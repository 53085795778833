import {useEffect} from 'react'

/**
 * Set title of the page and restore it back once the component is unmounted
 * @param title Title of the page
 */
export const useTitle = (title: string | undefined) => {
  useEffect(() => {
    const prevTitle = document.title
    document.title = title || ''
    return () => {
      document.title = prevTitle
    }
  }, [title])
}
