import {useAuth} from '@hconnect/common/authentication/context'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'

import {UserSettingsGrid} from '../components/userSettings/UserSettingsGrid'
import {UserSettingsHeader} from '../components/userSettings/UserSettingsHeader'
import {useTitle} from '../hooks/useTitle'

import {PageNotFound} from './PageNotFound'

export const SettingsPage = () => {
  useHistory()
  const {t} = useTranslation()
  useTitle(t('settingsPage.title'))
  const {decodedToken} = useAuth()
  return decodedToken?.email ? (
    <div data-test-id="settings-page">
      <UserSettingsHeader />
      <UserSettingsGrid userEmail={decodedToken.email} />
    </div>
  ) : (
    <PageNotFound />
  )
}
