import {Product, getCountries, retrieveResponseData} from '@hconnect/apiclient'
import {useApi} from '@hconnect/common/hooks/useApi'
import {useTranslation} from 'react-i18next'
import {useQuery} from 'react-query'

export const useCountries = () => {
  const {publicApi} = useApi()
  const response = useQuery('getCountries', retrieveResponseData(getCountries(publicApi)))
  return response
}

export const useAvailableDatalinkCountries = () => {
  const countriesResponse = useCountries()
  const {t} = useTranslation()

  const datalinkCountries = countriesResponse.data?.filter((country) =>
    country.liveProducts.includes(Product.Datalink)
  )

  const countrySelectValues = datalinkCountries?.map((country) => ({
    value: country.countryCode,
    label: t(`signUp.requestAccountForm.countries.${country.countryCode}`)
  }))

  countrySelectValues?.sort((a, b) => (a.label < b.label ? -1 : 1))

  return {
    countrySelectValues: countrySelectValues,
    datalinkCountries: datalinkCountries,
    isLoading: countriesResponse.isLoading
  }
}
