import {makeStyles} from 'tss-react/mui'

export const useStyles = makeStyles()(() => ({
  title: {
    marginBottom: '16px',
    fontSize: '22px',
    fontWeight: 600,
    color: '#4E565E'
  },
  subTitle: {
    marginTop: '40px',
    marginBottom: '0px',
    textTransform: 'initial',
    fontSize: '18px',
    lineHeight: '24px',
    color: '#4E565E'
  },
  loadingIndicatorContainer: {
    justifyContent: 'center',
    display: 'flex'
  },
  inputTextBox: {
    marginTop: '24px'
  },
  termsAndConditionsContainer: {
    marginTop: '24px'
  },
  submitButtonContainer: {
    display: 'flex',
    width: '100%',
    marginTop: '36px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  submitButton: {
    width: '100%',
    padding: '13px 16px',
    backgroundColor: '#016AD4',
    '&[disabled]': {
      backgroundColor: '#016AD4',
      opacity: 0.3,
      color: 'white'
    }
  },
  linkToSignContainer: {
    marginTop: '24px'
  },
  linkToSignIn: {
    paddingLeft: '10px',
    fontWeight: 500,
    lineHeight: '22px',
    fontSize: '16px',
    cursor: 'pointer',
    color: '#016AD4',
    textDecoration: 'underline'
  },
  requestAccountConfirmationContainer: {
    maxWidth: '664px',
    padding: '36px'
  },
  requestAccountConfirmationContent: {
    padding: '0px',
    h6: {
      margin: '0',
      marginBottom: '20px',
      fontWeight: '600',
      fontSize: '1.25rem',
      lineHeight: '1.6'
    },
    p: {
      margin: '0',
      marginBottom: '36px'
    }
  },
  confirmationCardActions: {
    padding: '0px'
  },
  confirmationWatchBtn: {
    lineHeight: '22px',
    padding: '13px 16px',
    marginLeft: 'auto'
  },
  supportStatusBanner: {
    marginTop: '16px',
    color: '#00274D',
    position: 'relative',
    '&:before': {
      content: '""',
      borderRadius: '10px',
      backgroundColor: '#00AB50',
      width: '4px',
      position: 'absolute',
      top: '0',
      bottom: '0',
      left: '0'
    },
    '&.notSupported:before': {
      backgroundColor: '#DA0901'
    }
  }
}))
