import {
  Link as MuiLink,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography
} from '@material-ui/core'
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos'
import {Box} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'

import {setAnalyticEvent} from '../common/eventTracker'
import {ApigeeApiProduct} from '../declarations'
import {useCommonTableStyles} from '../hooks/useCommonTableStyles'
import {useTracking} from '../hooks/useTracking'
import {getApiProductDetailPageUrl} from '../routes'

type ApiProductsCardProps = {
  record: ApigeeApiProduct[] | undefined
}

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontWeight: 'bold'
  },
  tableContainer: {
    marginTop: theme.spacing(2)
  },
  valueCell: {
    fontWeight: 'bold'
  },
  rangeExceeded: {
    color: theme.palette.error.main
  },
  container: {
    marginTop: theme.spacing(2)
  },
  header: {
    textTransform: 'none'
  }
}))

const getProductOrder = (product: ApigeeApiProduct): number =>
  parseInt(product.attributes.find((a) => a.name === 'order')?.value ?? '0', 10)

const sortApiProducts = (a: ApigeeApiProduct, b: ApigeeApiProduct) =>
  getProductOrder(a) - getProductOrder(b)

export const ApiProductsCard = (props: ApiProductsCardProps) => {
  const classes = useStyles()
  const {t} = useTranslation()
  const tableClasses = useCommonTableStyles()
  const {record} = props
  const {trackEvent} = useTracking(setAnalyticEvent)

  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        width="100%"
        justifyContent="space-between"
        data-test-id="page-api-product-title"
      >
        <Typography variant="h2" className={classes.header}>
          {t('apiProductOverview.title')}
        </Typography>
      </Box>
      <Box
        bgcolor="background.paper"
        color="text.primary"
        borderRadius={2}
        p={3}
        className={classes.container}
        data-test-id="page-api-product-content"
      >
        <TableContainer className={classes.tableContainer}>
          <Table size="small" data-test-id={'api-product-table'}>
            <TableHead
              className={tableClasses.tableHeader}
              data-test-id={'api-product-table-header'}
            >
              <TableRow>
                <TableCell className={tableClasses.tableHeaderCell}>
                  {t('apiProductOverview.table.header.name')}
                </TableCell>
                <TableCell className={tableClasses.tableHeaderCell}>
                  {t('apiProductOverview.table.header.description')}
                </TableCell>
                <TableCell className={tableClasses.tableHeaderCell} />
              </TableRow>
            </TableHead>
            <TableBody data-test-id={'api-product-table-body'}>
              {record?.sort(sortApiProducts)?.map((item: ApigeeApiProduct, index: number) => (
                <TableRow
                  key={index}
                  className={tableClasses.tableRow}
                  data-test-id={`api-product-table-row-${index}`}
                >
                  <TableCell
                    className={tableClasses.tableRowCell}
                    data-test-id={`api-product-table-cell-displayName-${index}`}
                  >
                    {item.displayName}
                  </TableCell>
                  <TableCell
                    className={tableClasses.tableRowCell}
                    data-test-id={`api-product-table-cell-description-${index}`}
                  >
                    {item.description}
                  </TableCell>
                  <TableCell
                    className={tableClasses.tableRowCell}
                    data-test-id={`api-product-table-cell-selection-${index}`}
                  >
                    <MuiLink
                      component={Link}
                      to={getApiProductDetailPageUrl(item.name)}
                      underline="none"
                      data-test-id={`api-product-selection-button-${index}`}
                      onClick={() => {
                        trackEvent({
                          name: 'dlProductEvent',
                          apiProduct: item.name,
                          operationName: 'dlProductClick'
                        })
                      }}
                    >
                      <ArrowForwardIos color="secondary" />
                    </MuiLink>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  )
}
