import {DecodedToken} from '@hconnect/apiclient/src'
import {AccountDropdown, Header} from '@hconnect/uikit'
import {Box, makeStyles} from '@material-ui/core'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {DataLinkLogo} from '../components/commons/DataLinkLogo'
import {UserSettingsBar} from '../components/userSettings/UserSettingsBar'
import {supportedLanguages} from '../localization'

export interface ShellHeaderProps {
  onClickHeaderLogo: () => void
  user: DecodedToken | null
  onLogout: () => void
  children?: React.ReactNode
}

const useStyles = makeStyles(() => ({
  logoWrapper: {
    cursor: 'pointer',
    height: 24,
    '& > img': {
      marginTop: -3
    }
  }
}))

export const ShellHeader: React.FC<ShellHeaderProps> = ({
  onClickHeaderLogo,
  user,
  onLogout,
  children
}) => {
  const {t, i18n} = useTranslation()
  const classes = useStyles()
  const language = i18n.language
  const localesList = supportedLanguages.map((code) => ({
    code,
    name: t(`locales.${code}`)
  }))

  return (
    <Header>
      <div
        onClick={onClickHeaderLogo}
        className={classes.logoWrapper}
        data-test-id="shell-header-logo"
      >
        <DataLinkLogo height={30} width={114} />
      </div>
      {children}
      {user && (
        <>
          <Box display="flex" flex={1} />
          <AccountDropdown
            actions={{
              logout: onLogout,
              selectLanguage: (lang) => {
                void i18n.changeLanguage(lang)
              }
            }}
            locales={{
              defaultLocale: language,
              locales: localesList,
              localeListLabel: t(`locales.${language}`)
            }}
            logoutButtonText={t('actionBar.logout')}
            profile={{name: user.given_name, email: user.email}}
            content={
              user.user_id
                ? {body: <UserSettingsBar data-test-id="user-settings-bar" />}
                : undefined
            }
          />
        </>
      )}
    </Header>
  )
}
