/* eslint-disable @typescript-eslint/unbound-method */
import {InputTextField, ValidatableInput} from '@hconnect/uikit/src/lib2'
import {Close} from '@mui/icons-material'
import {ButtonBase, Dialog, DialogContent, Typography} from '@mui/material'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {makeStyles} from 'tss-react/mui'

import CAN from './../../assets/CAN.svg'
import {AccountFormComponentProps, AccountFormTypes, AccountFormValues} from './types'

const useStyles = makeStyles()((theme) => ({
  textField: {
    marginTop: '24px'
  },
  infoIconButton: {
    marginBottom: theme.spacing(1),
    backgroundColor: 'transparent',
    color: '#7F9BA6',
    border: 'none',
    zIndex: 1000
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    top: 0,
    color: theme.palette.grey[500],
    padding: theme.spacing(2),
    zIndex: 1000
  },
  dialogContent: {
    padding: theme.spacing(4)
  },
  dialogTitle: {
    letterSpacing: 2,
    marginBottom: theme.spacing(2)
  },
  dialogImage: {
    margin: `${theme.spacing(3)}px auto`,
    display: 'block',
    maxWidth: '100%'
  }
}))

interface Props extends AccountFormComponentProps {
  name: keyof AccountFormValues
  formMethods: AccountFormTypes
}

export const CustomerNumberField: React.FC<Props> = ({name, formMethods}) => {
  const {t} = useTranslation()
  const {classes} = useStyles()
  const [isCustomerNumberHintShown, setCustomerNumberHintShown] = useState(false)

  const error = formMethods?.formState.errors[name]

  const {ref: accountNumberRef, ...accountNumberRest} = formMethods.register(name)

  return (
    <>
      <Dialog
        open={isCustomerNumberHintShown}
        onBackdropClick={() => setCustomerNumberHintShown(false)}
        maxWidth="md"
        data-test-id="customerNumberField-dialog"
      >
        <ButtonBase
          className={classes.closeButton}
          aria-label="Close"
          data-test-id="customerNumberField-dialog-closeButton"
          onClick={() => setCustomerNumberHintShown(false)}
        >
          <Close />
        </ButtonBase>
        <DialogContent className={classes.dialogContent}>
          <Typography className={classes.dialogTitle} variant="h1" color="textPrimary" gutterBottom>
            {t('signUp.requestAccountForm.whereIsMyCAN.title')}
          </Typography>
          <Typography variant="body2" color="textPrimary" gutterBottom>
            {t('signUp.requestAccountForm.whereIsMyCAN.body')}
          </Typography>
          <img
            alt={t('signUp.requestAccountForm.whereIsMyCAN.title')}
            className={classes.dialogImage}
            src={CAN}
            width={273}
            height={264}
          />
        </DialogContent>
      </Dialog>
      <div className={classes.textField} data-test-id="customerNumberField-accountNumberContainer">
        <ValidatableInput
          input={
            <InputTextField
              data-test-id="requestAccountForm-textInput-accountNumber"
              inputRef={accountNumberRef}
              error={!!error}
              required={false}
              helperText={error && t(`signUp.requestAccountForm.errors.${error?.message}`)}
              label={t('signUp.requestAccountForm.labels.accountNumber')}
              {...accountNumberRest}
            />
          }
          onHint={() => setCustomerNumberHintShown(true)}
        />
      </div>
    </>
  )
}
