import {Box} from '@material-ui/core'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'

import {EmptyAppInfo} from '../components/apps/EmptyAppInfo'
import {DeveloperVerificationShell} from '../container/DeveloperVerificationShell'
import {useApps} from '../hooks/useApps'
import {useQuotas} from '../hooks/useQuotas'
import {useDatalinkRoles} from '../hooks/useRoles'
import {useTitle} from '../hooks/useTitle'
import {AppOverviewContent} from '../layouts/AppOverviewContent'

export const AppOverviewPage = () => {
  useHistory()
  const {t} = useTranslation()
  useTitle(t('appOverview.title'))
  const appsQueryInfo = useApps()
  const {isFetching, isCompanyAssigned, isDatalinkDeveloper, assignedCompanies} = useDatalinkRoles()
  const {companyQuotas, isCompanyQuotasFetching} = useQuotas(assignedCompanies)
  return (
    <Box data-test-id="page-app-overview">
      <DeveloperVerificationShell
        areChildrenLoading={appsQueryInfo.isLoading && isFetching && isCompanyQuotasFetching}
      >
        {!appsQueryInfo.data?.app && <EmptyAppInfo />}

        {appsQueryInfo.data?.app && !isCompanyQuotasFetching && !isFetching && (
          <AppOverviewContent
            record={appsQueryInfo.data}
            disableCreateAppButton={isDatalinkDeveloper && !isCompanyAssigned}
            companyQuotas={companyQuotas}
          />
        )}
      </DeveloperVerificationShell>
    </Box>
  )
}
