import Markdown from 'markdown-to-jsx'
import SyntaxHighlighter from 'react-syntax-highlighter'
import {docco as theme} from 'react-syntax-highlighter/dist/esm/styles/hljs'

function HighlightOverride({className, children}) {
  if (!className?.includes('lang-')) return children

  const language = className.replace('lang-', '')
  return (
    <SyntaxHighlighter language={language.toLowerCase()} style={theme}>
      {children}
    </SyntaxHighlighter>
  )
}
const markdownOptions = {
  overrides: {
    code: HighlightOverride
  }
}
export const MarkdownViewer = ({children}) => {
  return (
    <div data-test-id="component-markdownviewer">
      <Markdown options={markdownOptions}>{children}</Markdown>
    </div>
  )
}
