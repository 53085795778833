import {CreationChannel, CreationProduct} from '@hconnect/apiclient'
import {useApi} from '@hconnect/common/hooks/useApi'
import {AxiosInstance} from 'axios'
import {useMutation} from 'react-query'

export interface UserModel {
  userName: string
  name: string
  email: string
  country: string
  user_id?: string
  creationChannel: CreationChannel
  requestedCustomerNumber: string
  isTermsApprovalRequired: boolean | null
  companyName: string
  defaultLocale: string
  termsAcceptedOn: string
  termsAcceptedVersion: string
  creationProduct: CreationProduct
  dataPrivacyAcknowledgedOn: string
  dataPrivacyAcknowledgedVersion: string
}

type UseUserCreationMutationKey = ['users', {user: UserModel}]

const userCreator = (secureApi: AxiosInstance) => async (queryKey: UseUserCreationMutationKey) => {
  const [, {user}] = queryKey
  const response = await secureApi.post<UserModel>('/users', user)
  return response.data
}

export const useCreateUserMutation = () => {
  const {publicApi} = useApi()

  return useMutation(userCreator(publicApi))
}
