import {AxiosInstance} from 'axios'
import {QueryFunction, useQuery} from 'react-query'

import {ApigeeDeveloper} from '../declarations'
import {useDatalinkSecureApi} from '../hooks/useDatalinkSecureApi'
type UseDeveloperStatusQueryKey = ['datalink', 'developers', 'developer']

const developerFetcher =
  (secureApi: AxiosInstance): QueryFunction<ApigeeDeveloper, UseDeveloperStatusQueryKey> =>
  async () => {
    const response = await secureApi.get<ApigeeDeveloper>(
      `/datalink/developerportal/${process.env.REACT_APP_ORGANIZATION_NAME}/developers/developer`
    )
    return response.data
  }

export const useDeveloper = () => {
  return useQuery(['datalink', 'developers', 'developer'], developerFetcher(useDatalinkSecureApi()))
}
