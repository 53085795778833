import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import {AuthProvider} from '@hconnect/common/authentication/context'
import {HCTheme} from '@hconnect/uikit'
import {CircularProgress, CssBaseline} from '@material-ui/core'
import {ThemeProvider} from '@material-ui/styles'
import i18next from 'i18next'
import {SnackbarProvider} from 'notistack'
import React, {Suspense} from 'react'
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import {HelmetProvider} from 'react-helmet-async'
import {I18nextProvider} from 'react-i18next'
import {QueryClient, QueryClientProvider} from 'react-query'
import {BrowserRouter as Router} from 'react-router-dom'

import {DatalinkApiProvider} from './DatalinkApiProvider'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
})

export const DatalinkAppProvider = ({children}: {children: React.ReactNode}) => (
  <Suspense fallback={<CircularProgress data-test-id="init-fallback" />}>
    <AuthProvider>
      <DatalinkApiProvider>
        <HelmetProvider>
          <I18nextProvider i18n={i18next}>
            <ThemeProvider theme={HCTheme}>
              <CssBaseline />
              <QueryClientProvider client={queryClient}>
                <SnackbarProvider
                  maxSnack={1}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                  }}
                  data-test-id="app-snackbar-provider"
                >
                  <Router>{children}</Router>
                </SnackbarProvider>
              </QueryClientProvider>
            </ThemeProvider>
          </I18nextProvider>
        </HelmetProvider>
      </DatalinkApiProvider>
    </AuthProvider>
  </Suspense>
)
