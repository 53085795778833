import {IconButton} from '@material-ui/core'
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp'
import React from 'react'

const ScrollTopIcon = ({className: classNameProp, ...otherProps}) => (
  <IconButton color="inherit" className={classNameProp} {...otherProps}>
    <KeyboardArrowUp />
  </IconButton>
)

export default ScrollTopIcon
