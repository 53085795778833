import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'

export const ExternalPageDialog = (props: {link: string; onClose: any}) => {
  const {t} = useTranslation()
  const {link, onClose} = props

  useEffect(() => {
    const timer = setTimeout(() => handleExternalPageOpen(), 3000)
    return () => clearTimeout(timer)
  }, [])

  const handleExternalPageOpen = () => {
    window.open(link, '_blank')?.focus()
    onClose()
  }

  return (
    <Dialog
      open={true}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      data-test-id="externalPageDialog-root"
    >
      <DialogTitle data-test-id="externalPageDialog-title">
        {t('components.externalPageDialog.title')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <a
            href={link}
            onClick={onClose}
            target="_blank"
            rel="noreferrer"
            data-test-id="externalPageDialog-link"
          >
            {link}
          </a>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  )
}
