import {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {hasCurrentDataProtectionPrivacyAcknowledged} from '../common/legal'

import {useAuthentication} from './useAuthentication'
import {useUserAcknowledgedDataPrivacy, useUserMutation} from './useSettings'

export const useDataProtectionPrivacy = () => {
  const {t} = useTranslation()
  const acknowledgedDataProtectionPrivacy = useUserAcknowledgedDataPrivacy()
  const {userEmail} = useAuthentication()
  const {mutateAsync: updateUserData} = useUserMutation()
  const [userAcknowledgedDataProtectionPrivacy, setUserAcknowledgedDataProtectionPrivacy] =
    useState<boolean>()

  const acknowledgeUserDataProtectionPrivacy = () => {
    const data = {
      dataPrivacyAcknowledgedVersion: t('legal.dataPrivacyProtectionVersion'),
      dataPrivacyAcknowledgedOn: new Date().toISOString()
    }
    void updateUserData([
      'users',
      {
        userEmail: userEmail,
        data
      }
    ])
    setUserAcknowledgedDataProtectionPrivacy(true)
  }

  useEffect(() => {
    if (
      acknowledgedDataProtectionPrivacy.isSuccess &&
      userAcknowledgedDataProtectionPrivacy === undefined
    ) {
      const dataProtectionPrivacyAcknowledged = hasCurrentDataProtectionPrivacyAcknowledged(
        acknowledgedDataProtectionPrivacy.data ?? [],
        t('legal.dataPrivacyProtectionVersion')
      )
      setUserAcknowledgedDataProtectionPrivacy(dataProtectionPrivacyAcknowledged)
    }
  }, [acknowledgedDataProtectionPrivacy, userAcknowledgedDataProtectionPrivacy, t])

  return {
    userAcknowledgedDataProtectionPrivacy: userAcknowledgedDataProtectionPrivacy,
    acknowledgeUserDataProtectionPrivacy: acknowledgeUserDataProtectionPrivacy
  }
}
