import {Box, Link, Typography} from '@mui/material'
import {useTranslation} from 'react-i18next'

export const CookiesPolicyPage = () => {
  const styles = {
    headerBox: {
      paddingBottom: '21px',
      paddingLeft: '12px'
    },
    headerTypography: {
      fontWeight: 600,
      fontSize: '28px',
      lineHeight: '34px',
      color: '#FFFFFF'
    },
    contentBox: {
      borderRadius: 2,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingBottom: '15px'
    }
  }

  const {t} = useTranslation()

  return (
    <>
      <Box sx={styles.headerBox}>
        <Typography sx={styles.headerTypography}>{t('cookies.title')}</Typography>
      </Box>
      <Box
        data-test-id="cookies-policy-content"
        bgcolor="background.paper"
        color="text.primary"
        sx={styles.contentBox}
      >
        <Typography sx={{mx: 5}}>
          <div
            className="content"
            dangerouslySetInnerHTML={{__html: t('cookies.legalExplanation')}}
          />
          <div
            className="content"
            dangerouslySetInnerHTML={{__html: t('cookies.cookieDescription')}}
          />
          <div
            className="content"
            dangerouslySetInnerHTML={{__html: t('cookies.legalObjection1')}}
          />
          <Link href="/settings">{t('cookies.settingsLinkText')}</Link>
          <div
            className="content"
            dangerouslySetInnerHTML={{__html: t('cookies.legalObjection2')}}
          />
          <Link href="/legal/dataprotection">{t('cookies.dppLinkText')}</Link>
        </Typography>
      </Box>
    </>
  )
}
