import {Box, Stack, Typography} from '@mui/material'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'

import {PricingIllustration} from '../assets'
import {hasDatalinkDeveloperOrAdminRole} from '../common/roles'
import {LoadingSpinner} from '../components/commons/LoadingSpinner'
import {useDatalinkRoles} from '../hooks/useRoles'
import {useTitle} from '../hooks/useTitle'
import {PricingLayout} from '../layouts/PricingLayout'
import {useStyles} from '../layouts/PricingLayout.styles'

import {PageNotFound} from './PageNotFound'

export const PricingPage = () => {
  useHistory()
  const {t} = useTranslation()
  useTitle(t('pricing.title'))
  const {classes} = useStyles()

  const {isFetching: isRolesQueryFetching, roles} = useDatalinkRoles()

  if (isRolesQueryFetching) return <LoadingSpinner />

  if (!hasDatalinkDeveloperOrAdminRole(roles)) return <PageNotFound />

  return (
    <Box data-test-id="page-pricing">
      <Stack direction={'row'} justifyContent={'space-between'}>
        <Box>
          <Typography
            variant="h1"
            className={classes.headerTitle}
            data-test-id="page-pricing-title"
          >
            {t('pricing.header.title')}
          </Typography>
          <Typography variant="body1" className={classes.headerDescription}>
            {t('pricing.header.description')}
          </Typography>
        </Box>
        <Box>
          <img src={PricingIllustration} alt="pricing" />
        </Box>
      </Stack>
      <Box data-test-id="page-pricing-content" className={classes.contentBox}>
        <PricingLayout />
      </Box>
    </Box>
  )
}
