import {overrideHConnectTheme} from '@hconnect/uikit/src/lib2'
import {CssBaseline, Stack} from '@mui/material'
import {ThemeProvider} from '@mui/material/styles'
import {identity} from 'lodash'
import {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'

import {RequestAccountConfirmation} from '../components/userRegistration/RequestAccountConfirmation'
import {useTitle} from '../hooks/useTitle'
import {GettingStartedLayout} from '../layouts/GettingStartedLayout'
import {RequestAccountLayout} from '../layouts/RequestAccountLayout'
import {SurveyLayout} from '../layouts/SurveyLayout'

export const SignUpPage = () => {
  useHistory()
  const {t} = useTranslation()

  useTitle(t('signUp.title'))

  const [accountRequestConfirmed, setAccountRequestConfirmed] = useState<boolean>(false)
  const [userId, setUserId] = useState<string>('')
  const [surveySubmitted, setSurveySubmitted] = useState<boolean>(false)

  const handleRequestAccountSubmit = (userId: string) => {
    setAccountRequestConfirmed(true)
    setUserId(userId)
  }

  const afterSurveySubmitted = () => {
    setSurveySubmitted(true)
  }

  const datalinkSignUpTheme = (overrideHConnectTheme ?? identity)({
    palette: {},
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            background:
              'linear-gradient(90deg, rgba(0,55,77,1) 0%, rgba(0,99,138,1) 40%,rgba(0,99,138,1) 60%, rgba(0,55,77,1) 100%)'
          }
        }
      }
    }
  })

  return (
    <ThemeProvider theme={datalinkSignUpTheme}>
      <CssBaseline />
      <Stack data-test-id="signUpPage-container">
        {accountRequestConfirmed ? (
          <GettingStartedLayout>
            {surveySubmitted ? (
              <RequestAccountConfirmation />
            ) : (
              <SurveyLayout userId={userId} afterSurveySubmitted={afterSurveySubmitted} />
            )}
          </GettingStartedLayout>
        ) : (
          <RequestAccountLayout afterUserRegistration={handleRequestAccountSubmit} />
        )}
      </Stack>
    </ThemeProvider>
  )
}
