import {AxiosInstance} from 'axios'
import {QueryFunction, useMutation, useQuery, useQueryClient} from 'react-query'

import {UserAcceptedTerms, UserSettings, UserAcknowledgedDataPrivacy} from '../declarations'

import {useAuthentication} from './useAuthentication'
import {useDatalinkSecureApi} from './useDatalinkSecureApi'

type UseSettingsQueryKey = ['users', {userEmail: string | undefined}]

type UserTermsAndConditionsQueryKey = ['users', 'acceptedTerms']

type UserAcknowledgedDataPrivacyQueryKey = ['users', 'acknowledgedDataPrivacy']

export type UseUserMutationKey = [
  'users',
  {
    userEmail: string
    data: any
  }
]

const settingsFetcher =
  (secureApi: AxiosInstance): QueryFunction<UserSettings, UseSettingsQueryKey> =>
  async ({queryKey}) => {
    const [, {userEmail}] = queryKey

    const response = await secureApi.get<UserSettings>(`/users/${userEmail}`)

    return response.data
  }

export const useUserSettings = (userEmail: string | undefined) => {
  const {data, isError, isFetching} = useQuery(
    ['users', {userEmail}],
    settingsFetcher(useDatalinkSecureApi()),
    {enabled: !!userEmail}
  )

  return {
    isAnalyticsEnabled: data?.isAnalyticsEnabled ?? false,
    isError: isError,
    isFetching: isFetching
  }
}

const userAcceptedTermsFetcher =
  (secureApi: AxiosInstance): QueryFunction<UserAcceptedTerms[], UserTermsAndConditionsQueryKey> =>
  async () => {
    const response = await secureApi.get<UserAcceptedTerms[]>('/users/acceptedTerms')
    return response.data
  }

export const useUserAcceptedTerms = () => {
  return useQuery(['users', 'acceptedTerms'], userAcceptedTermsFetcher(useDatalinkSecureApi()))
}

const userAcknowledgedDataPrivacyFetcher =
  (
    secureApi: AxiosInstance
  ): QueryFunction<UserAcknowledgedDataPrivacy[], UserAcknowledgedDataPrivacyQueryKey> =>
  async () => {
    const response = await secureApi.get<UserAcknowledgedDataPrivacy[]>(
      '/users/acknowledgedDataPrivacy'
    )
    return response.data
  }

export const useUserAcknowledgedDataPrivacy = () => {
  const {userAuthenticated} = useAuthentication()
  return useQuery(
    ['users', 'acknowledgedDataPrivacy'],
    userAcknowledgedDataPrivacyFetcher(useDatalinkSecureApi()),
    {enabled: userAuthenticated === true}
  )
}

const userModifier = (secureApi: AxiosInstance) => async (queryKey: UseUserMutationKey) => {
  const [, {userEmail, data}] = queryKey
  await secureApi.patch(`/users/${userEmail}`, data)
}

export const useUserMutation = () => {
  const secureApi = useDatalinkSecureApi()
  const queryClient = useQueryClient()
  return useMutation(userModifier(secureApi), {
    onSuccess: () => queryClient.invalidateQueries()
  })
}
