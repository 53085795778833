import {Box, Typography, Paper, Stack, Grid} from '@mui/material'
import _ from 'lodash'
import {useTranslation} from 'react-i18next'

import {Changelog, ChangelogTag} from '../../declarations'
import {useCommonBoxStyles} from '../../hooks/useCommonBoxStyles'

import {ChangeLogItem} from './ChangelogItem'
import {EmptyChangelogList} from './EmptyChangelogList'
import {TagList} from './TagList'

function getAllDistinctTags(changelogs: Changelog[]): ChangelogTag[] {
  return _.uniqBy(
    changelogs
      .flatMap((a) => a.tags)
      .filter(Boolean)
      .sort(),
    'text'
  )
}

function getFilteredChangelogs(changelogs: Changelog[], filterTag: string | null): Changelog[] {
  return filterTag ? changelogs.filter((a) => a.tags.some((c) => c.text === filterTag)) : changelogs
}

export const ChangelogList = ({
  changelogs,
  filterByTag
}: {
  changelogs: Changelog[]
  filterByTag: string | null
}) => {
  const classes = useCommonBoxStyles()
  const {t} = useTranslation()
  const filteredChangelogs = getFilteredChangelogs(changelogs, filterByTag)

  if (!filteredChangelogs.length) return <EmptyChangelogList />

  return (
    <Grid spacing={1} container style={{display: 'flex', justifyContent: 'center'}}>
      <Grid data-test-id="page-changelogs-content" item xs={9}>
        <Box data-test-id="component-changelogList">
          {filteredChangelogs.map((item) => {
            return <ChangeLogItem changelog={item} key={item.date} />
          })}
        </Box>
      </Grid>
      <Grid item xs={3}>
        <Paper className={classes.paper}>
          <Stack data-test-id="page-changelogs-sidebar" spacing={2}>
            <Typography variant="h6">
              <Box component="span" fontWeight="fontWeightBold">
                {t('changelog.tagsList.title')}
              </Box>
            </Typography>
            <TagList tags={getAllDistinctTags(changelogs)} />
          </Stack>
        </Paper>
      </Grid>
    </Grid>
  )
}
