import {HConnectLogo, OssAttributionLink} from '@hconnect/uikit'
import {Grid, Link, makeStyles} from '@material-ui/core'
import React, {useContext} from 'react'
import {useTranslation} from 'react-i18next'

import {LegalContext} from '../context/LegalContext'
import {dataLinkRoutes} from '../routes'

const useStyle = makeStyles((theme) => ({
  container: {
    textAlign: 'center',
    '& > a': {
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5)
    }
  }
}))

export const ShellFooter: React.FC = () => {
  const classes = useStyle()
  const {t} = useTranslation()
  const legalData = useContext(LegalContext)

  const handleDataProtectionNavigationClick = () => {
    if (legalData.userAcknowledgedDataProtectionPrivacy === false) {
      legalData.acknowledgeUserDataProtectionPrivacy()
    }
  }

  return (
    <Grid container justifyContent="center">
      <Grid item className={classes.container}>
        <OssAttributionLink />
        <Link
          variant="body2"
          color="secondary"
          href={dataLinkRoutes.TermsAndConditionsPage}
          rel="noreferrer"
          target="_blank"
          data-test-id="terms-and-condition-footer"
        >
          {t('footer.termsAndConditions')}
        </Link>
        <Link
          variant="body2"
          color="secondary"
          href={dataLinkRoutes.DataProtectionPage}
          rel="noreferrer"
          target="_blank"
          data-test-id="data-protection-footer"
          onClick={handleDataProtectionNavigationClick}
        >
          {t('footer.dataProtection')}
        </Link>
        <Link
          variant="body2"
          color="secondary"
          href={dataLinkRoutes.ImprintPage}
          rel="noreferrer"
          target="_blank"
        >
          {t('footer.imprint')}
        </Link>
      </Grid>
      <Grid item container justifyContent="flex-end">
        <HConnectLogo />
      </Grid>
    </Grid>
  )
}
