import {AxiosError} from 'axios'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {Redirect} from 'react-router-dom'

import {canManageApplication} from '../common/permission'
import {LoadingSpinner} from '../components/commons/LoadingSpinner'
import {ApigeeDeveloperStatus} from '../declarations'
import {useDatalinkSnackbar} from '../hooks/useDatalinkSnackbar'
import {useDeveloper} from '../hooks/useDeveloper'
import {useDatalinkPermissions} from '../hooks/usePermission'
import {useDatalinkRoles} from '../hooks/useRoles'
import {dataLinkRoutes} from '../routes'

export const DeveloperVerificationShell = ({
  children,
  areChildrenLoading
}: {
  children: React.ReactNode
  areChildrenLoading: boolean
}) => {
  const developerQueryInfo = useDeveloper()
  const {t} = useTranslation()
  const {enqueueSnackbar} = useDatalinkSnackbar()
  const {isCompanyAssigned, isDatalinkDeveloper, isFetching} = useDatalinkRoles()

  const {
    isError: isPermissionError,
    isFetching: isPermissionFetching,
    permissions: permissions
  } = useDatalinkPermissions()

  if (areChildrenLoading || developerQueryInfo.isFetching || isPermissionFetching)
    return <LoadingSpinner />

  if (
    developerQueryInfo.isError &&
    (developerQueryInfo.error as AxiosError)?.response?.status === 404
  ) {
    enqueueSnackbar(t('developerNotFoundMessage'), 'error')
    return <Redirect to={dataLinkRoutes.LandingPage} />
  }

  if (!isFetching && isDatalinkDeveloper && !isCompanyAssigned) {
    enqueueSnackbar(t('developerCompanyAssignmentMissing'), 'error')
  }

  if (
    developerQueryInfo.isSuccess &&
    developerQueryInfo.data?.status === (ApigeeDeveloperStatus.InActive as string)
  ) {
    enqueueSnackbar(t('developerInactiveStatusMessage'), 'error')
    return <Redirect to={dataLinkRoutes.LandingPage} />
  }

  if (!canManageApplication(permissions)) {
    enqueueSnackbar(t('developerMissingDatalinkDeveloperRole'), 'error')
    return <Redirect to={dataLinkRoutes.LandingPage} />
  }

  if (developerQueryInfo.isError || isPermissionError) {
    enqueueSnackbar(t('developerVerificationError'), 'error')
    return <Redirect to={dataLinkRoutes.LandingPage} />
  }

  return <>{children}</>
}
