// TODO: move to separate file
import {ClientConfig, createHttpClient, createPublicHttpClient} from '@hconnect/apiclient'
import {
  authRequestProvider,
  backendSelector,
  loginFlow,
  loginStorage
} from '@hconnect/common/authentication/loginClasses'
import {ApiProvider} from '@hconnect/common/hooks/useApi'
import React from 'react'

export const DatalinkApiProvider = ({children}: {children: React.ReactNode}) => {
  const clientConfig: ClientConfig = {
    backendSelector,
    authRequestProvider,
    loginStorage,
    loginFlow,

    // FIXME: Discuss removal, should just throw an exception where it happens
    onValidateJsonError: (error, axiosResponse) => {
      console.error('JSON Validation Error:', error, axiosResponse)
    }
  }

  const secureApi = createHttpClient(clientConfig, {})
  const publicApi = createPublicHttpClient({
    backendSelector
  })

  return (
    <ApiProvider secureApi={secureApi} publicApi={publicApi}>
      {children}
    </ApiProvider>
  )
}
