import {Box, Typography} from '@material-ui/core'
import React from 'react'
import {useTranslation} from 'react-i18next'

export const PageNotFound = () => {
  const {t} = useTranslation()
  return (
    <>
      <Box
        flex={1}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        data-test-id="page-not-found"
      >
        <Typography variant="h2">{t('error.pageNotFoundTitle')}</Typography>
        <br />
        <Typography variant="body1">{t('error.pageNotFoundDescription')}</Typography>
      </Box>
    </>
  )
}
