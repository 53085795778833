import {UserAcceptedTerms, UserAcknowledgedDataPrivacy} from '../declarations'

export const hasCurrentTermsAndConditionsAccepted = (
  termsAndConditions: UserAcceptedTerms[],
  currentVersion: string
) => {
  const currentDatalinkAcceptedTerm = termsAndConditions.find((f) =>
    f.termsAcceptedVersion.startsWith('dl-tc')
  )

  return currentDatalinkAcceptedTerm?.termsAcceptedVersion === currentVersion
}

export const hasCurrentDataProtectionPrivacyAcknowledged = (
  termsAndConditions: UserAcknowledgedDataPrivacy[],
  currentVersion: string
) => {
  const currentDatalinkAcknowledgedDataProtectionPrivacy = termsAndConditions.find((f) =>
    f.dataPrivacyAcknowledgedVersion.startsWith('dl-dpp')
  )

  return (
    currentDatalinkAcknowledgedDataProtectionPrivacy?.dataPrivacyAcknowledgedVersion ===
    currentVersion
  )
}
