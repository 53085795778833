import {Button} from '@material-ui/core'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import {Box, styled, Typography} from '@mui/material'
import {rgba} from 'polished'
import React from 'react'
import {useTranslation} from 'react-i18next'

const styles = {
  headerBox: {
    borderRadius: 2,
    paddingTop: '26px',
    paddingLeft: '24px',
    paddingBottom: '29px',
    paddingRight: '35px',
    backgroundColor: rgba(1, 106, 212, 0.1)
  }
}

const HeaderTypography = styled(Typography)({
  fontWeight: 600,
  fontSize: '18px',
  lineHeight: '24px',
  variant: 'subtitle1'
})

const DescriptionTypography = styled(Typography)({
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '26px',
  paddingTop: '24px',
  paddingBottom: '36px',
  variant: 'body1'
})

const GetInTouchButton = styled(Button)({
  textTransform: 'none',
  borderRadius: '6px',
  backgroundColor: '#016AD4',
  border: '1px solid #016AD4',
  color: '#FFFFFF',
  '&:hover': {
    backgroundColor: '#016AD4',
    color: '#FFFFFF'
  }
})

export const FAQMoreQuestionCard = () => {
  const {t} = useTranslation()
  return (
    <>
      <Box color="text.primary" sx={styles.headerBox} data-test-id="component-faq-more-question">
        <HeaderTypography data-test-id="component-faq-more-question-header">
          {t('faq.header.stillHaveQuestion')}
        </HeaderTypography>

        <DescriptionTypography data-test-id="component-faq-more-question-header-content">
          {t('faq.content.stillHaveQuestionDescription')}
        </DescriptionTypography>

        <GetInTouchButton
          variant="contained"
          startIcon={<ArrowForwardIcon />}
          size="medium"
          data-test-id="component-faq-more-question-get-in-touch-button"
          href="mailto:ba56a237.hcgroupnet.onmicrosoft.com(at)emea.teams.ms"
        >
          {t('faq.button.getInTouch')}
        </GetInTouchButton>
      </Box>
    </>
  )
}
