import {Box, styled} from '@mui/material'
import {makeStyles} from 'tss-react/mui'

export const useStyles = makeStyles()(() => ({
  surveyCard: {
    width: 656,
    height: 553,
    flexShrink: 0,
    borderRadius: 8,
    background: '#FFF',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: '36px !important'
  },
  surveyTitle: {
    color: '#000',
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '24px',
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: 'Inter',
    fontStyle: 'normal'
  },
  surveySubTitle: {
    color: '#000',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '26px',
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: 'Inter',
    fontStyle: 'normal'
  },
  anotherReasonTextField: {
    display: 'flex',
    width: 357,
    height: 86,
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    backgroundColor: '#FBFBFC',
    border: '1px solid #00274D26',
    borderRadius: '4px',
    marginTop: '14px',
    '& > div': {
      padding: '13px 16px',
      backgroundColor: '#FBFBFC'
    },
    '& > div > textarea': {
      color: '#54708C !important',
      fontSize: '16px !important',
      backgroundColor: '#FBFBFC',
      fontWeight: 500,
      lineHeight: '22px'
    },
    '& > div > fieldset': {
      border: 'none',
      backgroundColor: 'transparent'
    }
  },
  surveyItemLabel: {
    color: 'var(--light-text-high-contrast-hover, #001326)',
    fontSize: '16px',
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '22px'
  },
  surveySubmitButton: {
    borderRadius: 6,
    height: '48px',
    bottom: 0,
    right: 0,
    boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.08)',
    '&[disabled]': {
      backgroundColor: '#016AD4',
      opacity: 0.3,
      color: 'white'
    }
  }
}))

export const SubmitButtonBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
  height: 150
}))

export const TitleBox = styled(Box)(() => ({
  display: 'flow',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  width: 430
}))

export const AnotherReasonBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between'
}))
