import {Company, CompanyQuota} from '@hconnect/apiclient'
import {AxiosInstance} from 'axios'
import {QueryFunction, useQuery} from 'react-query'

import {useDatalinkSecureApi} from './useDatalinkSecureApi'

type UseCompanyQuotasQueryKey = ['developerportal', 'quotas', {assignedCompanies: Company[]}]

const companyQuotasFetcher =
  (secureApi: AxiosInstance): QueryFunction<CompanyQuota[], UseCompanyQuotasQueryKey> =>
  async ({queryKey}) => {
    const [, , {assignedCompanies}] = queryKey
    const response = await secureApi.get<CompanyQuota[]>(
      `/datalink/developerportal/quotas?${assignedCompanies
        .map((company) => `companyId=${company.id}`)
        .join('&')}`
    )
    return response.data
  }

export const useQuotas = (assignedCompanies: Company[]) => {
  const secureApi = useDatalinkSecureApi()
  const {data, isFetching} = useQuery(
    ['developerportal', 'quotas', {assignedCompanies}],
    companyQuotasFetcher(secureApi),
    {
      retry: false,
      enabled: assignedCompanies && assignedCompanies.length > 0
    }
  )

  return {
    isCompanyQuotasFetching: isFetching,
    companyQuotas:
      data?.map((companyQuota) => {
        const matchingCompany = assignedCompanies.find(
          (company) => company.id === companyQuota.companyId
        )
        companyQuota.companyName = matchingCompany?.name ?? ''
        return companyQuota
      }) ?? []
  }
}
