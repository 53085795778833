import {CardMedia} from '@material-ui/core'
import {useTranslation} from 'react-i18next'

import {ResourceOwnerPasswordCredentialsDiagram} from '../../assets'

export const AuthenticationDiagramCard = () => {
  const {t} = useTranslation()
  return (
    <>
      <CardMedia
        component="img"
        data-test-id={'according-details-faq-panel-aboutAuthentication-content-image'}
        src={ResourceOwnerPasswordCredentialsDiagram}
        alt={t('guide.noAppsImageLabel')}
      />
    </>
  )
}
