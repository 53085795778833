import {makeStyles} from '@material-ui/core'

export const useCommonButtonStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.common.white,
    background: '#6f8ad73b',
    border: '1px solid #6a6aba94',
    paddingTop: theme.spacing(1.5),
    marginLeft: theme.spacing(2.5),
    '&:hover': {
      background: '#899dd659'
    },
    lineHeight: '22px'
  },
  bodyButton: {
    border: '1px solid #6a6aba94',
    paddingTop: theme.spacing(1.5),
    color: theme.palette.common.white,
    marginTop: theme.spacing(4),
    backgroundColor: '#3869CD',
    '&:hover': {
      backgroundColor: '#4273d6'
    }
  }
}))

export const useCommonCopyTextButtonStyle = makeStyles((theme) => ({
  button: {
    marginLeft: theme.spacing(1),
    border: 'none',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none'
    }
  }
}))
