import {backendResolver} from '@hconnect/uikit'
import i18n from 'i18next'
import detector from 'i18next-browser-languagedetector'
import {initReactI18next} from 'react-i18next'

const detectorOptions = {
  order: ['querystring', 'navigator', 'cookie', 'localStorage', 'htmlTag', 'path', 'subdomain']
}

export const supportedLanguages = ['en']

const localizationParams = {
  fallbackLng: 'en',
  // Whitelisting is necessary to make sure unsupported languages don't get localized
  supportedLngs: [...supportedLanguages],
  detection: detectorOptions,
  saveMissing: process.env.NODE_ENV === 'development',
  backend: {
    loader: (filename: string) => {
      if (process.env.NODE_ENV === 'development') {
        console.info(`Load Translation for datalink [${filename}]`)
      }
      return import(`./${filename}.json`).catch((err) => {
        if (process.env.NODE_ENV === 'development') {
          console.error(`Could not load Translation for datalink [${filename}]`)
        }
        return {err}
      })
    }
  },
  interpolation: {escapeValue: false}
}

export const initLocalization = () =>
  void i18n.use(backendResolver).use(detector).use(initReactI18next).init(localizationParams)
