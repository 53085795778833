import {useSecureApi} from '@hconnect/common/hooks/useApi'

import {useErrorNotify} from './useErrorNotify'

export const useDatalinkSecureApi = () => {
  const secureApi = useSecureApi()
  const {onNotify} = useErrorNotify()
  secureApi.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      onNotify(error)
      return Promise.reject(error)
    }
  )
  return secureApi
}
