import {CompanyQuota} from '@hconnect/apiclient'
import {Box, Typography} from '@material-ui/core'
import {Gauge, gaugeClasses} from '@mui/x-charts/Gauge'
import React from 'react'
import {useTranslation} from 'react-i18next'

export const QuotaConsumptionCard = ({
  companyId,
  companyName,
  subscriptionPlanName,
  quotaLimit,
  quotaUsed
}: CompanyQuota) => {
  const {t} = useTranslation()
  return (
    <Box
      style={{display: 'flex', flexDirection: 'column'}}
      bgcolor="background.paper"
      color="text.primary"
      borderRadius={8}
      p={2}
      mt={2}
      ml={2}
      data-test-id={`quota-consumption-card-${companyId}`}
    >
      <Box style={{display: 'flex', justifyContent: 'center'}} data-test-id="quota-wrapper-title">
        <Typography variant="h3">{companyName}</Typography>
      </Box>
      <Box style={{display: 'flex', justifyContent: 'space-between'}}>
        <Box>
          <Box mb={2}>
            <Typography
              variant="h3"
              style={{lineHeight: '1'}}
              data-test-id="quota-wrapper-plan-name"
            >
              {subscriptionPlanName}
            </Typography>
            <Typography variant="caption">{t('appOverview.quotaConsumption.planLabel')}</Typography>
          </Box>
          <Box data-test-id="quota-wrapper-quota-consumption">
            <Typography variant="caption">
              {t('appOverview.quotaConsumption.requestsLeftLabel')}
            </Typography>
            <Typography variant="h2">
              {quotaLimit - quotaUsed}/{quotaLimit}
            </Typography>
          </Box>
        </Box>
        <Box style={{display: 'flex', alignItems: 'center'}}>
          <Gauge
            width={100}
            height={100}
            value={Number(((quotaUsed / quotaLimit) * 100).toFixed(2))}
            sx={{
              [`& .${gaugeClasses.valueText}`]: {
                fontSize: 20
              }
            }}
            text={({value}) => `${value}%`}
            data-test-id="quota-wrapper-gauge"
          />
        </Box>
      </Box>
    </Box>
  )
}
