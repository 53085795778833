import {AppInsightsAdapter} from '@hconnect/common/logging'
import React, {useEffect, useRef} from 'react'
import {useHistory} from 'react-router-dom'

import {setAnalyticEvent} from '../common/eventTracker'
import {useAuthentication} from '../hooks/useAuthentication'
import {useUserSettings} from '../hooks/useSettings'
import {useTracking} from '../hooks/useTracking'

export const AnalyticTrackerShell = ({children}) => {
  const {userAuthenticated, userEmail} = useAuthentication()
  const {isAnalyticsEnabled} = useUserSettings(userEmail)
  const history = useHistory()
  const {trackEvent} = useTracking(setAnalyticEvent)
  const disabledAnalytics = !isAnalyticsEnabled || 'true' === process.env.CYPRESS_COVERAGE
  const loadedPageRef = useRef<boolean>(false)

  useEffect(() => {
    if (userAuthenticated && !disabledAnalytics) {
      AppInsightsAdapter.allowTracking()
      if (loadedPageRef.current === false) {
        loadedPageRef.current = true
        trackEvent({
          name: 'dlPageView'
        })
      }
      return history.listen(() => {
        trackEvent({
          name: 'dlPageView'
        })
      })
    } else if (userAuthenticated && disabledAnalytics) {
      AppInsightsAdapter.stopTracking()
    }
  }, [userAuthenticated, isAnalyticsEnabled, history, trackEvent])

  return <>{children}</>
}
