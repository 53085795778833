import {DecodedToken} from '@hconnect/apiclient'
import {useAuth} from '@hconnect/common/authentication/context'

export const useAuthentication = (): {
  userAuthenticated: boolean
  userEmail: string
  decodedToken: DecodedToken | null
  userId: string
  isTestUser: boolean
  countryCode?: string
  startLogoutProcess: () => Promise<void>
  startLoginProcess: () => Promise<void>
} => {
  const {authState, decodedToken, logout, login} = useAuth()
  return {
    userAuthenticated: authState === 'authenticated',
    userEmail: decodedToken?.email ?? '',
    decodedToken: decodedToken,
    userId: decodedToken?.user_id ?? '',
    isTestUser: decodedToken?.isTestUser.toLowerCase() === 'true',
    countryCode: decodedToken?.country_code,
    startLogoutProcess: logout,
    startLoginProcess: login
  }
}
