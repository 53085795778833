import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import DoneIcon from '@material-ui/icons/Delete'
import React from 'react'
import {useTranslation} from 'react-i18next'

type DeleteAppModalProps = {
  open: boolean
  onDeleteAppEvent: () => void
  onCancel: () => void
  appName?: string
}

export const DeleteAppConfirmation = (props: DeleteAppModalProps) => {
  const {onDeleteAppEvent, open, onCancel, ...other} = props
  const {t} = useTranslation()

  return (
    <>
      <Dialog maxWidth="xs" open={open} {...other} data-test-id="delete-app-dialog">
        <DialogTitle> {t('appOverview.appDetail.deleteApp.dialogTitle')}</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            {t('appOverview.appDetail.deleteApp.dialogContent')}
          </Typography>

          <Typography variant="body1">
            {t('appOverview.appDetail.deleteApp.confirmationContent')}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            startIcon={<CloseIcon />}
            onClick={onCancel}
            data-test-id="delete-app-dialog-btn-cancel"
          >
            {t('appOverview.appDetail.deleteApp.cancelButtonLabel')}
          </Button>
          <Button
            variant="contained"
            startIcon={<DoneIcon />}
            type="submit"
            onClick={onDeleteAppEvent}
            data-test-id="delete-app-dialog-btn-submit"
          >
            {t('appOverview.appDetail.deleteApp.deleteButtonLabel')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
