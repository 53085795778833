import {Box} from '@material-ui/core'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router'

import {setAnalyticEvent} from '../common/eventTracker'
import {ApiDocView} from '../components/apiProducts/ApiDocView'
import {LoadingSpinner} from '../components/commons/LoadingSpinner'
import {AnalyticEvent, AnalyticsOperationName} from '../declarations'
import {useDatalinkRoles} from '../hooks/useRoles'
import {useTitle} from '../hooks/useTitle'
import {useTracking} from '../hooks/useTracking'
import {DatalinkUrlPathParameters} from '../routes'

export const ApiProductDetailPage = () => {
  const {apiId} = useParams<Pick<DatalinkUrlPathParameters, 'apiId'>>()
  const {t} = useTranslation()
  const apiResponseSelector = 'api-response'
  const apiRequestSelector = 'api-request'

  const title = apiId
    ? `${t('apiProductOverview.title')} - ${apiId}`
    : t('apiProductOverview.title')

  useTitle(title)

  const {
    isError: rolesQueryError,
    isFetching: isRolesQueryFetching,
    customerId,
    countryIds
  } = useDatalinkRoles()
  const {trackEvent} = useTracking(setAnalyticEvent)

  if (isRolesQueryFetching) return <LoadingSpinner />

  const setApiProductDetailPageClickEvent = (
    operationName: AnalyticsOperationName | undefined,
    responseCode?: string
  ) => {
    const eventObject: AnalyticEvent = {
      name: 'dlProductEvent',
      apiProduct: apiId,
      operationName: operationName,
      responseCode: responseCode
    }
    trackEvent(eventObject)
  }

  const addClickEventOnSpanElement = (
    spans: never[] | NodeListOf<HTMLSpanElement>,
    text: string,
    operationName: AnalyticsOperationName
  ) => {
    const expandAllSpan = Array.from(spans)?.find((el) => el?.innerText?.includes(text))

    expandAllSpan?.addEventListener('click', () => setApiProductDetailPageClickEvent(operationName))
  }

  const attachClickEventOnSpanElements = (rootNode: never[] | NodeListOf<Element>) => {
    const spans =
      Array.from(rootNode)
        .map((el) => el.shadowRoot)
        ?.find(Boolean)
        ?.querySelectorAll('span') ?? []

    addClickEventOnSpanElement(spans, 'Expand', 'dlDocumentationExpandAll')
    addClickEventOnSpanElement(spans, 'Collapse', 'dlDocumentationCollapseAll')
  }

  const attachClickEventOnCodeSampleCopyButton = (
    section: Element,
    isSummaryPanelCollapsed: boolean
  ) => {
    const sampleCodeCopyButton = section?.querySelector('div[data-tab=cURL0] button')

    if (isSummaryPanelCollapsed) {
      detachEvent(sampleCodeCopyButton, () => setApiProductDetailPageClickEvent('dlCodeSampleCopy'))
      return
    }

    sampleCodeCopyButton?.addEventListener('click', () =>
      setApiProductDetailPageClickEvent('dlCodeSampleCopy')
    )
  }

  const attachEvent = (element: Element | null | undefined, callback: () => void) => {
    if (element && element.getAttribute('dl_listenerOnClick') !== 'true') {
      element.setAttribute('dl_listenerOnClick', 'true')
      element.addEventListener('click', (e) => {
        // call handler with original context
        return callback.call(e)
      })
    }
  }

  const detachEvent = (element: Element | null | undefined, callback: () => void) => {
    if (element) {
      element.removeEventListener('click', callback)
    }
  }

  const attachClickEventOnApiRequestResponseTreeAction = (
    section: Element,
    selector: string,
    treeName: string,
    query: string,
    operationName: AnalyticsOperationName
  ) => {
    const apiResponseShadowRoot = section.querySelector(selector)?.shadowRoot

    const schemaTrees = apiResponseShadowRoot?.querySelectorAll(treeName)

    schemaTrees?.forEach((schemaTree) => {
      const multilineDescriptionLabel = schemaTree?.shadowRoot?.querySelector(query)
      attachEvent(multilineDescriptionLabel, () => {
        const selectedResponseCodeButton = apiResponseShadowRoot?.querySelector(
          'button[part*=btn-selected-response-status]'
        )
        setApiProductDetailPageClickEvent(
          operationName,
          selectedResponseCodeButton?.textContent?.trim() ?? ''
        )
      })
    })
  }

  const attachClickEventOnApiRequestResponseButtons = (
    section: Element,
    selector: string,
    exampleOperationName: AnalyticsOperationName,
    schemaOperationName: AnalyticsOperationName,
    schemaMultilineExpandOperationName: AnalyticsOperationName
  ) => {
    const buttons = section?.querySelector(selector)?.shadowRoot?.querySelectorAll('button')

    buttons?.forEach((button) => {
      attachEvent(button, () => {
        const buttonDataTabValue = button.getAttribute('data-tab')
        let operationName: AnalyticsOperationName = 'dlDocumentationResponseClick'
        const selectedResponseCodeButton = Array.from(buttons).find((button) =>
          button.part.contains('btn-selected-response-status')
        )
        let responseCode = selectedResponseCodeButton?.textContent?.trim()
        switch (buttonDataTabValue) {
          case 'example':
            operationName = exampleOperationName
            break
          case 'schema':
            operationName = schemaOperationName
            break
          default:
            responseCode = button.textContent?.trim() ?? ''
            break
        }

        setApiProductDetailPageClickEvent(operationName, responseCode)

        attachClickEventOnApiRequestResponseTreeAction(
          section,
          selector,
          'schema-tree',
          'div[class="toolbar"] div[part="schema-toolbar-item schema-multiline-toggle"]',
          schemaMultilineExpandOperationName
        )
      })
    })
  }

  const attachClickEventOnSectionEndpointElements = (rootNode: never[] | NodeListOf<Element>) => {
    const sections = Array.from(rootNode)
      .map((el) => el.shadowRoot)
      .find(Boolean)
      ?.querySelectorAll('section[part=section-endpoint]')

    sections?.forEach((section) => {
      const summaryPanel = section.querySelector('summary')
      summaryPanel?.addEventListener('click', () => {
        const isSummaryPanelCollapsed = summaryPanel.part.contains(
          'section-endpoint-head-collapsed'
        )
        attachClickEventOnCodeSampleCopyButton(section, isSummaryPanelCollapsed)

        attachClickEventOnApiRequestResponseButtons(
          section,
          apiRequestSelector,
          'dlRequestExampleClick',
          'dlRequestSchemaClick',
          'dlRequestSchemaMultilineExpand'
        )

        attachClickEventOnApiRequestResponseButtons(
          section,
          apiResponseSelector,
          'dlResponseExampleClick',
          'dlResponseSchemaClick',
          'dlResponseSchemaMultilineExpand'
        )

        attachClickEventOnApiRequestResponseTreeAction(
          section,
          apiResponseSelector,
          'json-tree',
          'div[class="json-tree"] button[part="btn btn-fill btn-copy"]',
          'dlResponseExampleCopy'
        )
      })
    })
  }

  const handleSpecLoadedEvent = () => {
    const root = document?.getElementById('root')?.querySelectorAll('*') ?? []
    attachClickEventOnSpanElements(root)

    attachClickEventOnSectionEndpointElements(root)
  }

  return (
    <Box data-test-id="page-api-product-detail">
      <ApiDocView
        id="thedoc"
        name={apiId}
        render-style="view"
        theme="light"
        allow-authentication={false}
        allow-server-selection={false}
        allow-api-list-style-selection={false}
        allow-try={false}
        show-header={false}
        show-info={true}
        allow-search={false}
        nav-bg-color="#fff"
        nav-hover-text-color="#9b0700"
        nav-hover-bg-color="#ffebea"
        primary-color="#386495"
        bg-color="#ffffff"
        data-reveal-delay="200"
        use-path-in-nav-bar={false}
        fetch-credentials="same-origin"
        data-test-id="product-api-docs"
        css-file="rapidoc.css"
        specLoaded={() => handleSpecLoadedEvent()}
        country-ids={countryIds}
      >
        {!rolesQueryError && customerId !== undefined && (
          <div data-test-id="api-doc-customer-info">
            <div style={{display: 'flex', margin: '8px 5px'}}>
              <div style={{flex: '1'}}></div>
              <div data-test-id="api-doc-customer-id">
                <b>{t('apiProductOverview.customerId')} </b>&nbsp;:&nbsp; {customerId}
              </div>
            </div>
          </div>
        )}
      </ApiDocView>
    </Box>
  )
}
