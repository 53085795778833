import {Role} from '../declarations'

export const hasDatalinkDeveloperRole = (roles: Role[]) =>
  roles?.some((r) => r.roleType === 'DATALINK_DEVELOPER')

export const hasIntegrationPartnerRole = (roles: Role[]) =>
  roles?.some((r) => r.roleType === 'DATALINK_INTEGRATION_PARTNER')

export const hasDatalinkAdminRole = (roles: Role[]) =>
  roles?.some((r) => r.roleType === 'DATALINK_ADMIN')

export const hasDatalinkDeveloperOrAdminRole = (roles: Role[]) =>
  hasDatalinkDeveloperRole(roles) || hasDatalinkAdminRole(roles)

export const getCustomerId = (roles: Role[]): string | undefined => {
  const developerRole = roles?.filter((r) => r.roleType === 'DATALINK_DEVELOPER')[0]

  return developerRole?.dataScope.customerIds?.[0]
}

export const getBusinessLine = (roles: Role[]): string | undefined => {
  return roles?.find((r) => r.roleType === 'DATALINK_DEVELOPER')?.dataScope?.businessLine
}

export const getCountryIds = (roles: Role[]): string[] => {
  const countryIds = roles
    ?.map((r) => r.dataScope?.countryId)
    .filter((countryId): countryId is string => !!countryId)

  const distinctCountryIds = [...new Set(countryIds)]

  return distinctCountryIds
}

export const getRoleTypes = (roles: Role[]): string[] => {
  return roles?.map((item) => item.roleType)
}
