import {useLocation} from 'react-router'

import {ShellVariant} from '../declarations'
import {dataLinkRoutes} from '../routes'

export const usePageLayout = () => {
  const location = useLocation()

  const shellVariant: ShellVariant =
    location.pathname === dataLinkRoutes.SignUpPage ? ShellVariant.SignUp : ShellVariant.Default

  return {
    shellVariant: shellVariant
  }
}
