import {Button, Card, CardActions, CardContent, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router'

import {dataLinkRoutes} from '../../routes'

import {useStyles} from './RequestAccount.styles'

export const RequestAccountConfirmation = () => {
  const history = useHistory()
  const {t} = useTranslation()
  const {classes} = useStyles()

  const handleButtonClick = () => {
    history.push(dataLinkRoutes.GetStarted)
  }

  return (
    <Card
      className={classes.requestAccountConfirmationContainer}
      data-test-id="requestAccountConfirmation-card"
    >
      <CardContent className={classes.requestAccountConfirmationContent}>
        <Typography>
          <div dangerouslySetInnerHTML={{__html: t('signUp.requestAccountConfirmation.text')}} />
        </Typography>
      </CardContent>
      <CardActions className={classes.confirmationCardActions}>
        <Button
          onClick={handleButtonClick}
          variant="contained"
          className={classes.confirmationWatchBtn}
          data-test-id="requestAccountConfirmation-btn-getStarted"
        >
          {t('signUp.requestAccountConfirmation.buttonText')}
        </Button>
      </CardActions>
    </Card>
  )
}
