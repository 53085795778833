import {Box, Paper} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'

import {GlossaryGuideCard} from '../components/guides/GlossaryGuideCard'
import {useCommonBoxStyles} from '../hooks/useCommonBoxStyles'
import {useTitle} from '../hooks/useTitle'

export const Glossary = () => {
  useHistory()
  const classes = useCommonBoxStyles()
  const {t} = useTranslation()
  useTitle(t('guide.glossary.title'))

  return (
    <Paper data-test-id="page-glossary" className={classes.paper}>
      <Box data-test-id="layouts-glossary-card" className={classes.box}>
        <GlossaryGuideCard />
      </Box>
    </Paper>
  )
}
