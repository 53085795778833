import {Box, Card, CardContent, CardMedia, Typography} from '@material-ui/core'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {EmptyAppGraphic} from '../../assets'

export const EmptyApiProductInfo = (props: {variant?: 'text'}) => {
  const {t} = useTranslation()

  if (props.variant === 'text') {
    return <Typography variant="body1">{t('apiProductOverview.defaultMessage')}</Typography>
  }

  return (
    <>
      <Box display="flex" justifyContent="center">
        <Card component="div">
          <CardMedia component="img" image={EmptyAppGraphic} alt="empty apps" />
          <CardContent>
            <Typography variant="body1">{t('apiProductOverview.defaultMessage')}</Typography>
          </CardContent>
        </Card>
      </Box>
    </>
  )
}
