import {dataTestId} from '@hconnect/uikit'
import {Box, Checkbox, TextField, Typography} from '@mui/material'
import {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {AnotherReasonBox, useStyles} from './Survey.styles'
import {SurveyProps} from './SurveyTypes'

const MAX_COMMENT_LENGTH = 147

export const SurveyTextFieldCard = (props: SurveyProps) => {
  const {t} = useTranslation()
  const {classes} = useStyles()
  const [checked, setChecked] = useState(false)
  const [reason, setReason] = useState('')
  const {updateSurveyAnswer, removeSurveyAnswer} = props

  const onAnsweredSurvey = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked)
    if (!event.target.checked) {
      setReason('')
      removeSurveyAnswer('survey.purpose.anotherReason.label')
    }
  }

  const handleTextFieldChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReason(event.target.value)
    updateSurveyAnswer({
      answer: 'survey.purpose.anotherReason.label',
      reason: event.target.value
    })
  }

  return (
    <AnotherReasonBox {...dataTestId('box-survey-another-reason')}>
      <Box {...dataTestId('box-survey-another-reason-checkbox-section')}>
        <Checkbox
          {...dataTestId('box-survey-another-reason-checkbox')}
          id="box-survey-another-reason-checkbox"
          checked={checked}
          onChange={onAnsweredSurvey}
        />
      </Box>
      <Box sx={{paddingTop: '10px'}} {...dataTestId('stack-survey-another-reason-content')}>
        <Typography
          className={classes.surveyItemLabel}
          {...dataTestId('box-survey-another-reason-label')}
        >
          {t('survey.purpose.anotherReason.label')}
        </Typography>

        <TextField
          fullWidth
          disabled={!checked}
          hiddenLabel
          multiline
          value={reason}
          rows={2}
          placeholder={t('survey.purpose.anotherReason.placeholder')}
          className={classes.anotherReasonTextField}
          {...dataTestId('panel-survey-another-reason_input')}
          inputProps={{
            maxLength: MAX_COMMENT_LENGTH,
            id: 'panel-survey-another-reason_input'
          }}
          onChange={handleTextFieldChanged}
        />
      </Box>
    </AnotherReasonBox>
  )
}
