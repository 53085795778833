import {AxiosInstance} from 'axios'
import {QueryFunction, useQuery} from 'react-query'

import {datalinkPublicApi} from '../common/datalinkApi'
import {Changelog} from '../declarations'

const ChangelogsEndPoint = 'developer-portal/changelogs'

const ChangelogFetcher =
  (datalinkApi: AxiosInstance): QueryFunction<Changelog[]> =>
  async () => {
    const response = await datalinkApi.get<Changelog[]>(ChangelogsEndPoint)
    return response.data
  }

export const useDeveloperPortalChangelogs = () => {
  return useQuery(['Changelogs'], ChangelogFetcher(datalinkPublicApi))
}
