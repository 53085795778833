import {InputTextField} from '@hconnect/uikit/src/lib2/components/inputs'
import React, {ChangeEventHandler} from 'react'
import {useTranslation} from 'react-i18next'

import {useDatalinkBusinessLines} from '../../hooks/useDatalinkBusinessLines'

import {AccountFormComponentProps} from './types'

interface Props extends AccountFormComponentProps {
  value: string
  onChange: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
  [x: string]: any
}

export const BusinessLineField: React.FC<Props> = ({
  name,
  value,
  onChange,
  formMethods,
  ...rest
}) => {
  const {t} = useTranslation()

  const {watch} = formMethods
  const watchedCountry = watch('country')

  const {data, isLoading} = useDatalinkBusinessLines(watchedCountry)

  const selectValues = data?.map((bl) => ({
    label: bl.businessLineName,
    value: bl.businessLineCode
  }))

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const isSupported =
      data!.find((a) => a.businessLineCode === e.target.value)!.isSupported ?? false
    formMethods.setValue('isSupported', isSupported)
    onChange(e)
  }

  return (
    <InputTextField
      id="businessLineField"
      name={name}
      value={value}
      onChange={handleChange}
      error={!!formMethods.formState.errors?.[name]}
      disabled={!watchedCountry || isLoading}
      label={t('signUp.requestAccountForm.labels.businessLine')}
      type="text"
      select
      selectValues={selectValues}
      data-test-id="businessLineField-select"
      {...rest}
    />
  )
}
