import {AppInsightsAdapter} from '@hconnect/common/logging'
import React from 'react'
import ReactDOM from 'react-dom'

import './index.css'
import {App} from './App'
import {initLocalization} from './localization'
import {DatalinkAppProvider} from './providers/DatalinkAppProvider'

initLocalization()
AppInsightsAdapter.init()

ReactDOM.render(
  <React.StrictMode>
    <DatalinkAppProvider>
      <App />
    </DatalinkAppProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
