import {Page} from '@hconnect/uikit'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'

import {Headline} from '../components/landingPage/Headline'
import {UseCases} from '../components/landingPage/UseCases'
import {useTitle} from '../hooks/useTitle'

export const Home = () => {
  useHistory()
  const {t} = useTranslation()

  useTitle(t('landingPage.title'))

  return (
    <div>
      <Page px={6} boxed={true}>
        <Headline />
      </Page>
      <Page px={6} boxed={true} variant="sheet">
        <UseCases />
      </Page>
    </div>
  )
}
