import {Box, Button, Menu, MenuItem, Typography} from '@mui/material'
import {styled} from '@mui/system'
import {MouseEventHandler, useState} from 'react'
import {NavLink} from 'react-router-dom'

export type NavigationItemProps = {
  text: string
  page?: string
  hidden: boolean
  subMenu?: SubMenu[]
  onClick?: MouseEventHandler
}

type SubMenu = {
  text: string
  page: string
}

const DefaultNavLinkStyles = {
  fontSize: '16px',
  fontWeight: 500,
  color: '#616161',
  textDecorationLine: 'none'
}

const StyledNavLink = styled(NavLink)(() => ({
  ...DefaultNavLinkStyles,
  paddingBottom: 4,
  '&.active': {
    color: '#29aaff',
    borderBottomColor: '#29aaff',
    borderBottomWidth: 2,
    borderBottomStyle: 'solid',
    pointerEvents: 'none'
  }
}))

const StyledDropDownLink = styled(Typography)(() => ({
  ...DefaultNavLinkStyles
}))

export const NavigationItem = (props: NavigationItemProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Box sx={{mx: 3}} data-test-id={`nav-link-${props.page}`}>
      {props.subMenu !== undefined ? (
        <>
          <Button
            onClick={handleClick}
            sx={{textTransform: 'capitalize'}}
            data-test-id={`${props.text}-dropdown`}
          >
            <StyledDropDownLink>{props.text}</StyledDropDownLink>
          </Button>

          <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
            {props.subMenu?.map((s) => (
              <MenuItem onClick={handleClose} key={s.page}>
                <StyledNavLink to={s.page} data-test-id={`${s.text.replace(/ /g, '-')}-menu-item`}>
                  {s.text}
                </StyledNavLink>
              </MenuItem>
            ))}
          </Menu>
        </>
      ) : (
        <StyledNavLink to={props.page ?? ''} hidden={props.hidden} onClick={props.onClick}>
          {props.text}
        </StyledNavLink>
      )}
    </Box>
  )
}
