import './App.css'
import React from 'react'

import {AnalyticTrackerShell} from './container/AnalyticTrackerShell'
import {DefaultShell} from './container/DefaultShell'
import {SignUpShell} from './container/SignUpShell'
import {DatalinkRouter} from './DatalinkRouter'
import {ShellVariant} from './declarations'
import {usePageLayout} from './hooks/usePageLayout'
import {DatalinkEventProvider} from './providers/DatalinkEventProvider'

export const App = () => {
  const {shellVariant} = usePageLayout()

  const AppShell = shellVariant === ShellVariant.Default ? DefaultShell : SignUpShell

  return (
    <AppShell>
      <AnalyticTrackerShell>
        <DatalinkEventProvider>
          <DatalinkRouter />
        </DatalinkEventProvider>
      </AnalyticTrackerShell>
    </AppShell>
  )
}
