import {Box, CircularProgress, Typography} from '@material-ui/core'
import React from 'react'
import {useTranslation} from 'react-i18next'

export const LoadingSpinner = () => {
  const {t} = useTranslation()
  return (
    <Box flex={1} display="flex" color="text.secondary" alignItems="center" justifyContent="center">
      <Box display="flex" flexDirection="column" alignItems={'center'}>
        <CircularProgress />
        <Typography variant="body1"> {t('loadingSpinnerText')}</Typography>
      </Box>
    </Box>
  )
}
