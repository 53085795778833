import BlackboardIllustrationGraphic from './blackboard-illustration.svg'
import CalendarIcon from './calendar.png'
import DataLinkIcon from './datalink.svg'
import EmptyAppGraphic from './emptyAppGraphic.png'
import Glossaries from './glossaries.json'
import HcBrandingIcon from './hcbranding.png'
import HConnectLogo from './hconnect_logo.svg'
import HeaderIllustrationBackground from './header-illustration.png'
import HeidelbergMaterialsLogo from './HeidelbergMaterialsLogo.svg'
import LetterboxIllustrationGraphic from './letterbox-illustration.png'
import PricingIllustration from './pricing-Illustration.svg'
import ResourceOwnerPasswordCredentialsDiagram from './resource_owner_password_credentials.svg'
import RestMaturityLevelBadge2 from './rest-maturity-level-badge-2.svg'
import RestMaturityLevelBadge3 from './rest-maturity-level-badge-3.svg'
import SearchingIllustrationGraphic from './searching-illustration.png'
import Subscriptions from './subscriptions.json'
import ToolBoxIcon from './toolbox.png'
import TruckIcon from './truck.png'
import WorkIllustrationGraphic from './working-illustration.png'

export {
  HeaderIllustrationBackground,
  CalendarIcon,
  HcBrandingIcon,
  ToolBoxIcon,
  TruckIcon,
  DataLinkIcon,
  EmptyAppGraphic,
  HConnectLogo,
  BlackboardIllustrationGraphic,
  SearchingIllustrationGraphic,
  LetterboxIllustrationGraphic,
  WorkIllustrationGraphic,
  ResourceOwnerPasswordCredentialsDiagram,
  Glossaries,
  RestMaturityLevelBadge2,
  RestMaturityLevelBadge3,
  HeidelbergMaterialsLogo,
  PricingIllustration,
  Subscriptions
}
