import {Box} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {EmptyApiProductInfo} from '../components/apiProducts/EmptyApiProductInfo'
import {ErrorOccurredText} from '../components/commons/ErrorOccurredText'
import {LoadingSpinner} from '../components/commons/LoadingSpinner'
import {FilterAction, useApiProducts} from '../hooks/useApiProducts'
import {useTitle} from '../hooks/useTitle'
import {ApiProductsCard} from '../layouts/ApiProductsCard'

export const ApiProductsPage = () => {
  const apiProductsQueryInfo = useApiProducts(FilterAction.Viewable)

  const {t} = useTranslation()
  useTitle(t('apiProductOverview.title'))

  if (apiProductsQueryInfo.isLoading) return <LoadingSpinner />

  if (apiProductsQueryInfo.isSuccess && apiProductsQueryInfo.data?.apiProduct?.length === 0) {
    return <EmptyApiProductInfo />
  }

  if (apiProductsQueryInfo.isError) return <ErrorOccurredText />

  return (
    <Box data-test-id="page-api-product">
      <ApiProductsCard record={apiProductsQueryInfo.data?.apiProduct} />
    </Box>
  )
}
