import {CircularProgress} from '@material-ui/core'
import {Button} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {useStyles} from './RequestAccount.styles'

type Props = {
  loading: boolean
  disabled: boolean
}

export const SubmitButton: React.FC<Props> = ({loading, disabled}) => {
  const {t} = useTranslation()
  const {classes} = useStyles()

  return (
    <>
      <Button
        data-test-id="useRegistration-submitButton"
        disabled={loading || disabled}
        className={classes.submitButton}
        color="primary"
        size="large"
        type="submit"
        variant="contained"
      >
        {t('signUp.requestAccountForm.submitButtonText')}
      </Button>
      {loading && <CircularProgress />}
    </>
  )
}
