import {AxiosInstance} from 'axios'
import {QueryFunction, useMutation, useQuery} from 'react-query'

import {ApigeeApp, ApigeeApps, AppModel} from '../declarations'

import {useDatalinkSecureApi} from './useDatalinkSecureApi'

type UseDeveloperApps = ['datalink', 'apps']
type UseDeveloperAppByNameQueryKey = ['datalink', 'app', {appName?: string}]

type UseDeveloperAppUpdateMutationKey = [
  'datalink/apps',
  {app: {payload: {description: string; name: string; companyId: string}; uniqueName: string}}
]
type UseDeveloperAppCreationMutationKey = ['datalink/apps', {app: AppModel}]
type UseDeveloperAppDeletionMutationKey = ['datalink/apps', {appName?: string}]

const DeveloperAppsEndPoint = `/datalink/developerportal/${process.env.REACT_APP_ORGANIZATION_NAME}`

const appsFetcher =
  (secureApi: AxiosInstance): QueryFunction<ApigeeApps | undefined, UseDeveloperApps> =>
  async () => {
    const response = await secureApi.get<ApigeeApps>(`${DeveloperAppsEndPoint}/apps`)
    return response.data
  }

const appFetcher =
  (secureApi: AxiosInstance): QueryFunction<ApigeeApp | undefined, UseDeveloperAppByNameQueryKey> =>
  async ({queryKey}) => {
    const [, , {appName}] = queryKey
    const response = await secureApi.get<ApigeeApp>(`${DeveloperAppsEndPoint}/apps/${appName}`)
    return response.data
  }

const appsModifier =
  (secureApi: AxiosInstance) => async (queryKey: UseDeveloperAppUpdateMutationKey) => {
    const [, {app}] = queryKey
    const response = await secureApi.put<ApigeeApp>(
      `${DeveloperAppsEndPoint}/apps/${app.uniqueName}`,
      app.payload
    )
    return response.data
  }

const appsCreator =
  (secureApi: AxiosInstance) => async (queryKey: UseDeveloperAppCreationMutationKey) => {
    const [, {app}] = queryKey
    const response = await secureApi.post<ApigeeApp>(`${DeveloperAppsEndPoint}/apps`, app.payload)
    return response.data
  }

const appsEliminator =
  (secureApi: AxiosInstance) => async (queryKey: UseDeveloperAppDeletionMutationKey) => {
    const [, {appName}] = queryKey
    const response = await secureApi.delete<ApigeeApp>(`${DeveloperAppsEndPoint}/apps/${appName}`)
    return response.data
  }

export const useApps = () => {
  const secureApi = useDatalinkSecureApi()

  return useQuery(['datalink', 'apps'], appsFetcher(secureApi))
}

export const useAppByName = (appName: string) => {
  const secureApi = useDatalinkSecureApi()

  return useQuery(['datalink', 'app', {appName}], appFetcher(secureApi))
}

export const useCreateAppMutation = () => {
  const secureApi = useDatalinkSecureApi()

  return useMutation(appsCreator(secureApi))
}

export const useDeleteAppMutation = () => {
  const secureApi = useDatalinkSecureApi()

  return useMutation(appsEliminator(secureApi))
}

export const useModifyAppMutation = () => {
  const secureApi = useDatalinkSecureApi()
  return useMutation(appsModifier(secureApi))
}
