import {useApi} from '@hconnect/common/hooks/useApi'
import {AxiosInstance} from 'axios'
import {QueryFunction, useQuery} from 'react-query'

import {RestLevel} from '../declarations'

type UseOpenApi = ['datalink', 'openApi']

const OpenApiEndPoint = '/datalink/developerportal'

const restLevelFetcher =
  (publicApi: AxiosInstance): QueryFunction<RestLevel | undefined, UseOpenApi> =>
  async () => {
    const response = await publicApi.get<RestLevel>(`${OpenApiEndPoint}/openapi/restlevels`)
    return response.data
  }

export const useRestLevels = () => {
  const {publicApi} = useApi()

  return useQuery(['datalink', 'openApi'], restLevelFetcher(publicApi))
}
