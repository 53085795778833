import CheckIcon from '@mui/icons-material/Check'
import MoodIcon from '@mui/icons-material/Mood'
import {
  Box,
  Stack,
  Paper,
  Typography,
  ListItemText,
  List,
  ListItem,
  ListItemIcon,
  Chip
} from '@mui/material'
import classNames from 'classnames'

import {Subscription} from '../../declarations'
import {useCommonBoxStyles} from '../../hooks/useCommonBoxStyles'
import {useStyles} from '../../layouts/PricingLayout.styles'

export const PricingItem = ({subscription}: {subscription: Subscription}) => {
  const boxClasses = useCommonBoxStyles()

  const {classes} = useStyles()
  return (
    <Paper
      data-test-id="pricing-item"
      className={classNames(classes.paper, boxClasses.paper, {
        recommended: subscription.recommended
      })}
    >
      <Stack direction={'row'} justifyContent={'space-between'}>
        <Typography variant="h6" className={classes.title}>
          {subscription.name}
        </Typography>
        {subscription.recommended && (
          <Chip
            data-test-id="recommended-chip"
            color="primary"
            size="small"
            className={classes.recommended}
            icon={<MoodIcon />}
            label="Recommended"
          />
        )}
      </Stack>
      <Box className={classes.options}>
        {subscription.description && (
          <Typography variant="body1" className={classes.description}>
            {subscription.description}
          </Typography>
        )}
        <List dense={true} className={classes.list}>
          {subscription.options.map((option, index) => (
            <ListItem key={index}>
              <ListItemIcon>
                <CheckIcon sx={{fontSize: '16px'}} />
              </ListItemIcon>
              <ListItemText
                primary={option.text}
                primaryTypographyProps={{fontWeight: option.bold ? 'bold' : 'normal'}}
              />
            </ListItem>
          ))}
        </List>
      </Box>
      <Box>
        <Stack direction={'column'} justifyContent={'space-between'}>
          <Typography variant="h6" className={classes.quota}>
            {subscription.quota}
          </Typography>
          <Typography variant="h6" className={classes.price}>
            {subscription.price}
          </Typography>
          <Typography variant="caption" className={classes.priceDescription}>
            {subscription.priceDescription}
          </Typography>
        </Stack>
      </Box>
    </Paper>
  )
}
