import {useAuth} from '@hconnect/common/authentication/context'
import {useHistory} from 'react-router'

import {EventContext} from '../context/EventContext'
import {useDatalinkPermissions} from '../hooks/usePermission'

export const DatalinkEventProvider = ({children}) => {
  const {decodedToken} = useAuth()
  const {permissions} = useDatalinkPermissions()
  const {location} = useHistory()

  const eventData = {
    userId: decodedToken?.user_id,
    country: decodedToken?.country_code,
    route: location.pathname,
    permissions: permissions
  }

  return <EventContext.Provider value={eventData}>{children}</EventContext.Provider>
}
