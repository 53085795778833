import {trackEventWithBrowserProps} from '@hconnect/common/logging/Analytics'

import {AnalyticEvent} from '../declarations'

export const setAnalyticEvent = (event: AnalyticEvent) => {
  trackEventWithBrowserProps(event.name ?? '', {
    ...{
      name: event.name,
      domain: window.location.host,
      pageUrl: window.location.href,
      source: 'df',
      clientId: process.env.REACT_APP_CLIENT_ID,
      apiProduct: event.apiProduct,
      user_uuid: event.userId,
      country: event.countryCode,
      businessLine: event.businessLine,
      customerId: event.customerId,
      role: event.roles,
      operationName: event.operationName,
      responseCode: event.responseCode
    },
    creationChannel: process.env.REACT_APP_ANALYTICS_APP_ID
  })
}

export const setDeveloperAppAnalyticEvent = (event: AnalyticEvent) => {
  setAnalyticEvent({...event, name: 'dlAppEvent'})
}
