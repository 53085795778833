import {Page} from '@hconnect/uikit'
import {useEffect, useState} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'

import {useDatalinkRoles} from './hooks/useRoles'
import {ApiProductDetailPage} from './pages/ApiProductDetailPage'
import {ApiProductsPage} from './pages/ApiProductsPage'
import {AppDetailsPage} from './pages/AppDetailsPage'
import {AppOverviewPage} from './pages/AppOverviewPage'
import {ChangeLogPage} from './pages/ChangeLogPage'
import {CookiesPolicyPage} from './pages/CookiesPolicyPage'
import {FAQ} from './pages/FAQPage'
import {GetStartedPage} from './pages/GetStartedPage'
import {Glossary} from './pages/Glossary'
import {Home} from './pages/Home'
import {Imprint} from './pages/Imprint'
import {LegalPage, OpendTab} from './pages/LegalPage'
import {SettingsPage} from './pages/SettingsPage'
import {SignUpPage} from './pages/SignUpPage'
import {dataLinkRoutes} from './routes'
import { PricingPage } from './pages/PricingPage'

export const DatalinkRouter = () => {
  const {isDatalinkDeveloper, isCompanyAssigned, isFetching} = useDatalinkRoles()
  const [canAccessAppDetail, setCanAccessAppDetail] = useState(true)

  useEffect(() => {
    if (!isFetching && isDatalinkDeveloper) {
      setCanAccessAppDetail(!!isCompanyAssigned)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDatalinkDeveloper, isCompanyAssigned, isFetching])

  return (
    <Switch>
      <Route path={dataLinkRoutes.LandingPage} exact component={Home} />
      <Route
        path={dataLinkRoutes.ApiProductOverviewPage}
        exact
        render={() => (
          <Page px={6} boxed={true}>
            <ApiProductsPage />
          </Page>
        )}
      />
      <Route
        path={dataLinkRoutes.ApiProductDetailPage}
        render={() => (
          <Page px={6} boxed={true}>
            <ApiProductDetailPage />
          </Page>
        )}
      />
      <Route
        path={dataLinkRoutes.AppOverviewPage}
        render={() => (
          <Page px={6} boxed={true}>
            <AppOverviewPage />
          </Page>
        )}
      />
      {canAccessAppDetail && (
        <Route
          path={dataLinkRoutes.AppDetailPage}
          exact
          render={() => (
            <Page px={6} boxed={true}>
              <AppDetailsPage />
            </Page>
          )}
        />
      )}
      <Route
        path={dataLinkRoutes.ImprintPage}
        exact
        render={() => (
          <Page px={6} boxed={true}>
            <Imprint />
          </Page>
        )}
      />
      <Route
        path={dataLinkRoutes.SettingsPage}
        exact
        render={() => (
          <Page px={6} boxed={true}>
            <SettingsPage />
          </Page>
        )}
      />
      <Route
        path={dataLinkRoutes.Glossary}
        exact
        render={() => (
          <Page px={6} boxed={true}>
            <Glossary />
          </Page>
        )}
      />
      <Route
        path={dataLinkRoutes.GetStarted}
        exact
        render={() => (
          <Page px={6} boxed={true}>
            <GetStartedPage />
          </Page>
        )}
      />
      <Route
        path={dataLinkRoutes.FAQ}
        exact
        render={() => (
          <Page px={6} boxed={true}>
            <FAQ />
          </Page>
        )}
      />
      <Route
        path={dataLinkRoutes.DataProtectionPage}
        exact
        render={() => (
          <Page px={6} boxed={true}>
            <LegalPage openTab={OpendTab.DataProtection} />
          </Page>
        )}
      />
      <Route
        path={dataLinkRoutes.TermsAndConditionsPage}
        exact
        render={() => (
          <Page px={6} boxed={true}>
            <LegalPage openTab={OpendTab.TermsAndConditions} />
          </Page>
        )}
      />
      <Route
        path={dataLinkRoutes.Cookies}
        exact
        render={() => (
          <Page px={6} boxed={true}>
            <CookiesPolicyPage />
          </Page>
        )}
      />
      <Route
        path={dataLinkRoutes.SignUpPage}
        exact
        render={() => (
          <Page boxed={true}>
            <SignUpPage />
          </Page>
        )}
      />
      <Route
        path={[dataLinkRoutes.Changelogs, dataLinkRoutes.ChangelogsTag]}
        exact
        render={() => (
          <Page boxed={true}>
            <ChangeLogPage />
          </Page>
        )}
      />
      <Route
        path={dataLinkRoutes.PricingPage}
        exact
        render={() => (
          <Page boxed={true}>
            <PricingPage />
          </Page>
        )}
      />
      <Redirect to={dataLinkRoutes.LandingPage} />
    </Switch>
  )
}
