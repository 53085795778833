import {Chip, Stack} from '@mui/material'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'

import {ChangelogTag} from '../../declarations'
import {dataLinkRoutes} from '../../routes'

import {useStyles} from './Changelog.styles'
import {Tag} from './Tag'

export const TagList = ({tags}: {tags: ChangelogTag[]}) => {
  const {classes} = useStyles()
  const {t} = useTranslation()
  return (
    <Stack
      data-test-id="component-tagsList"
      direction={'row'}
      spacing={0}
      sx={{flexWrap: 'wrap', gap: 0.5}}
    >
      <Chip
        data-test-id="tags-all-label"
        label={t('changelog.tagsList.all')}
        className={classes.tag}
        color="primary"
        variant="outlined"
        component={Link}
        to={dataLinkRoutes.Changelogs}
        clickable
      />
      {tags.map((tag) => (
        <Tag key={tag.text} tag={tag} />
      ))}
    </Stack>
  )
}
