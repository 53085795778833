import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {ExternalPageDialog} from '../components/commons/ExternalPageDialog'
import {RestMaturityBadge} from '../components/landingPage/RestMaturityBadge'

export type RestMaturityBadgeShellProps = {
  level: number
  [x: string]: unknown
}

export const RestMaturityBadgeShell = (props: RestMaturityBadgeShellProps) => {
  const {t} = useTranslation()
  const [externalPageDialogVisible, setExternalPageDialogVisible] = useState(false)
  const {level, ...rest} = props
  const restMaturityModelLink = t('externalLinks.restMaturityModel')

  const handleBadgeClick = () => {
    setExternalPageDialogVisible(true)
  }

  const handleExternalPageDialogClose = () => {
    setExternalPageDialogVisible(false)
  }

  return (
    <>
      <RestMaturityBadge level={level} onClick={handleBadgeClick} {...rest} />
      {externalPageDialogVisible && (
        <ExternalPageDialog link={restMaturityModelLink} onClose={handleExternalPageDialogClose} />
      )}
    </>
  )
}
