import {CardMedia} from '@material-ui/core'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {RestMaturityLevelBadge2, RestMaturityLevelBadge3} from '../../assets'
import {RestMaturityMaxLevel} from '../../common/openApi'
import {RestMaturityBadgeShellProps} from '../../container/RestMaturityBadgeShell'

type RestMaturityBadgeProps = {
  onClick: () => void
}

type Props = RestMaturityBadgeShellProps & RestMaturityBadgeProps

export const RestMaturityBadge = (props: Props) => {
  const {t} = useTranslation()
  const {level, ...rest} = props
  const restMaturityLevelBadgeImg =
    level === RestMaturityMaxLevel ? RestMaturityLevelBadge3 : RestMaturityLevelBadge2

  return (
    <CardMedia
      component="img"
      data-test-id="restMaturityBadge-img"
      src={restMaturityLevelBadgeImg}
      alt={t('landingPage.restMaturityLevel')}
      {...rest}
    />
  )
}
