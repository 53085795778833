import {makeStyles} from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import {Warning} from '@material-ui/icons'
import CloseIcon from '@material-ui/icons/Close'
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {LayoutConstants} from '../../common'

const useStyles = makeStyles(() => ({
  box: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    height: 120,
    maxWidth: LayoutConstants.DEFAULT_CONTENT_WIDTH,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  icon: {
    alignSelf: 'center'
  },
  alert: {
    width: '100%',
    whiteSpace: 'pre-line'
  }
}))

type Props = {
  onClose: () => void
}

export const UnsupportedBrowserWarning: React.FC<Props> = ({onClose}) => {
  const {t} = useTranslation()
  const classes = useStyles()

  return (
    <div className={classes.box}>
      <Alert
        className={classes.alert}
        severity="warning"
        icon={<Warning fontSize="large" className={classes.icon} />}
        action={
          <IconButton color="inherit" size="small" onClick={() => onClose()}>
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
      >
        <AlertTitle>{t('unsupportedBrowserTitle')}</AlertTitle>
        <span style={{display: 'block'}}>{t('unsupportedBrowser')}</span>
        <span style={{display: 'block'}}>{t('unsupportedBrowserHint')}</span>
      </Alert>
    </div>
  )
}
