import {Paper, Typography} from '@material-ui/core'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {useCommonBoxStyles} from '../hooks/useCommonBoxStyles'
import {useTitle} from '../hooks/useTitle'

const imprint = `
  HDigital GmbH
  Berliner Strasse 6
  69120 Heidelberg
  Germany

  Managing Director: Noman Ahmed
  E-mail address: appsupport(at)heidelbergcement.com
  Telephone number: +49 6221-4810
  Register entries Court of registration: Amtsgericht Mannheim HRB 733979
`

export const Imprint = () => {
  const classes = useCommonBoxStyles()
  const {t} = useTranslation()
  useTitle(t('imprint.title'))
  return (
    <>
      <Typography variant="h2" className={classes.header}>
        {t('imprint.title')}
      </Typography>
      <Paper className={classes.paper}>
        <Typography variant="body2" className={classes.details}>
          {imprint}
        </Typography>
      </Paper>
    </>
  )
}
