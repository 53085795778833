import {LegalContext} from '../context/LegalContext'
import {useAuthentication} from '../hooks/useAuthentication'
import {useDataProtectionPrivacy} from '../hooks/useDataProtectionPrivacy'

export const DatalinkLegalContextProvider = ({children}) => {
  const {acknowledgeUserDataProtectionPrivacy, userAcknowledgedDataProtectionPrivacy} =
    useDataProtectionPrivacy()
  const {userAuthenticated} = useAuthentication()

  return userAuthenticated ? (
    <LegalContext.Provider
      value={{
        acknowledgeUserDataProtectionPrivacy: acknowledgeUserDataProtectionPrivacy,
        userAcknowledgedDataProtectionPrivacy: userAcknowledgedDataProtectionPrivacy
      }}
    >
      {children}
    </LegalContext.Provider>
  ) : (
    <>{children}</>
  )
}
