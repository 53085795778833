import {useCallback, useMemo, useState} from 'react'

import {Survey} from './SurveyTypes'

/**
 * Set restore survey answers
 * @param answer selected answers of the survey
 */
export const useSurveyAnswers = () => {
  const emptyArray: Survey[] = []
  const [answers, setAnswers] = useState(emptyArray)

  const updateSurveyAnswer = useCallback(
    (survey: Survey) => {
      setAnswers([...answers.filter((f) => f.answer !== survey.answer), survey])
    },
    [answers]
  )

  const removeSurveyAnswer = useCallback(
    (surveyItem: string) => {
      setAnswers(answers.filter((f) => f.answer !== surveyItem))
    },
    [answers]
  )

  return useMemo(
    () => ({
      updateSurveyAnswer,
      removeSurveyAnswer,
      answers
    }),
    [updateSurveyAnswer, removeSurveyAnswer, answers]
  )
}
