import {dataTestId} from '@hconnect/uikit'
import {Typography} from '@mui/material'
import {useTranslation} from 'react-i18next'

import {TitleBox, useStyles} from './Survey.styles'

export const SurveyTitleCard = () => {
  const {t} = useTranslation()
  const {classes} = useStyles()
  return (
    <TitleBox {...dataTestId('container-survey-title')}>
      <Typography className={classes.surveyTitle} gutterBottom>
        {t('survey.title')}
      </Typography>
      <Typography className={classes.surveySubTitle} gutterBottom>
        {t('survey.subTitle')}
      </Typography>
    </TitleBox>
  )
}
