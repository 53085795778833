import {
  makeStyles,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from '@material-ui/core'
import {ArrowForwardIos} from '@material-ui/icons'
import {Box, Stack} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {setAnalyticEvent} from '../../common/eventTracker'
import {useCommonTableStyles} from '../../hooks/useCommonTableStyles'
import {useUserMutation, useUserSettings} from '../../hooks/useSettings'
import {useTracking} from '../../hooks/useTracking'

const useStyle = makeStyles((theme) => ({
  margin: {
    marginTop: theme.spacing(2)
  },
  boxLeft: {
    width: '20%',
    minWidth: '180px'
  },
  boxRight: {
    width: '80%',
    minWidth: '400px'
  },
  iconAlign: {
    verticalAlign: 'middle'
  }
}))

interface Props {
  userEmail: string
}

export const UserSettingsGrid = (props: Props) => {
  const classes = useStyle()
  const {t} = useTranslation()
  const tableClasses = useCommonTableStyles()

  const {isAnalyticsEnabled} = useUserSettings(props.userEmail)

  const {mutateAsync: setAnalytics} = useUserMutation()

  const {trackEvent} = useTracking(setAnalyticEvent)

  const handleChangeAnalyticSetting = async () => {
    const data = {isAnalyticsEnabled: !isAnalyticsEnabled}
    await setAnalytics([
      'users',
      {
        userEmail: props.userEmail,
        data
      }
    ])
    trackEvent({
      name: 'dlSettings',
      operationName: data.isAnalyticsEnabled ? 'UsageAnalyticsOn' : 'UsageAnalyticsOff'
    })
  }

  return (
    <Stack direction={'row'} spacing={2} className={classes.margin} data-test-id="settings-grid">
      <Box
        bgcolor="background.paper"
        color="text.primary"
        borderRadius={2}
        p={3}
        className={classes.boxLeft}
      >
        <TableContainer>
          <Table size="small">
            <TableBody>
              <TableRow className={tableClasses.tableRow}>
                <TableCell className={tableClasses.tableRowCell}>
                  <Typography>{t('components.userSettingsGrid.privacySettingsOption')}</Typography>
                </TableCell>
                <TableCell className={tableClasses.tableRowCell} align={'right'}>
                  <ArrowForwardIos color="secondary" className={classes.iconAlign} />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box
        bgcolor="background.paper"
        color="text.primary"
        borderRadius={2}
        p={3}
        className={classes.boxRight}
      >
        <TableContainer>
          <Table size="small">
            <TableBody>
              <TableRow className={tableClasses.tableRow}>
                <TableCell className={tableClasses.tableRowCell}>
                  <Typography variant="h3">
                    {t('components.userSettingsGrid.privacySettingsHeader')}
                  </Typography>
                  <Typography>
                    {t('components.userSettingsGrid.privacySettingsDescription', {joinArrays: ' '})}
                  </Typography>
                </TableCell>
                <TableCell className={tableClasses.tableRowCell} align={'right'}>
                  <Switch
                    checked={isAnalyticsEnabled}
                    onChange={handleChangeAnalyticSetting}
                    inputProps={{'aria-label': 'controlled'}}
                    data-test-id="settings-switch-analytics"
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Stack>
  )
}
