import {Box, Typography} from '@material-ui/core'
import {useTranslation} from 'react-i18next'

export const ErrorOccurredText = () => {
  const {t} = useTranslation()
  return (
    <Box flex={1} display="flex" color="text.secondary" alignItems="center" justifyContent="center">
      <Box display="flex" flexDirection="column" alignItems={'center'}>
        <Typography variant="body1"> {t('error.errorOccurredText')}</Typography>
      </Box>
    </Box>
  )
}
