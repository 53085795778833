import {Box} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import {useParams} from 'react-router'
import {useHistory} from 'react-router-dom'

import {getAppName} from '../common/app'
import {DeveloperVerificationShell} from '../container/DeveloperVerificationShell'
import {FilterAction, useApiProducts} from '../hooks/useApiProducts'
import {useAppByName} from '../hooks/useApps'
import {useTitle} from '../hooks/useTitle'
import {AppDetailCard} from '../layouts/AppDetailCard'

const useStyle = makeStyles((theme) => ({
  container: {
    backgroundColor: '#063b50'
  },
  gridList: {
    display: 'flex',
    flexWrap: 'wrap',
    width: 1008,
    gap: '10px'
  },
  paper: {
    height: 200,
    width: 194,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.grey[200]
  }
}))
export const AppDetailsPage = () => {
  useHistory()
  useStyle()
  const {appName} = useParams<{appName: string}>()
  const appQueryInfo = useAppByName(appName)
  const apiProductsQueryInfo = useApiProducts(FilterAction.Manageable)
  useTitle(appQueryInfo?.data ? getAppName(appQueryInfo?.data) : appName)

  return (
    <Box data-test-id="page-app-detail">
      <DeveloperVerificationShell
        areChildrenLoading={apiProductsQueryInfo.isLoading || appQueryInfo.isLoading}
      >
        {appQueryInfo?.data && (
          <AppDetailCard apiRecord={apiProductsQueryInfo?.data} appRecord={appQueryInfo.data} />
        )}
      </DeveloperVerificationShell>
    </Box>
  )
}
