import {Stack, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

export const GettingStartedLayout = (props) => {
  const {t} = useTranslation()

  return (
    <Stack direction={'column'} justifyContent={'flex-start'}>
      <Typography variant="h5">{t('signUp.gettingStartedText')}</Typography>
      <Stack alignItems={'center'} justifyContent={'center'} mt={10} mb={15}>
        {props.children}
      </Stack>
    </Stack>
  )
}
