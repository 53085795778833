import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'

import {hasDatalinkDeveloperOrAdminRole} from '../common/roles'
import {LoadingSpinner} from '../components/commons/LoadingSpinner'
import {useDatalinkRoles} from '../hooks/useRoles'
import {useTitle} from '../hooks/useTitle'
import {ChangelogLayout} from '../layouts/ChangelogLayout'

import {PageNotFound} from './PageNotFound'

export const ChangeLogPage = () => {
  const {t} = useTranslation()
  useHistory()
  useTitle(t('changelog.title'))

  const {isFetching: isRolesQueryFetching, roles} = useDatalinkRoles()

  if (isRolesQueryFetching) return <LoadingSpinner />

  if (!isRolesQueryFetching && !hasDatalinkDeveloperOrAdminRole(roles)) return <PageNotFound />

  return <ChangelogLayout />
}
