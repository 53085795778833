import {makeStyles} from 'tss-react/mui'

export const useStyles = makeStyles()(() => ({
  container: {
    marginBottom: '1.1rem'
  },
  title: {
    marginBottom: '0.4rem',
    fontSize: '1.4rem !important',
    fontWeight: 'bold'
  },
  subTitle: {
    marginBottom: '0.8rem',
    fontSize: '0.9rem',
    fontWeight: 'normal',
    color: '#363636'
  },
  tags: {
    marginBottom: '0.2rem'
  },
  tag: {
    lineHeight: '32px',
    marginBottom: '0.3rem',
    background: '#016AD40A',
    borderColor: '#016AD414'
  }
}))
