import {ApigeeAppCredential, ApigeeAppCredentialStatus} from '../declarations'

import {getNonRevokedCredentials} from './credentials'

/**
 * Filter out all enabled products in the provided credentials
 * @param credentials application credentials where the lookup will be performed
 * @returns array of active products
 */
export function getAllEnabledProducts(credentials?: ApigeeAppCredential[]): string[] {
  const result = getNonRevokedCredentials(credentials)
    .map((credentials) => credentials.apiProducts ?? [])
    .reduce((accumulator, current) => accumulator.concat(current), [])
    .filter((product) => product.status !== (ApigeeAppCredentialStatus.Revoked as string))
    .map((product) => product.apiProduct)

  return [...new Set(result)]
}

/**
 * Check if the product is enabled in the provided credentials
 * @param credentials array of application credentials
 * @param productName product name
 * @returns TRUE, if product is active in at least one of the provided credentials
 */
export function isProductEnabled(
  credentials?: ApigeeAppCredential[],
  productName?: string
): boolean {
  return getAllEnabledProducts(getNonRevokedCredentials(credentials)).some(
    (product) => product === productName
  )
}
