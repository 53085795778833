import {dataTestId} from '@hconnect/uikit'
import {Checkbox, FormControlLabel, Typography} from '@mui/material'
import {useTranslation} from 'react-i18next'

import {useStyles} from './Survey.styles'
import {SurveyTextFieldCard} from './SurveyTextFieldCard'
import {SurveyProps} from './SurveyTypes'

const questions = [
  {
    translationKey: 'survey.purpose.automatedInvoiceReconciliation',
    key: 'panel-survey-automatedInvoiceReconciliation'
  },
  {
    translationKey: 'survey.purpose.onlineOrderIntake',
    key: 'panel-survey-onlineOrderIntake'
  },
  {
    translationKey: 'survey.purpose.realtimeOrderDeliveryMonitoring',
    key: 'panel-survey-realtimeOrderDeliveryMonitoring'
  },
  {
    translationKey: 'survey.purpose.archivingDeliveryOrInvoice',
    key: 'panel-survey-archivingDeliveryOrInvoice'
  }
]

export const SurveyQuestionnairesCard = (props: SurveyProps) => {
  const {t} = useTranslation()
  const {classes} = useStyles()
  const {removeSurveyAnswer, updateSurveyAnswer} = props

  const onAnsweredSurvey = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.checked) {
      removeSurveyAnswer(event.target.value)
    } else {
      updateSurveyAnswer({answer: event.target.value})
    }
  }

  return (
    <div {...dataTestId('div-survey-questions')}>
      {questions?.map((question) => (
        <FormControlLabel
          control={<Checkbox value={question.translationKey} onChange={onAnsweredSurvey} />}
          key={question.key}
          label={
            <Typography className={classes.surveyItemLabel}>
              {t(question.translationKey)}
            </Typography>
          }
          {...dataTestId(question.key)}
        />
      ))}

      <FormControlLabel
        control={
          <SurveyTextFieldCard
            removeSurveyAnswer={removeSurveyAnswer}
            updateSurveyAnswer={updateSurveyAnswer}
          />
        }
        label=""
      />
    </div>
  )
}
