import {Box, CardMedia, makeStyles, Typography} from '@material-ui/core'
import {Button, Link} from '@mui/material'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'

import {BlackboardIllustrationGraphic, HeidelbergMaterialsLogo} from '../../assets'
import {RestMaturityBadgeShell} from '../../container/RestMaturityBadgeShell'
import {useRestLevels} from '../../hooks/useOpenApi'
import {dataLinkRoutes} from '../../routes'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  infoColumn: {
    width: '50%',
    minWidth: '506px'
  },
  header: {
    textTransform: 'none',
    paddingBottom: theme.spacing(6),
    marginTop: theme.spacing(9)
  },
  info: {
    whiteSpace: 'pre-line',
    lineHeight: `${theme.typography.fontSize * 2}px`
  },
  restLevelBadge: {
    paddingTop: theme.spacing(6),
    paddingBottom: '22px',
    width: '314px',
    cursor: 'pointer'
  },
  datalinkImage: {
    paddingBottom: theme.spacing(9)
  },
  heidelbergMaterialsLogo: {
    width: '205px',
    height: '59px',
    bottom: 0,
    right: 0,
    position: 'absolute'
  },
  signUpButtonContainer: {
    marginTop: '37px'
  }
}))

export const Headline = () => {
  const {t} = useTranslation()
  const {
    container,
    infoColumn,
    header,
    restLevelBadge,
    info,
    heidelbergMaterialsLogo,
    datalinkImage,
    signUpButtonContainer
  } = useStyles()
  const restLevelQueryInfo = useRestLevels()
  const history = useHistory()

  const handleSignUpClick = () => {
    history.push(dataLinkRoutes.SignUpPage)
  }

  return (
    <>
      <Box className={container}>
        <Box className={infoColumn}>
          <Typography variant="h2" className={header} data-test-id="homepage-headline">
            {t('landingPage.headline')}
          </Typography>
          <Typography variant="body1" className={info}>
            {t('landingPage.info')}
          </Typography>
          <Typography variant="body1" className={info}>
            <Link
              sx={{cursor: 'pointer'}}
              onClick={handleSignUpClick}
              color="inherit"
              data-test-id="homepage-sign-up-link"
            >
              {t('landingPage.signuplink')}
            </Link>
            {' ' + t('landingPage.signup')}
          </Typography>
          {restLevelQueryInfo.data?.ecsRestLevel && (
            <>
              <RestMaturityBadgeShell
                level={restLevelQueryInfo.data.ecsRestLevel}
                className={restLevelBadge}
              />
              <Typography variant="body1" className={info}>
                {t('landingPage.restMaturityLevelDescription')}
              </Typography>
            </>
          )}
          <Box className={signUpButtonContainer}>
            <Button
              data-test-id="homepage-sign-up-btn"
              variant="contained"
              color="primary"
              autoCapitalize="false"
              onClick={handleSignUpClick}
              sx={{
                textTransform: 'none',
                backgroundColor: '#016AD4',
                fontSize: '16px',
                lineHeight: '22px',
                padding: '13px 16px'
              }}
            >
              {t('landingPage.signUpBtn')}
            </Button>
          </Box>
        </Box>
        <Box position="relative">
          <CardMedia
            component="img"
            src={BlackboardIllustrationGraphic}
            alt={t('landingPage.presentingDatalinkImageLabel')}
            className={datalinkImage}
          />
          <CardMedia
            component="img"
            src={HeidelbergMaterialsLogo}
            className={heidelbergMaterialsLogo}
            data-test-id="homepage-heidelberg-materials-logo"
          />
        </Box>
      </Box>
    </>
  )
}
