import {ApigeeAppCredential, ApigeeAppCredentialStatus} from '../declarations'

/**
 * Returns all non-revoked credentials in the array
 * @param credentials array of credentials to check
 * @returns all non-revoked credentials
 */
export function getNonRevokedCredentials(
  credentials?: ApigeeAppCredential[]
): ApigeeAppCredential[] {
  return (
    credentials?.filter((credential) => credential.status !== ApigeeAppCredentialStatus.Revoked) ??
    []
  )
}
