export const dataLinkRoutes = {
  LandingPage: '/',
  ApiProductOverviewPage: '/api-product-overview',
  ApiProductDetailPage: '/api-products/:apiId',
  AppOverviewPage: '/app-overview',
  AppDetailPage: '/apps/:appName',
  AppCreationPage: '/apps/new',
  ImprintPage: '/imprint',
  SettingsPage: '/settings',
  Glossary: '/glossary',
  GetStarted: '/get-started',
  FAQ: '/faq',
  DataProtectionPage: '/legal/dataprotection',
  TermsAndConditionsPage: '/legal/termsandconditions',
  Cookies: '/cookies',
  SignUpPage: '/sign-up',
  Changelogs: '/changelogs',
  ChangelogsTag: '/changelogs/:tag',
  PricingPage: '/pricing'
}

export interface DatalinkUrlPathParameters {
  apiId: string
  appName: string
}

export const getApiProductDetailPageUrl = (apiId: string) =>
  dataLinkRoutes.ApiProductDetailPage.replaceAll(':apiId', apiId)

export const getChangelogPageTagUrl = (tag: string) =>
  dataLinkRoutes.ChangelogsTag.replaceAll(':tag', tag)
