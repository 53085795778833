import {getPermissionsByUserId, Permission} from '@hconnect/apiclient'
import {useAuth} from '@hconnect/common/authentication/context'
import {AxiosInstance} from 'axios'
import {QueryFunction, useQuery} from 'react-query'

import {getDatalinkPermissions} from '../common/permission'

import {useDatalinkSecureApi} from './useDatalinkSecureApi'

type PermissionQueryKey = ['permission', {userId: string | undefined}]

const apiPermissionFetcher =
  (secureApi: AxiosInstance): QueryFunction<Permission[], PermissionQueryKey> =>
  async ({queryKey}) => {
    const [, {userId}] = queryKey
    return await getPermissionsByUserId(secureApi)(userId ?? '')
  }

const usePermissions = (userId: string | undefined) => {
  const secureApi = useDatalinkSecureApi()
  return useQuery(['permission', {userId}], apiPermissionFetcher(secureApi), {
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    enabled: !!userId
  })
}

export const useDatalinkPermissions = () => {
  const {decodedToken} = useAuth()
  const {data, isError, isFetching} = usePermissions(decodedToken?.user_id)

  return {
    isError: isError,
    isFetching: isFetching,
    permissions: getDatalinkPermissions(data as Permission[])
  }
}
