import {Box} from '@mui/material'
import React, {forwardRef, useEffect} from 'react'
import 'rapidoc'

import {useOpenApiSpec} from '../../hooks/useOpenApiSpec'

interface RapiDocProps
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  // General
  name: string
  'spec-url'?: string
  'update-route'?: boolean
  'route-prefix'?: string
  'sort-tags'?: boolean
  'sort-endpoints-by'?: 'path' | 'method' | 'summary' | 'none'
  'heading-text'?: string
  'goto-path'?: string
  'fill-request-fields-with-example'?: boolean
  'persist-auth'?: boolean
  // UI Colors and Fonts
  theme?: 'light' | 'dark'
  'bg-color'?: string
  'text-color'?: string
  'header-color'?: string
  'primary-color'?: string
  'load-fonts'?: boolean
  'regular-fonts'?: string
  'mono-fonts'?: string
  'font-size'?: 'default' | 'large' | 'largest'
  // Navigation
  'use-path-in-nav-bar'?: boolean
  'nav-bg-color'?: string
  'nav-text-color'?: string
  'nav-hover-bg-color'?: string
  'nav-hover-text-color'?: string
  'nav-accent-color'?: string
  'nav-item-spacing'?: 'default' | 'compact' | 'relaxed'
  // UI Layout & Placement
  layout?: 'row' | 'column'
  'render-style'?: 'read' | 'view' | 'focused'
  'on-nav-tag-click'?: 'expand-collapse' | 'show-description'
  'schema-style'?: 'tree' | 'table'
  'schema-expand-level'?: number
  'schema-description-expanded'?: boolean
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  'schema-hide-read-only'?: 'always' | 'never' | string
  'default-schema-tab'?: 'model' | 'example'
  'response-area-height'?: string
  'css-file': string
  // Hide/Show Sections
  'show-info'?: boolean
  'info-description-headings-in-navbar'?: boolean
  'show-components'?: boolean
  'show-header'?: boolean
  'allow-authentication'?: boolean
  'allow-spec-url-load'?: boolean
  'allow-spec-file-load'?: boolean
  'allow-spec-file-download'?: boolean
  'allow-search'?: boolean
  'allow-advanced-search'?: boolean
  'allow-try'?: boolean
  'allow-server-selection'?: boolean
  'allow-schema-description-expand-toggle'?: boolean
  // API Server & calls
  'server-url'?: string
  'default-api-server'?: string
  'api-key-name'?: string
  'api-key-location'?: 'header' | 'query'
  'api-key-value'?: string
  'fetch-credentials'?: 'omit' | 'same-origin' | 'include'
  'country-ids': string[]
  // Events
  specLoaded?: () => void
}

declare global {
  interface HTMLElementTagNameMap {
    'rapi-doc': HTMLDivElement
  }

  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'rapi-doc': RapiDocProps
    }
  }
}

export const ApiDocView = forwardRef<HTMLDivElement, RapiDocProps>(
  ({specLoaded, children, ...props}: RapiDocProps, ref) => {
    const localRef = React.useRef<HTMLDivElement>(null)

    useEffect(() => {
      const rapiDocRef = typeof ref === 'object' && ref?.current ? ref?.current : localRef.current

      const handleSpecLoaded = () => {
        specLoaded && specLoaded()
      }

      if (rapiDocRef) {
        specLoaded && rapiDocRef.addEventListener('spec-loaded', handleSpecLoaded)
      }

      return () => {
        if (rapiDocRef) {
          specLoaded && rapiDocRef.removeEventListener('spec-loaded', handleSpecLoaded)
        }
      }
    }, [ref, localRef, specLoaded])

    const name = props.name.toLowerCase()
    const countryIds = props['country-ids']
    useOpenApiSpec(name, countryIds)

    return (
      <Box p={4} bgcolor={'white'} borderRadius={6}>
        <rapi-doc {...props} ref={ref || localRef}>
          {children}
        </rapi-doc>
      </Box>
    )
  }
)
