import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from '@mui/material'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'

import {hasCurrentTermsAndConditionsAccepted} from '../common/legal'
import {TermsAndConditionsCard} from '../components/TermsAndConditionsCard'
import {useAuthentication} from '../hooks/useAuthentication'
import {useUserAcceptedTerms, useUserMutation} from '../hooks/useSettings'

const styles = {
  buttonBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end'
  },

  button: {
    margin: 1
  }
}

export const TermsAndConditionsVerificationDialog = () => {
  const {userAuthenticated, userEmail, startLogoutProcess} = useAuthentication()
  const {t} = useTranslation()

  const acceptedTerms = useUserAcceptedTerms()
  const {mutateAsync: acceptTerms} = useUserMutation()
  const [open, setOpen] = React.useState(false)

  const handleOnReject = () => {
    setOpen(false)
    void startLogoutProcess()
  }

  const handleOnAccept = async () => {
    const data = {
      termsAcceptedVersion: t('legal.termsAndConditionsVersion'),
      termsAcceptedOn: new Date().toISOString()
    }
    await acceptTerms([
      'users',
      {
        userEmail: userEmail,
        data
      }
    ])

    setOpen(false)
  }

  useEffect(() => {
    if (acceptedTerms.isSuccess) {
      const termsAndConditionsAccepted = hasCurrentTermsAndConditionsAccepted(
        acceptedTerms.data ?? [],
        t('legal.termsAndConditionsVersion')
      )

      setOpen(!termsAndConditionsAccepted)
    }
  }, [acceptedTerms, t])

  if (!userAuthenticated) return <></>

  return (
    <Dialog
      open={open}
      maxWidth="lg"
      aria-labelledby="layout-legal-terms-and-condition-dialog-title"
      aria-describedby="layout-legal-terms-and-condition-dialog-description"
      data-test-id="layout-legal-terms-and-condition-dialog"
    >
      <DialogTitle
        id="layout-legal-terms-and-condition-dialog-title"
        data-test-id="layout-legal-terms-and-condition-dialog"
      >
        <Alert severity="info" data-test-id="layout-legal-terms-and-condition-dialog-title-alert">
          <Typography>{t('legal.acceptTermsAndConditionsMessage')}</Typography>
        </Alert>
      </DialogTitle>
      <DialogContent data-test-id="layout-legal-terms-and-condition-dialog-content">
        <TermsAndConditionsCard />
      </DialogContent>
      <DialogActions>
        <Button
          sx={styles.button}
          variant="contained"
          onClick={handleOnReject}
          data-test-id="layout-legal-terms-and-condition-dialog-action-reject"
        >
          {t('legal.rejectButton')}
        </Button>
        <Button
          sx={styles.button}
          variant="contained"
          onClick={handleOnAccept}
          data-test-id="layout-legal-terms-and-condition-dialog-action-accept"
        >
          {t('legal.acceptButton')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
