import {Box, Paper, Stack, Typography} from '@mui/material'
import classNames from 'classnames'

import {Changelog} from '../../declarations'
import {useCommonBoxStyles} from '../../hooks/useCommonBoxStyles'

import {useStyles} from './Changelog.styles'
import {MarkdownViewer} from './MarkdownViewer'
import {Tag} from './Tag'

export const ChangeLogItem = ({changelog}: {changelog: Changelog}) => {
  const {classes} = useStyles()
  const boxClasses = useCommonBoxStyles()
  return (
    <Paper
      data-test-id="component-changelog-item"
      className={classNames(boxClasses.paper, classes.container)}
    >
      <Box className={boxClasses.box}>
        <Stack>
          <Typography variant={'h4'} className={classes.title}>
            {changelog.title}
          </Typography>

          <Stack direction="row" gap={3} className={classes.subTitle}>
            <Typography variant={'caption'}>
              <Box component="span" fontWeight="fontWeightBold">
                V{changelog.version}
              </Box>
            </Typography>
            <Typography variant={'caption'}>{changelog.date}</Typography>
          </Stack>

          <Stack direction="row" gap={1} className={classes.tags}>
            {changelog.tags.map((tag) => (
              <Tag tag={tag} key={tag.text} />
            ))}
          </Stack>

          <Box>
            <MarkdownViewer>{changelog.content}</MarkdownViewer>
          </Box>
        </Stack>
      </Box>
    </Paper>
  )
}
