import {Button, IconButton} from '@material-ui/core'
import ContentCopyIcon from '@material-ui/icons/FileCopy'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {useCommonCopyTextButtonStyle} from '../../hooks/useCommonButtonStyles'

interface Props {
  text?: string
  hoverTitle?: string
  onIconClick?: (text: string) => void | undefined
}

export const RevealableTextArea = ({text, hoverTitle, onIconClick}: Props) => {
  const [displayText, setDisplayText] = useState(false)
  const {t} = useTranslation()
  const classes = useCommonCopyTextButtonStyle()

  return (
    <span>
      {displayText ? (
        text
      ) : (
        <Button
          variant="text"
          onClick={() => setDisplayText(true)}
          data-test-id={`reveal-text-area-${text}`}
        >
          {t('components.revealableTextArea.revealButtonLabel')}
        </Button>
      )}

      <IconButton
        onClick={onIconClick ? () => onIconClick(text || '') : undefined}
        size="small"
        title={hoverTitle}
        aria-label={hoverTitle}
        className={classes.button}
        data-test-id={`reveal-text-area-copy-${text}`}
      >
        <ContentCopyIcon />
      </IconButton>
    </span>
  )
}
