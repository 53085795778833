import {Paper, TextField, Typography} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import React, {useCallback, useEffect, useState} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {getAppDescription, getAppName} from '../../common/app'
import {ApigeeApp} from '../../declarations'

export interface AppDetailOverviewProps {
  appRecord: ApigeeApp
  onSubmit: (app: ApigeeApp) => void
}

const useStyle = makeStyles((theme) => ({
  title: {
    marginTop: theme.spacing(1)
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '1em',
    marginTop: theme.spacing(2)
  },
  header: {
    marginBottom: theme.spacing(1)
  },
  description: {
    marginTop: theme.spacing(3)
  }
}))

export const AppDetailOverviewCard = ({appRecord, onSubmit}: AppDetailOverviewProps) => {
  const classes = useStyle()
  const {t} = useTranslation()
  const [elevation] = useState(1)

  const getAppDetailFormData = useCallback(
    (): ApigeeApp => ({
      ...appRecord,
      description: getAppDescription(appRecord.attributes)
    }),
    [appRecord]
  )

  const {reset, handleSubmit, control} = useForm<ApigeeApp>({
    defaultValues: getAppDetailFormData()
  })

  useEffect(() => {
    reset(getAppDetailFormData())
  }, [appRecord, getAppDetailFormData, reset])

  return (
    <>
      <Paper className={classes.paper} elevation={elevation}>
        <div className={classes.title}>
          <Typography
            variant="h3"
            data-test-id="app-detail-overview-card-title"
            className={classes.header}
          >
            {t('appOverview.appDetail.overview')}
          </Typography>
          <form id="app-detail-form" onSubmit={handleSubmit(onSubmit)}>
            <TextField
              value={getAppName(appRecord)}
              margin="dense"
              id="appName"
              label={t('appOverview.appDetail.appNameLabel')}
              type="text"
              fullWidth
              variant="standard"
              InputProps={{
                readOnly: true
              }}
            />

            <Controller
              name={'description'}
              control={control}
              render={({field: {onChange, value}}) => (
                <TextField
                  onChange={onChange}
                  value={value}
                  className={classes.description}
                  margin="dense"
                  id="appDescription"
                  label={t('appOverview.appDetail.appDescriptionLabel')}
                  type="text"
                  multiline
                  fullWidth
                  variant="standard"
                  inputProps={{maxLength: 100}}
                  InputLabelProps={{shrink: true}}
                />
              )}
            />
          </form>
        </div>
      </Paper>
    </>
  )
}
