import {trackEvent} from '@hconnect/common/logging/Analytics'
import axios, {AxiosError} from 'axios'
import {useContext} from 'react'
import {useTranslation} from 'react-i18next'

import {EventContext} from '../context/EventContext'

import {useDatalinkSnackbar} from './useDatalinkSnackbar'

/**
 * Notify on error with Snackbar
 * @returns void, display generic Snackbar error
 */
export const useErrorNotify = () => {
  const {t} = useTranslation()
  const {enqueueSnackbar} = useDatalinkSnackbar()
  const eventData = useContext(EventContext)

  const onNotify = (err) => {
    if (axios.isAxiosError(err)) {
      let errorMessage: string
      if (err?.response?.data) {
        const error = err as AxiosError<{detail?; title?}>
        errorMessage =
          (error.response?.data.detail ?? error.response?.data.title ?? error.message) +
          `- ${t('appOverview.appDetail.notifications.developerAppError')}`
      } else {
        errorMessage = `${err.message} - ${t(
          'appOverview.appDetail.notifications.developerAppError'
        )}`
      }
      enqueueSnackbar(errorMessage, 'error')

      trackEvent('dlAppError', {
        ...eventData,
        message: errorMessage
      })
    }
  }

  return {
    onNotify
  }
}
