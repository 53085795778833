import {useAuth} from '@hconnect/common/authentication/context'
import {useTranslation} from 'react-i18next'

import {canManageApplication, canViewApiProducts} from '../common/permission'
import {hasDatalinkDeveloperOrAdminRole} from '../common/roles'
import {useDatalinkPermissions} from '../hooks/usePermission'
import {useDatalinkRoles} from '../hooks/useRoles'
import {dataLinkRoutes} from '../routes'

import {NavigationItem, NavigationItemProps} from './NavigationItem'

export const Navigator = () => {
  const {t} = useTranslation()
  const {authState, login} = useAuth()
  const {permissions} = useDatalinkPermissions()
  const {roles} = useDatalinkRoles()

  const userAuthenticated = authState === 'authenticated'

  const navItems: NavigationItemProps[] = [
    {
      page: dataLinkRoutes.ApiProductOverviewPage,
      text: t('navigation.products'),
      hidden: !userAuthenticated || !canViewApiProducts(permissions),
      onClick: undefined
    },
    {
      page: dataLinkRoutes.AppOverviewPage,
      text: t('navigation.apps'),
      hidden: !userAuthenticated || !canManageApplication(permissions),
      onClick: undefined
    },
    {
      page: dataLinkRoutes.FAQ,
      text: t('navigation.faq'),
      hidden: false,
      onClick: undefined
    },
    {
      page: undefined,
      text: t('navigation.guides'),
      hidden: !userAuthenticated,
      onClick: undefined,
      subMenu: [
        {
          text: t('guide.glossary.subMenuTitle'),
          page: dataLinkRoutes.Glossary
        },
        {
          text: t('guide.getStarted.subMenuTitle'),
          page: dataLinkRoutes.GetStarted
        }
      ]
    },
    {
      page: dataLinkRoutes.Changelogs,
      text: t('navigation.changelog'),
      hidden: !hasDatalinkDeveloperOrAdminRole(roles),
      onClick: undefined
    },
    {
      page: dataLinkRoutes.GetStarted,
      text: t('guide.getStarted.subMenuTitle'),
      hidden: userAuthenticated,
      onClick: undefined
    },
    {
      page: 'login',
      text: t('navigation.login'),
      hidden: userAuthenticated,
      onClick: () => {
        void (async () => await login())()
      }
    },
    {
      page: dataLinkRoutes.PricingPage,
      text: t('navigation.pricing'),
      hidden: !hasDatalinkDeveloperOrAdminRole(roles),
      onClick: undefined
    }
  ]

  return (
    <>
      {navItems
        .filter((f) => !f.hidden)
        .map((n) => (
          <NavigationItem key={n.text} {...n} />
        ))}
    </>
  )
}
