import {Shell} from '@hconnect/uikit'
import React from 'react'
import {useHistory} from 'react-router-dom'

import {Navigator} from '../components/Navigator'
import {ShellFooter} from '../components/ShellFooter'
import {useAuthentication} from '../hooks/useAuthentication'
import {CookiesDialog} from '../layouts/CookiesDialog'
import {DataProtectionPolicyVerificationAlert} from '../layouts/DataProtectionPolicyVerificationAlert'
import {ShellHeader} from '../layouts/ShellHeader'
import {TermsAndConditionsVerificationDialog} from '../layouts/TermsAndConditionsVerificationDialog'
import {DatalinkLegalContextProvider} from '../providers/DatalinkLegalContextProvider'
import {dataLinkRoutes} from '../routes'

export const DefaultShell = ({children}: {children?: React.ReactNode}) => {
  const history = useHistory()
  const {userAuthenticated, startLogoutProcess, decodedToken} = useAuthentication()

  return (
    <DatalinkLegalContextProvider>
      <Shell
        compact={false}
        isResponsive
        boxed={true}
        header={
          <ShellHeader
            onClickHeaderLogo={() => {
              history.push(dataLinkRoutes.LandingPage)
            }}
            user={decodedToken}
            onLogout={() => {
              void startLogoutProcess()
            }}
          >
            <Navigator />
          </ShellHeader>
        }
        footer={<ShellFooter />}
      >
        <CookiesDialog />
        {userAuthenticated && (
          <>
            <DataProtectionPolicyVerificationAlert />
            <TermsAndConditionsVerificationDialog />
          </>
        )}
        {children}
      </Shell>
    </DatalinkLegalContextProvider>
  )
}
