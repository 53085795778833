import {InputTextField} from '@hconnect/uikit/src/lib2/components/inputs'
import React, {ChangeEventHandler} from 'react'
import {useTranslation} from 'react-i18next'

import {AccountFormComponentProps} from './types'

interface Props extends AccountFormComponentProps {
  value: string
  onChange: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
  [x: string]: any
  countrySelectValues: {label: string; value: string}[] | undefined
}

export const CountryField: React.FC<Props> = ({
  name,
  value,
  onChange,
  formMethods,
  countrySelectValues,
  ...rest
}) => {
  const {t} = useTranslation()

  return (
    <InputTextField
      id="countryField"
      name={name}
      value={value}
      onChange={onChange}
      error={!!formMethods.formState.errors?.[name]}
      helperText={formMethods.formState.errors?.[name]?.message}
      select
      label={t('signUp.requestAccountForm.labels.country')}
      selectValues={countrySelectValues}
      data-test-id="countryField-select-country"
      {...rest}
    />
  )
}
