import {Typography, makeStyles} from '@material-ui/core'
import {Box} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

const useStyle = makeStyles(() => ({
  header: {
    textTransform: 'none'
  }
}))

export const UserSettingsHeader = () => {
  const {t} = useTranslation()
  const classes = useStyle()
  return (
    <Box sx={{flexGrow: 1}} data-test-id="settings-header">
      <Typography variant="h2" className={classes.header}>
        {t('settingsPage.title')}
      </Typography>
    </Box>
  )
}
