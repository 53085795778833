import {useCallback} from 'react'

import {AnalyticEvent} from '../declarations'

import {useAuthentication} from './useAuthentication'
import {useDatalinkRoles} from './useRoles'

/**
 * Wraps common event tracking properties when calling analytic event callback.
 * @param setAnalyticEventCallback
 * @returns object
 */
export const useTracking = (setAnalyticEventCallback: (event: AnalyticEvent) => void) => {
  const {userId, countryCode} = useAuthentication()
  const {customerId, roleTypes, businessLine} = useDatalinkRoles()

  const trackEvent = useCallback(
    (event: AnalyticEvent) => {
      setAnalyticEventCallback({
        ...event,
        userId: userId,
        countryCode: countryCode,
        customerId: customerId,
        roles: roleTypes,
        businessLine: businessLine
      })
    },
    [setAnalyticEventCallback, userId, countryCode, customerId, roleTypes, businessLine]
  )

  return {
    trackEvent
  }
}
