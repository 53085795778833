import {Permission} from '@hconnect/apiclient'

export const getDatalinkPermissions = (permissions?: Permission[]) => {
  return permissions?.filter(
    (p) =>
      p.permissionType === 'VIEW_DATALINK_API_PRODUCT' ||
      p.permissionType === 'MANAGE_DATALINK_APPLICATIONS'
  )
}

export const canManageApplication = (permissions?: Permission[]) => {
  return permissions?.some((p) => p.permissionType === 'MANAGE_DATALINK_APPLICATIONS')
}

export const canViewApiProducts = (permissions?: Permission[]) => {
  return permissions?.some((p) => p.permissionType === 'VIEW_DATALINK_API_PRODUCT')
}

export const getManageableApiProducts = (permissions?: Permission[]): string[] => {
  return [
    ...new Set(
      permissions
        ?.filter((p) => p.permissionType === 'MANAGE_DATALINK_APPLICATIONS')
        .map((product) => JSON.parse(product.dataScope))
        .map((dataScope: {apiProduct: string; country: string}) =>
          dataScope.apiProduct.toLowerCase()
        )
    )
  ]
}
