import {Box, Grid, Paper, Typography, styled} from '@mui/material'
import {useTranslation} from 'react-i18next'

import {FAQMoreQuestionCard} from '../components/faq/FAQMoreQuestionCard'
import {useCommonBoxStyles} from '../hooks/useCommonBoxStyles'
import {useTitle} from '../hooks/useTitle'

const HeaderTypography = styled(Typography)({
  fontWeight: 600,
  fontSize: '28px',
  lineHeight: '34px',
  variant: 'caption'
})

const SubHeaderTypography = styled(Typography)({
  noWrap: true,
  variant: 'subtitle1',
  fontWeight: 600,
  fontSize: '18px',
  lineHeight: '24px'
})

const DescriptionTypography = styled(Typography)({
  noWrap: true,
  variant: 'body1',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '26px',
  paddingTop: '24px',
  paddingBottom: '36px'
})

export const GetStartedPage = () => {
  const {t} = useTranslation()
  const classes = useCommonBoxStyles()
  useTitle(t('guide.getStarted.title'))
  return (
    <>
      <Box sx={{pb: '21px'}} data-test-id="page-get-started">
        <HeaderTypography>{t('guide.getStarted.title')}</HeaderTypography>
      </Box>
      <Paper className={classes.paper}>
        <Grid container spacing={2} sx={{p: 3}}>
          <Grid item xs={8} style={{display: 'flex', justifyContent: 'flex-start'}}>
            <Box>
              <SubHeaderTypography>{t('guide.getStarted.subtitle')}</SubHeaderTypography>
              <video width="100%" height="500vh" controls data-test-id="get-started-video">
                <source src="/build/videos/get-started.mp4" type="video/mp4" />
                <track
                  src="/build/videos/get-started-en.vtt"
                  kind="captions"
                  label="english_captions"
                ></track>
              </video>
              <DescriptionTypography>
                {t('guide.getStarted.videoDescription')}
              </DescriptionTypography>
              <SubHeaderTypography>{t('guide.getStarted.videoContentTitle')}</SubHeaderTypography>
              <Typography>
                <div
                  className="content"
                  dangerouslySetInnerHTML={{__html: t('guide.getStarted.videoContent')}}
                />
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4} style={{justifyContent: 'flex-end'}}>
            <FAQMoreQuestionCard />
          </Grid>
        </Grid>
      </Paper>
    </>
  )
}
