import {Box, Divider, List, ListItem, ListItemText, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {Glossaries} from '../../assets'

export const GlossaryGuideCard = () => {
  const {t} = useTranslation()
  return (
    <Box data-test-id="layouts-glossary-card">
      <Typography variant="h4">{t('guide.glossary.title')}</Typography>
      <List disablePadding={true}>
        {Glossaries.sort((a, b) => (a.item > b.item ? 1 : -1)).map((glossary) => (
          <>
            <ListItem key={glossary.item} alignItems="flex-start">
              <ListItemText
                primary={glossary.item}
                secondary={
                  <React.Fragment>
                    <Typography
                      sx={{display: 'inline'}}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      {glossary.description}
                    </Typography>
                  </React.Fragment>
                }
              />
            </ListItem>
            <Divider />
          </>
        ))}
      </List>
    </Box>
  )
}
