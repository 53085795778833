import {useCallback, useEffect} from 'react'

import {OpenApi, OpenApiPath, SecuritySchemes} from '../declarations'

type RapiDoc = HTMLDivElement & {
  loadSpec: (jsonSpec: string) => void
}

const getOpenApiSpecUrl = (name: string) =>
  `${process.env.REACT_APP_APIGEE_ORIGIN}/openapi-specifications/${name}`

type CountryExclusiveEndpoints = {
  [countryCode: string]: string[]
}

const countryPaths: CountryExclusiveEndpoints = {
  AU: ['material-tests']
}

const convertRelativeUrlsToAbsolute = (openapiJson: OpenApi) => {
  const paths = Object.keys(openapiJson.paths)

  for (const path of paths) {
    openapiJson.paths[openapiJson.servers[0]?.url + path] = openapiJson.paths[path]
    delete openapiJson.paths[path]
  }
}

const filterApiEndpointsByCountry = (json: any, countryCodes: string[]) => {
  if (countryCodes.length === 0) {
    return json
  }

  const filteredEndpoints: any = {}
  const allCountrySpecificEndpoints = Object.values(countryPaths).flat()

  countryCodes.forEach((countryCode) => {
    const allowedEndpoints = countryPaths[countryCode] || []

    Object.keys(json.paths).forEach((endpoint) => {
      const isUnrestrictedEndpoint = !allCountrySpecificEndpoints.some((exclusiveEndpoint) =>
        endpoint.includes(exclusiveEndpoint)
      )

      const isEndpointAllowed = allowedEndpoints.some((allowedEndpoint) =>
        endpoint.includes(allowedEndpoint)
      )

      if (isUnrestrictedEndpoint || isEndpointAllowed) {
        filteredEndpoints[endpoint] = json.paths[endpoint]
      }
    })
  })

  const filteredJson = {...json, paths: filteredEndpoints}
  return filteredJson
}

const getCurlQueryParameter = (path: OpenApiPath) => {
  if (path?.parameters?.some((f) => f.in === 'query')) {
    const queryString = path.parameters
      .filter((f) => f.in === 'query')
      .map((param) => {
        return `${param.name}={${param.name}}`
      })

    return `?${queryString.join('&')}`
  }
  return ''
}

const getCurl = (apiSpecification: OpenApi, key: string, httpMethod: string): string => {
  const url = `${apiSpecification?.servers[0]?.url}${key}`
  const queries = getCurlQueryParameter(
    Object.values(apiSpecification?.paths[key])[0] as OpenApiPath
  )
  return `curl -X ${httpMethod.toLocaleUpperCase()} '${url}${queries}'\\\n`
}

const pushCurlXCodeSample = (path: OpenApiPath, curlSyntax: string) => {
  if (path['x-codeSamples']) {
    path['x-codeSamples'].find((f) => f.lang === 'cURL').source = curlSyntax
  } else {
    path['x-codeSamples'] = new Array({
      lang: 'cURL',
      label: 'CLI',
      source: curlSyntax
    })
  }
}

const getAuthorizationHeader = (path: OpenApiPath, securitySchemes: SecuritySchemes) => {
  if (path.security) {
    const security = Object.keys(path?.security[0])[0]
    return `-H 'Authorization: ${securitySchemes[security].scheme} {TOKEN}\\\n`
  }
}

const getCurlHeaderExample = (apiSpecification: OpenApi, key: string): string => {
  const path = Object.values(apiSpecification?.paths[key])[0] as OpenApiPath
  const header = "-H 'Accept: application/json'\\\n"
  const authenticationHeader = getAuthorizationHeader(
    path,
    apiSpecification.components.securitySchemes
  )
  if (path?.requestBody) {
    const contentType = Object.keys(path?.requestBody?.content)[0]
    return `${header} -H 'Content-Type: ${contentType}'\\\n`
  }
  return `${header} -H 'Content-Type: application/json'\\\n ${authenticationHeader}`
}

const getCurlData = () => {
  return '-d {Take from example}'
}

const getCurlForm = (path: OpenApiPath) => {
  if (path?.requestBody) {
    const keys = Object.keys(path.requestBody?.content)

    if (path?.requestBody?.content[keys[0]]?.schema?.required) {
      const values = path?.requestBody?.content[keys[0]]?.schema?.required?.map((item) => {
        return `${item}={${item}}`
      })
      return `-d '${values.join('&')}'`
    }
  }
  return ''
}

const generateCurlSample = (json: OpenApi) => {
  Object.keys(json.paths).forEach((key) => {
    const httpMethod = Object.keys(json?.paths[key])[0]?.toLocaleUpperCase()
    const path = Object.values(json?.paths[key])[0] as OpenApiPath
    const curlForm = getCurlForm(path)
    const curlDataExample = httpMethod !== 'GET' && curlForm === '' ? getCurlData() : curlForm
    const curlHeader = getCurlHeaderExample(json, key)
    const curlSyntax = `${getCurl(json, key, httpMethod)} ${curlHeader} ${curlDataExample}`

    pushCurlXCodeSample(path, curlSyntax)
  })
}

export const useOpenApiSpec = (name: string, countryIds: string[]) => {
  const callback = useCallback(async () => {
    const url = getOpenApiSpecUrl(name)
    const el = document.querySelector('rapi-doc') as RapiDoc

    const response = await fetch(url, {
      method: 'GET',
      credentials: 'same-origin'
    })

    const json = await response.json()
    const filteredjson = filterApiEndpointsByCountry(json, countryIds)
    generateCurlSample(filteredjson)
    convertRelativeUrlsToAbsolute(filteredjson)
    el?.loadSpec(filteredjson)
  }, [name, countryIds])

  useEffect(() => {
    callback()
  }, [callback, name])
}
