import {makeStyles} from '@material-ui/core'
import SettingsIcon from '@mui/icons-material/Settings'
import {Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'

import {dataLinkRoutes} from '../../routes'

const useStyles = makeStyles(() => ({
  settingsButton: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: '#F7F7F7',
      cursor: 'pointer'
    }
  }
}))

export const UserSettingsBar = () => {
  const history = useHistory()
  const classes = useStyles()
  const {t} = useTranslation()
  const handleNavigation = () => {
    history.push(dataLinkRoutes.SettingsPage)
  }

  return (
    <div
      className={classes.settingsButton}
      onClick={handleNavigation}
      data-test-id="user-settings-bar"
    >
      <SettingsIcon fontSize="small" />
      <Typography variant="body1">{t('components.settingsButtonLabel')}</Typography>
    </div>
  )
}
