import {Box} from '@mui/material'
import React from 'react'

import {FAQCard} from '../components/faq/FAQCard'
import {FAQDescriptionCard} from '../components/faq/FAQDescriptionCard'
import {FAQMoreQuestionCard} from '../components/faq/FAQMoreQuestionCard'

const styles = {
  leftZoneBox: {
    padding: '33px 27px 29px 41px'
  },
  rightZoneBox: {
    paddingTop: '19px',
    paddingRight: '25px'
  }
}

export const FAQLayout = () => {
  return (
    <>
      <Box data-test-id="layouts-faq-left-zone-content" sx={styles.leftZoneBox}>
        <FAQDescriptionCard />
        <FAQCard />
      </Box>

      <Box data-test-id="layouts-faq-right-zone-content" sx={styles.rightZoneBox}>
        <FAQMoreQuestionCard />
      </Box>
    </>
  )
}
