import {
  Box,
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import ContentCopyIcon from '@material-ui/icons/FileCopy'
import dayjs from 'dayjs'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {setDeveloperAppAnalyticEvent} from '../../common/eventTracker'
import {ApigeeAppCredential, ApigeeAppCredentialStatus} from '../../declarations'
import {
  useCommonButtonStyles,
  useCommonCopyTextButtonStyle
} from '../../hooks/useCommonButtonStyles'
import {useCommonTableStyles} from '../../hooks/useCommonTableStyles'
import {useTracking} from '../../hooks/useTracking'
import {RevealableTextArea} from '../commons/RevealableTextArea'

const useStyle = makeStyles((theme) => ({
  title: {
    marginTop: theme.spacing(1)
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '1em',
    marginTop: theme.spacing(2)
  },
  table: {
    marginTop: theme.spacing(2)
  }
}))

interface Props {
  keys: ApigeeAppCredential[]
  onRevokeAppKey: (key: string) => void
  onAddAppKey: () => void
  isAddAppKeyButtonVisible: boolean
}

// @ts-ignore: 2362
// prevent typescript complaining about subtract operation on strings
const sortByIssueDate = (a: ApigeeAppCredential, b: ApigeeAppCredential) => a.issuedAt - b.issuedAt

export const AppKeysCard = ({
  keys,
  onRevokeAppKey,
  onAddAppKey,
  isAddAppKeyButtonVisible
}: Props) => {
  const classes = useStyle()
  const {t} = useTranslation()
  const tableClasses = useCommonTableStyles()
  const copyTextButtonClasses = useCommonCopyTextButtonStyle()
  const buttonClasses = useCommonButtonStyles()
  const [elevation] = useState(1)
  const {trackEvent} = useTracking(setDeveloperAppAnalyticEvent)

  const copyText = (text: string) => {
    void navigator.clipboard.writeText(text)
  }

  const handleConsumerKeyCopyText = (text: string) => {
    copyText(text)
    trackEvent({
      operationName: 'dlAppKeyCopy'
    })
  }

  const handleConsumerSecretCopyText = (text: string) => {
    copyText(text)
    trackEvent({
      operationName: 'dlAppSecretCopy'
    })
  }

  return (
    <>
      <Paper className={classes.paper} elevation={elevation} data-test-id="app-keys-card">
        <div className={classes.title}>
          <Typography variant="h3" data-test-id="app-keys-card-title">
            {t('appOverview.appDetail.appKeys.title')}
          </Typography>

          <TableContainer className={classes.table}>
            <Table size="small">
              <TableHead className={tableClasses.tableHeader}>
                <TableRow>
                  <TableCell className={tableClasses.tableHeaderCell}>
                    {t('appOverview.appDetail.appKeys.key')}
                  </TableCell>
                  <TableCell className={tableClasses.tableHeaderCell}>
                    {t('appOverview.appDetail.appKeys.secret')}
                  </TableCell>
                  <TableCell className={tableClasses.tableHeaderCell}>
                    {t('appOverview.appDetail.appKeys.status')}
                  </TableCell>
                  <TableCell className={tableClasses.tableHeaderCell}>
                    {t('appOverview.appDetail.appKeys.expires')}
                  </TableCell>
                  <TableCell className={tableClasses.tableHeaderCell}>
                    {t('appOverview.appDetail.appKeys.action')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {keys.sort(sortByIssueDate).map((credential) => (
                  <TableRow key={credential.consumerKey} className={tableClasses.tableRow}>
                    <TableCell
                      className={tableClasses.tableRowCell}
                      data-test-id={`app-keys-table-cell-consumer-key-${credential.consumerKey}`}
                    >
                      {credential.consumerKey}
                      <IconButton
                        onClick={() => handleConsumerKeyCopyText(credential.consumerKey)}
                        aria-label={t('appOverview.appDetail.appKeys.copyConsumerKey')}
                        size="small"
                        className={copyTextButtonClasses.button}
                        data-test-id={`app-keys-key-copy-${credential.consumerKey}`}
                      >
                        <ContentCopyIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell
                      className={tableClasses.tableRowCell}
                      data-test-id={`app-keys-table-cell-consumer-secret-${credential.consumerSecret}`}
                    >
                      <RevealableTextArea
                        text={credential.consumerSecret}
                        hoverTitle={t('appOverview.appDetail.appKeys.copyConsumerSecret')}
                        onIconClick={handleConsumerSecretCopyText}
                      />
                    </TableCell>
                    <TableCell
                      className={tableClasses.tableRowCell}
                      data-test-id={`app-keys-table-cell-status-${credential.status}`}
                    >
                      {credential.status}
                    </TableCell>
                    <TableCell
                      className={tableClasses.tableRowCell}
                      data-test-id={`app-keys-table-cell-expires-${credential.expiresAt}`}
                    >
                      {credential.expiresAt !== '-1'
                        ? dayjs(+credential.expiresAt).format('YYYY-MM-DD')
                        : t('appOverview.appDetail.appKeys.never')}
                    </TableCell>
                    <TableCell
                      className={tableClasses.tableRowCell}
                      data-test-id={`app-keys-table-cell-action-${credential.consumerKey}`}
                    >
                      {credential.status === ApigeeAppCredentialStatus.Approved && (
                        <Button
                          variant="text"
                          startIcon={<CloseIcon />}
                          onClick={() => onRevokeAppKey(credential.consumerKey)}
                          data-test-id={`app-keys-btn-revoke-${credential.consumerKey}`}
                        >
                          {t('appOverview.appDetail.appKeys.revoke')}
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box>
            {isAddAppKeyButtonVisible && (
              <Button
                variant="outlined"
                className={buttonClasses.bodyButton}
                fullWidth={false}
                onClick={onAddAppKey}
                data-test-id="app-keys-btn-add"
              >
                {t('appOverview.appDetail.appKeys.addKeyButtonLabel')}
              </Button>
            )}
          </Box>
        </div>
      </Paper>
    </>
  )
}
