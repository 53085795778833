import {Theme} from '@mui/material'
import {makeStyles} from 'tss-react/mui'

export const useStyles = makeStyles()((theme: Theme) => ({
  headerBox: {
    paddingBottom: '21px'
  },
  headerTitle: {
    fontWeight: 600,
    fontSize: '28px',
    marginBottom: '32px',
    lineHeight: '34px'
  },
  headerDescription: {
    maxWidth: '600px'
  },
  contentBox: {
    marginTop: '30px'
  },
  paper: {
    padding: '24px',
    borderRadius: '8px',
    '&.recommended': {
      borderColor: theme.palette.primary.main,
      borderWidth: '1px',
      borderStyle: 'solid'
    }
  },
  title: {
    marginBottom: '16px'
  },
  description: {
    color: '#52667A',
    marginBottom: '20px',
    marginTop: '3px'
  },
  quota: {
    color: '#29333D',
    fontSize: '22px',
    paddingBottom: '20px',
    marginBottom: '24px',
    borderBottom: '1px solid #E8ECF0',
    fontWeight: 300
  },
  price: {
    color: '#29333D',
    fontSize: '36px',
    lineHeight: '34px',
    fontWeight: 300
  },
  priceDescription: {
    color: '#52667A',
    fontSize: '12px'
  },
  options: {
    height: '270px'
  },
  list: {
    padding: 0,
    '& li': {
      padding: 0,

      '& div:first-child': {
        minWidth: '24px'
      }
    }
  },
  recommended: {
    borderRadius: '4px'
  }
}))
