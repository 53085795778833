import {ApigeeApp, KeyValue} from '../declarations'

/**
 * Returns app description from app attributes
 * @param attributes keyValue | undefined
 * @returns string
 */
export function getAppDescription(attributes: KeyValue[] | undefined): string {
  return attributes?.find((item) => item.name === 'DisplayName')?.value || ''
}

/**
 * Returns app name from custom attribute
 * @param developerApp ApigeeApp
 * @returns string app name
 */
export function getAppName(developerApp: ApigeeApp): string {
  const appName =
    developerApp?.attributes?.find((item) => item.name === 'AppName')?.value || undefined

  return appName ?? developerApp.name
}

/**
 * Returns company id from custom attribute
 * @param developerApp ApigeeApp
 * @returns string company id
 */
export function getCompanyId(developerApp: ApigeeApp): string {
  const companyId =
    developerApp?.attributes?.find((item) => item.name === 'CompanyId')?.value || undefined

  return companyId ?? ''
}
