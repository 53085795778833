import {Box, Button, CardMedia, makeStyles, Typography} from '@material-ui/core'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {useCommonButtonStyles} from '../../hooks/useCommonButtonStyles'
import {useDatalinkRoles} from '../../hooks/useRoles'
import {CreateAppConfirmationDialog} from '../../layouts/CreateAppConfirmationDialog'

import {AppOverviewSubHeader} from './AppOverviewSubHeader'

import NoAppsGraphic from '@hconnect/datalink/src/assets/noAppsGraphic.png'

const useStyles = makeStyles(() => ({
  imageContainer: {
    maxWidth: 360,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  messageContainer: {
    marginTop: 32,
    marginBottom: 32
  }
}))

export const EmptyAppInfo = () => {
  const {t} = useTranslation()
  const [openCreateAppModal, setOpenCreateAppModal] = useState<boolean>(false)
  const [disabledCreateAppButton, setDisabledCreateAppButton] = useState<boolean>(false)
  const classes = useStyles()
  const commonButtonClasses = useCommonButtonStyles()
  const {isDatalinkDeveloper, isCompanyAssigned, isFetching} = useDatalinkRoles()

  const handleOpen = () => {
    setOpenCreateAppModal(true)
  }

  const handleClose = () => {
    setOpenCreateAppModal(false)
  }

  useEffect(() => {
    if (!isFetching && isDatalinkDeveloper) {
      setDisabledCreateAppButton(!isCompanyAssigned)
    }
  }, [isCompanyAssigned, isFetching, isDatalinkDeveloper])

  return (
    <>
      <AppOverviewSubHeader hideCreateAppButton={true} />
      <Box className={classes.imageContainer}>
        <CardMedia component="img" src={NoAppsGraphic} alt={t('emptyAppInfo.noAppsImageLabel')} />
        <Box className={classes.messageContainer}>
          <Typography align="center" color="textSecondary" variant="h3">
            {t('appOverview.defaultMessage')}
          </Typography>
        </Box>

        <Typography align="center">
          <Button
            className={commonButtonClasses.button}
            variant="outlined"
            data-test-id="button-add-app-secondary"
            aria-haspopup="true"
            aria-controls="create-app-confirmation-dialog"
            startIcon={<ArrowForwardIcon />}
            onClick={handleOpen}
            disabled={disabledCreateAppButton}
          >
            {t('appOverview.addAppButtonText')}
          </Button>
        </Typography>

        <CreateAppConfirmationDialog open={openCreateAppModal} onClose={handleClose} />
      </Box>
    </>
  )
}
