import {Permission} from '@hconnect/apiclient/src'

export interface ApigeeApiProducts {
  apiProduct: ApigeeApiProduct[]
}

export interface ApigeeApiProduct {
  name: string
  displayName: string
  description: string
  attributes: KeyValue[]
}

export type ApigeeApps = {
  app: ApigeeApp[]
}

export interface ApigeeApp {
  appId: string
  description?: string
  attributes?: KeyValue[]
  name: string
  status?: string
  createdAt: number
  credentials?: ApigeeAppCredential[]
  apiProducts?: string[] | undefined
}

export interface ApigeeAppCredential {
  consumerKey: string
  consumerSecret: string
  issuedAt: string
  expiresAt: string
  scopes?: string[]
  status?: string
  apiProducts?: ApigeeApiProductName[]
}

export interface KeyValue {
  name: string
  value: string
}

export interface ApigeeApiProductName {
  apiProduct: string
  status: string
}

export type AppFormInputs = {
  description?: string
  name: string
  country: string
  companyId: string
  apiProducts: string[]
}
export type AppModel = {
  payload: AppFormInputs
}

export enum ApigeeAppCredentialStatus {
  Revoked = 'revoked',
  Approved = 'approved'
}

export enum ApigeeDeveloperStatus {
  Active = 'active',
  InActive = 'inactive'
}

export interface ApigeeDeveloper {
  email: string
  firstName: string
  lastName: string
  userName: string
  developerId: string
  organizationName: string
  status: string
  createdAt: string
  lastModifiedAt: string
}

export interface Role {
  id: number
  userId: string
  dataScope: DataScope
  roleType: string
  expiryDate: string
}

export interface DataScope {
  countryId: string
  businessLine?: string
  orgUnitId?: string
  customerIds?: string[]
}

export interface UserSettings {
  isAnalyticsEnabled: boolean
}

export interface UserAcceptedTerms {
  termsAcceptedOn: string
  termsAcceptedVersion: string
}

export interface OpenApi {
  paths: OpenApiPath
  openapi: string
  info: object
  tags: object[]
  servers: Server[]
  components: Components
}

export interface Server {
  url: string
}

export interface Components {
  schemas: object
  securitySchemes: SecuritySchemes
}

export interface SecuritySchemes {
  Authorization: Authorization
}

export interface Authorization {
  type: string
  description: string
  scheme: string
  bearerFormat: string
}

export interface OpenApiPath {
  tags: string[]
  summary: string
  description: string
  operationId: string
  parameters: Parameter[]
  security: string[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  requestBody: any
  responses: object
}

export interface Parameter {
  name: string
  in: string
  required: boolean
  description: string
  schema: object
}

export interface AnalyticEvent {
  name?: AnalyticEventName
  countryCode?: string
  roles?: string[]
  customerId?: string
  businessLine?: string
  userId?: string
  apiProduct?: string
  operationName?: AnalyticsOperationName
  responseCode?: string
}

export interface RestLevel {
  ecsRestLevel: number
}

export interface EventData {
  userId?: string
  country?: string
  route?: string
  permissions?: Permission[]
}

export interface UserAcknowledgedDataPrivacy {
  dataPrivacyAcknowledgedOn: string
  dataPrivacyAcknowledgedVersion: string
}

export interface DatalinkBusinessLine {
  businessLineCode: string
  businessLineName: string
  isSupported: boolean
}

export interface ChangelogTag {
  text: string
  important: boolean | undefined
}
export interface Changelog {
  title: string
  date: string
  version: string
  tags: ChangelogTag[]
  content: string
}
export interface Subscription {
  name: string
  description: string | undefined
  price: string
  priceDescription: string
  options: SubscriptionOption[]
  quota: string
  recommended: boolean | undefined
}
export interface SubscriptionOption {
  text: string
  bold: boolean | undefined 
}
export type AnalyticEventName =
  | 'datalinkAppError'
  | 'dlSignIn'
  | 'dlSettings'
  | 'dlProductEvent'
  | 'dlAppEvent'
  | 'dlPageView'
  | 'dlSubmitRequest'

export type AnalyticsOperationName =
  | 'UsageAnalyticsOn'
  | 'UsageAnalyticsOff'
  | 'dlProductClick'
  | 'dlDocumentationExpandAll'
  | 'dlDocumentationCollapseAll'
  | 'dlCodeSampleCopy'
  | 'dlDocumentationResponseClick'
  | 'dlResponseExampleClick'
  | 'dlResponseExampleCopy'
  | 'dlResponseSchemaClick'
  | 'dlResponseSchemaMultilineExpand'
  | 'dlRequestExampleClick'
  | 'dlRequestSchemaClick'
  | 'dlRequestSchemaMultilineExpand'
  | 'dlAppCreate'
  | 'dlAppSave'
  | 'dlAppDelete'
  | 'dlAppKeyCopy'
  | 'dlAppSecretCopy'
  | 'dlAppKeyRevoke'
  | 'dlAppKeyCreate'

export enum ShellVariant {
  Default = 'default',
  SignUp = 'signUp'
}
