import {Grid} from '@mui/material'

import {Subscriptions} from '../assets'
import {PricingItem} from '../components/pricing/PricingItem'

export const PricingLayout = () => {
  return (
    <Grid
      data-test-id="component-pricing-layout"
      container
      spacing={2}
      alignItems="center"
      justifyContent="center"
    >
      {Subscriptions.map((subscription, index) => (
        <Grid item xs={12} sm={6} md={4} key={index} data-test-id="pricing-grid-item">
          <PricingItem subscription={subscription} />
        </Grid>
      ))}
    </Grid>
  )
}
