import {Backdrop, BackdropProps, CircularProgress, createStyles, Theme} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import React from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1
    }
  })
)
type DefaultLoadingComponentProps = {isLoading: boolean} & Omit<BackdropProps, 'className' | 'open'>
export const DefaultLoadingComponent: React.FC<DefaultLoadingComponentProps> = ({
  isLoading,
  ...backdropProps
}) => {
  const classes = useStyles()
  return (
    <Backdrop className={classes.backdrop} open={isLoading} {...backdropProps}>
      <CircularProgress />
    </Backdrop>
  )
}
