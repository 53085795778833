import {makeStyles} from '@material-ui/core'
import classNames from 'classnames'
import {get} from 'lodash'
import React, {useEffect, useRef, useState} from 'react'
import {animateScroll} from 'react-scroll/modules'

import {ShellProvider} from '../../common'
import {useYScroll} from '../../common/hooks/useScroll'
import ScrollTopIcon from '../Molecules/Icons/ScrollTopIcon'

import Content from './Content'
import Footer from './Footer'
import Header from './Header'

const palette = {
  scrollToTopButton: {
    mainText: '#29AAFF',
    mainBorder: '#29AAFFB2',
    hoverText: '#29AAFF',
    hoverBorder: '#29AAFF',
    activeText: '#F9AAFF',
    activeBorder: '#29AAFF7F'
  }
}

const useStyles = makeStyles((theme) => ({
  minWidth: {
    minWidth: '1280px'
  },

  '@global': {
    body: {
      overflowY: 'scroll'
    }
  },

  shell: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',

    '& $scrollTop': {
      color: palette.scrollToTopButton.mainText,
      borderColor: palette.scrollToTopButton.mainBorder,
      borderWidth: 3,
      boxShadow: theme.shadows[4],
      padding: theme.spacing(0.6),
      position: 'fixed',
      right: theme.spacing(1),
      bottom: theme.spacing(2),
      visibility: 'hidden',
      opacity: 0,
      transition: 'visibility .3s, opacity .3s',
      '&:active': {
        color: palette.scrollToTopButton.activeText,
        backgroundColor: 'transparent',
        borderColor: palette.scrollToTopButton.activeBorder,
        boxShadow: theme.shadows[4]
      },
      '&:hover': {
        color: palette.scrollToTopButton.hoverText,
        backgroundColor: 'transparent',
        borderColor: palette.scrollToTopButton.hoverBorder,
        boxShadow: theme.shadows[4]
      }
    },

    '& $scrollTopVisible': {
      visibility: 'visible',
      opacity: 1
    },
    '& $scrollTopFooter': {
      bottom: 0,
      marginBottom: theme.spacing(2)
    }
  },

  scrollTop: {
    '@media print': {
      display: 'none'
    }
  },

  scrollTopVisible: {},
  scrollTopFooter: {}
}))

interface ShellProps {
  className?: string
  compact?: boolean
  boxed?: boolean
  showScrollToTop?: boolean
  isResponsive?: boolean
  header?: React.ReactNode
  footer?: React.ReactNode
  scrolled?: boolean
  children?: React.ReactNode
}

const Shell: React.FC<ShellProps> = ({
  className: classNameProp,
  children,
  header,
  footer,
  compact,
  boxed,
  showScrollToTop = true,
  isResponsive
}) => {
  const classes = useStyles()
  const [scrolled, setScrolled] = useState(false)
  const footerRef = useRef<HTMLDivElement>(null)

  const yScroll = useYScroll()
  useEffect(() => {
    setScrolled(yScroll > 1)
  }, [setScrolled, yScroll])

  const [isScrollTopVisible, setScrollTopVisible] = useState(false)
  const [isScrollTopAtFooter, setScrollTopAtFooter] = useState(false)
  useEffect(() => {
    if (showScrollToTop) {
      if (scrolled) {
        setScrollTopVisible(true)
        const footerHeight = get(footerRef, 'current.clientHeight', 0)

        const bottomY = yScroll + window.innerHeight
        const limitHeight = document.documentElement.scrollHeight - footerHeight
        const diff = limitHeight - bottomY
        if (diff < 0) {
          // @ts-expect-error FIXME
          setScrollTopAtFooter(diff * -1)
        } else {
          setScrollTopAtFooter(false)
        }
      } else {
        setScrollTopVisible(false)
      }
    }
  }, [scrolled, yScroll, footerRef, showScrollToTop])

  const handleScrollToTop = () => {
    animateScroll.scrollToTop()
  }

  return (
    <ShellProvider
      defaultBoxed={boxed ?? false}
      defaultCompact={compact ?? false}
      scrolled={scrolled}
    >
      <div
        className={classNames(classes.shell, classNameProp, {
          [classes.minWidth]: !isResponsive
        })}
      >
        <Header scrolled={scrolled}>{header}</Header>
        <Content>{children}</Content>
        <Footer ref={footerRef}>{footer}</Footer>
        {showScrollToTop && (
          <ScrollTopIcon
            onClick={handleScrollToTop}
            className={classNames(classes.scrollTop, {
              [classes.scrollTopVisible]: isScrollTopVisible,
              [classes.scrollTopFooter]: isScrollTopAtFooter
            })}
            style={{bottom: isScrollTopAtFooter}}
          />
        )}
      </div>
    </ShellProvider>
  )
}

Shell.defaultProps = {
  showScrollToTop: true,
  boxed: true,
  compact: false
}

Shell.displayName = 'Shell'

export default Shell
