import {dataTestId} from '@hconnect/uikit'
import {Container} from '@mui/material'
import {t} from 'i18next'

import {useStyles} from '../components/survey/Survey.styles'
import {SurveyQuestionnairesCard} from '../components/survey/SurveyQuestionnairesCard'
import {SurveySubmitButtonCard} from '../components/survey/SurveySubmitButtonCard'
import {SurveyTitleCard} from '../components/survey/SurveyTitleCard'
import {useSurveyAnswers} from '../components/survey/useSurveyAnswers'
import {useSurveyResults} from '../hooks/useUserSelfRegistrations'

export const SurveyLayout = (props: {userId: string; afterSurveySubmitted: () => void}) => {
  const {classes} = useStyles()
  const {answers, removeSurveyAnswer, updateSurveyAnswer} = useSurveyAnswers()
  const submitSurveyMutation = useSurveyResults()
  const disabledButton = answers.length === 0
  const onSubmitResult = () => {
    const results = answers
      .map((item) => `<li>${t(item.answer)} ${item.reason ?? ''}</li>`)
      .join('')
    void submitSurveyMutation
      .mutateAsync([
        'datalink/survey-results',
        {userId: props.userId, surveyAnswers: `<ul>${results}</ul>`}
      ])
      .finally(() => {
        props.afterSurveySubmitted()
      })
  }
  return (
    <Container className={classes.surveyCard} {...dataTestId('container-survey')}>
      <SurveyTitleCard />
      <SurveyQuestionnairesCard
        removeSurveyAnswer={removeSurveyAnswer}
        updateSurveyAnswer={updateSurveyAnswer}
      />
      <SurveySubmitButtonCard disabledButton={disabledButton} onSubmit={onSubmitResult} />
    </Container>
  )
}
