import {
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {isProductEnabled} from '../../common/products'
import {ApigeeApiProduct, ApigeeApiProducts, ApigeeAppCredential} from '../../declarations'
import {useCommonTableStyles} from '../../hooks/useCommonTableStyles'

import {EmptyApiProductInfo} from './EmptyApiProductInfo'

const useStyle = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '1em',
    marginTop: theme.spacing(2)
  },
  tableContainer: {
    marginTop: theme.spacing(2)
  },
  title: {
    marginTop: theme.spacing(1)
  }
}))

interface Props {
  apis: ApigeeApiProducts | undefined
  appCredentials: ApigeeAppCredential[] | undefined
  toggleProduct: (productName: string, enabled: boolean) => void
}

const ApiProductsListTable = ({apis, appCredentials, toggleProduct}: Props) => {
  const classes = useStyle()
  const tableClasses = useCommonTableStyles()
  const {t} = useTranslation()

  return (
    <TableContainer className={classes.tableContainer}>
      <Table size="small">
        <TableHead className={tableClasses.tableHeader}>
          <TableRow>
            <TableCell className={tableClasses.tableHeaderCell}>
              {t('appOverview.appDetail.apiProduct.nameHeaderLabel')}
            </TableCell>
            <TableCell className={tableClasses.tableHeaderCell}>
              {t('appOverview.appDetail.apiProduct.productEnabledLabel')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {apis?.apiProduct?.map((product: ApigeeApiProduct) => (
            <TableRow key={product.name} className={tableClasses.tableRow}>
              <TableCell
                className={tableClasses.tableRowCell}
                data-test-id={`api-product-table-cell-product-name-${product.name}`}
              >
                {product.displayName}
              </TableCell>
              <TableCell
                className={tableClasses.tableRowCell}
                data-test-id={`api-product-table-cell-product-switch-button-${product.name}`}
              >
                <Switch
                  size="small"
                  aria-label={t('appOverview.appDetail.apiProduct.toggleProduct')}
                  onChange={(e) => toggleProduct(product.name, e.target.checked)}
                  checked={isProductEnabled(appCredentials, product.name)}
                  data-test-id={`api-product-switch-button-${product.name}`}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export const ApiProductList = (props: Props) => {
  const classes = useStyle()
  const {t} = useTranslation()

  const [elevation] = useState(1)

  return (
    <>
      <Paper className={classes.paper} elevation={elevation} data-test-id="api-products-list">
        <div className={classes.title}>
          <Typography variant="h3" data-test-id="api-product-list-title">
            {t('appOverview.appDetail.apiProduct.title')}
          </Typography>
          {props.apis?.apiProduct ? (
            <ApiProductsListTable {...props} />
          ) : (
            <EmptyApiProductInfo variant="text" />
          )}
        </div>
      </Paper>
    </>
  )
}
