import {AxiosInstance} from 'axios'
import {QueryFunction, useQuery} from 'react-query'

import {ApigeeApiProducts} from '../declarations'

import {useDatalinkSecureApi} from './useDatalinkSecureApi'

export enum FilterAction {
  Manageable = 'manageable',
  Viewable = 'viewable'
}

type UseApiProductQueryKey = ['datalink', 'apiproducts', {filter: FilterAction}]

const apiProductsFetcher =
  (secureApi: AxiosInstance): QueryFunction<ApigeeApiProducts, UseApiProductQueryKey> =>
  async ({queryKey}) => {
    const [, , {filter}] = queryKey
    const response = await secureApi.get<ApigeeApiProducts>(
      `/datalink/developerportal/${process.env.REACT_APP_ORGANIZATION_NAME}/apiproducts?filter=${filter}`
    )
    return response.data
  }

export const useApiProducts = (filter: FilterAction) => {
  const secureApi = useDatalinkSecureApi()

  return useQuery(['datalink', 'apiproducts', {filter}], apiProductsFetcher(secureApi))
}
