import {getOssAttributionLink, HProduceAccountDropdown} from '@hconnect/uikit/src/lib2'
import {Box, makeStyles} from '@material-ui/core'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {DataLinkLogo} from '../components/commons/DataLinkLogo'
import {dataLinkRoutes} from '../routes'

export interface ShellHeaderProps {
  onClickHeaderLogo: () => void
  onLogin: () => void
  children?: React.ReactNode
}

const useStyles = makeStyles(() => ({
  shellContainer: {
    width: '100%',
    height: '40px'
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },
  logoWrapper: {
    cursor: 'pointer',
    height: 24
  }
}))

export const SignUpShellHeader: React.FC<ShellHeaderProps> = ({
  onClickHeaderLogo,
  onLogin,
  children
}) => {
  const {t} = useTranslation()
  const classes = useStyles()

  return (
    <Box className={classes.shellContainer}>
      <Box className={classes.headerContainer}>
        <div
          onClick={onClickHeaderLogo}
          className={classes.logoWrapper}
          data-test-id="shell-header-logo"
        >
          <DataLinkLogo height={40} width={114} />
        </div>
        {children}
        <HProduceAccountDropdown
          imprintText={t('footer.imprint')}
          osSoftwareAttributionText={t('footer.oss')}
          dppText={t('footer.dataProtection')}
          tcText={t('footer.termsAndConditions')}
          signInText={t('navigation.login')}
          actions={{
            tc: () => {
              window.location.href = dataLinkRoutes.TermsAndConditionsPage
            },
            dpp: () => {
              window.location.href = dataLinkRoutes.DataProtectionPage
            },
            imprint: () => {
              window.location.href = dataLinkRoutes.ImprintPage
            },
            osSoftwareAttribution: () => {
              window.location.href = getOssAttributionLink()
            },
            signIn: onLogin
          }}
        />
      </Box>
    </Box>
  )
}
