import {useQueryClient} from 'react-query'

import {getNonRevokedCredentials} from '../common/credentials'
import {ApigeeApp, ApigeeAppCredential} from '../declarations'

import {
  UseAssignProductMutationKey,
  useAssignProductToAppKeysMutation,
  useToggleProductMutation,
  UseToogleProductMutationKey
} from './useAppKeys'

interface Props {
  app: ApigeeApp
  productName: string
  enabled: boolean
}

type AssignProductToAppKeyTypes = UseToogleProductMutationKey | UseAssignProductMutationKey

function shouldToggle(credential: ApigeeAppCredential, productName: string): boolean {
  return credential.apiProducts?.some((product) => product.apiProduct === productName) ?? false
}

function prepareParameters(
  props: Props,
  credential: ApigeeAppCredential
): AssignProductToAppKeyTypes {
  return [
    'datalink/appkeys',
    {
      productName: props.productName,
      enabled: props.enabled,
      credential,
      appName: props.app.name
    }
  ]
}

export const useProductAssignment = () => {
  const queryClient = useQueryClient()
  const {mutateAsync: toggleProductAsync} = useToggleProductMutation()
  const {mutateAsync: assignProductToKeysAsync} = useAssignProductToAppKeysMutation()

  return async function assignProductAsync(props: Props) {
    const {app, productName} = props
    for (const credential of getNonRevokedCredentials(app.credentials)) {
      if (shouldToggle(credential, productName)) {
        await toggleProductAsync(
          prepareParameters(props, credential) as UseToogleProductMutationKey
        )
      } else {
        await assignProductToKeysAsync(prepareParameters(props, credential))
      }
    }

    await queryClient.refetchQueries({
      queryKey: ['datalink', 'app', {appName: app.name}]
    })
  }
}
