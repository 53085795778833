import {useAuth} from '@hconnect/common/authentication/context'
import {Typography} from '@material-ui/core'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {useStyles} from './RequestAccount.styles'

export const LinkToSignIn = () => {
  const {t} = useTranslation()
  const {login} = useAuth()
  const {classes} = useStyles()

  return (
    <Typography>
      {t('signUp.requestAccountForm.linkToSignIn.text')}{' '}
      <span
        data-test-id="linkToSignIn-redirectLink"
        className={classes.linkToSignIn}
        onClick={() => void login()}
      >
        {t('signUp.requestAccountForm.linkToSignIn.linkText')}
      </span>
    </Typography>
  )
}
