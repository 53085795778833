import {Chip} from '@mui/material'
import {Link} from 'react-router-dom'

import {ChangelogTag} from '../../declarations'
import {getChangelogPageTagUrl} from '../../routes'

import {useStyles} from './Changelog.styles'

export const Tag = ({tag}: {tag: ChangelogTag}) => {
  const {classes} = useStyles()
  return (
    <Chip
      data-test-id="component-tag"
      label={tag.text}
      className={classes.tag}
      color={tag.important ? 'error' : 'primary'}
      variant="outlined"
      component={Link}
      to={getChangelogPageTagUrl(tag.text)}
      clickable
    />
  )
}
