import {Box, Grid, Typography} from '@mui/material'
import {useTranslation} from 'react-i18next'

export const UseCases = () => {
  const {t} = useTranslation()
  const styles = {
    topBox: {
      paddingTop: '22px',
      paddingBottom: '60px'
    },
    box: {
      paddingBottom: '60px'
    },
    header: {
      paddingBottom: '32px',
      fontWeight: 'bold'
    },
    subheader: {
      paddingBottom: '23px',
      fontWeight: 'bold'
    }
  }

  return (
    <>
      <Box sx={styles.topBox}>
        <Typography variant="h5" sx={styles.header} data-test-id="value-prop-use-cases">
          {t('landingPage.valueProp.useCases.title')}
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={4}>
            <Typography variant="h6" sx={styles.subheader}>
              {t('landingPage.valueProp.useCases.delivery')}
            </Typography>
            <Typography variant="body1" sx={{'white-space': 'pre-line'}}>
              {t('landingPage.valueProp.useCases.deliveryTracking')}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h6" sx={styles.subheader}>
              {t('landingPage.valueProp.useCases.invoices')}
            </Typography>
            <Typography variant="body1" sx={{'white-space': 'pre-line'}}>
              {t('landingPage.valueProp.useCases.invoiceReconciliation')}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h6" sx={styles.subheader}>
              {t('landingPage.valueProp.useCases.documents')}
            </Typography>
            <Typography variant="body1" sx={{'white-space': 'pre-line'}}>
              {t('landingPage.valueProp.useCases.digitalDocuments')}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box sx={styles.box}>
        <Typography variant="h5" sx={styles.header} data-test-id="value-prop-features">
          {t('landingPage.valueProp.features.title')}
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={4}>
            <Typography variant="h6" sx={styles.subheader}>
              {t('landingPage.valueProp.features.fastData')}
            </Typography>
            <Typography variant="body1">{t('landingPage.valueProp.features.pubSub')}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h6" sx={styles.subheader}>
              {t('landingPage.valueProp.features.oauth')}
            </Typography>
            <Typography variant="body1">{t('landingPage.valueProp.features.oauthAPIs')}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h6" sx={styles.subheader}>
              {t('landingPage.valueProp.features.apiReference')}
            </Typography>
            <Typography variant="body1">
              {t('landingPage.valueProp.features.documentationAPI')}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box sx={styles.box}>
        <Typography variant="h5" sx={styles.header} data-test-id="value-prop-provided-data">
          {t('landingPage.valueProp.data.title')}
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={4}>
            <Typography variant="h6" sx={styles.subheader}>
              {t('landingPage.valueProp.data.invoices')}
            </Typography>
            <Typography variant="body1">
              {t('landingPage.valueProp.data.retrieveInvoices')}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h6" sx={styles.subheader}>
              {t('landingPage.valueProp.data.orders')}
            </Typography>
            <Typography variant="body1">
              {t('landingPage.valueProp.data.retrieveOrders')}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h6" sx={styles.subheader}>
              {t('landingPage.valueProp.data.deliveries')}
            </Typography>
            <Typography variant="body1">
              {t('landingPage.valueProp.data.retrieveDeliveries')}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}
