import {Box, Tab, Tabs, Typography} from '@mui/material'
import {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router'

import {TermsAndConditionsCard} from '../components/TermsAndConditionsCard'
import {dataLinkRoutes} from '../routes'

export enum OpendTab {
  TermsAndConditions,
  DataProtection
}

const styles = {
  headerBox: {
    paddingBottom: '7px'
  },
  headerTypography: {
    fontWeight: 600,
    fontSize: '28px',
    lineHeight: '34px',
    color: '#FFFFFF'
  },
  contentBox: {
    borderRadius: 2,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  tab: {
    minHeight: '34px',
    height: '34px',
    textTransform: 'none'
  }
}

export const LegalPage = (props: {openTab: OpendTab}) => {
  const [value, setValue] = useState(props.openTab)
  const {t} = useTranslation()
  const history = useHistory()

  const handleChange = (event: React.SyntheticEvent, tabIndex: number) => {
    setValue(tabIndex)
  }

  useEffect(() => {
    if (value === (0 as OpendTab)) {
      document.title = t('legal.termsAndConditionsTitle')
      history.push(dataLinkRoutes.TermsAndConditionsPage)
    }
    if (value === (1 as OpendTab)) {
      document.title = t('legal.dataProtectionTitle')
      history.push(dataLinkRoutes.DataProtectionPage)
    }
  }, [history, t, value])

  return (
    <>
      <Box sx={styles.headerBox}>
        <Tabs
          TabIndicatorProps={{
            style: {display: 'none'}
          }}
          value={value}
          onChange={handleChange}
        >
          <Tab
            sx={styles.tab}
            label={<Typography sx={styles.headerTypography}>{'Terms and Condition'}</Typography>}
            data-test-id="terms-and-condition-tab"
          />
          <Tab
            sx={styles.tab}
            label={<Typography sx={styles.headerTypography}>{'Data Protection'}</Typography>}
            data-test-id="data-protection-tab"
          />
        </Tabs>
      </Box>
      {value === OpendTab.TermsAndConditions && <TermsAndConditionsCard />}
      {value === OpendTab.DataProtection && (
        <Box
          data-test-id="data-protection-content"
          bgcolor="background.paper"
          color="text.primary"
          sx={styles.contentBox}
        >
          <Typography sx={{mx: 5}}>
            <div
              className="content"
              dangerouslySetInnerHTML={{__html: t('legal.dataProtection')}}
            />
          </Typography>
        </Box>
      )}
    </>
  )
}
