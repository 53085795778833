import {Box, Typography, Stack} from '@mui/material'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router-dom'

import {ChangelogList} from '../components/changelog/ChangelogList'
import {ErrorOccurredText} from '../components/commons/ErrorOccurredText'
import {LoadingSpinner} from '../components/commons/LoadingSpinner'
import {useDeveloperPortalChangelogs} from '../hooks/useChangelog'
const styles = {
  headerBox: {
    paddingBottom: '21px'
  },
  headerTypography: {
    fontWeight: 600,
    fontSize: '28px',
    lineHeight: '34px'
  }
}
interface RouteParams {
  tag: string
}
export const ChangelogLayout = () => {
  const {t} = useTranslation()

  const params = useParams<RouteParams>()
  const {data: changelogs, isLoading, isError} = useDeveloperPortalChangelogs()

  if (isLoading) return <LoadingSpinner />

  if (!isLoading && (isError || !changelogs)) return <ErrorOccurredText />

  return (
    <Stack data-test-id="page-changelogs">
      <Box data-test-id="page-changelogs-header" sx={styles.headerBox}>
        <Typography variant="caption" sx={styles.headerTypography}>
          {t('changelog.title')}
        </Typography>
      </Box>
      <ChangelogList changelogs={changelogs} filterByTag={params?.tag} />
    </Stack>
  )
}
