import {Link} from '@material-ui/core'
import {Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {useStyles} from './RequestAccount.styles'

export const TermsAndConditions = () => {
  const {t} = useTranslation()
  const {classes} = useStyles()

  return (
    <Typography
      data-test-id="termsAndConditions-text"
      className={classes.termsAndConditionsContainer}
    >
      {t('signUp.requestAccountForm.termsAndConditions.text1')}{' '}
      <Link
        target="_blank"
        rel="noreferrer"
        href="/legal/termsandconditions"
        data-test-id="termsAndConditions-link-tac"
      >
        {t('signUp.requestAccountForm.termsAndConditions.tacLinkText')}
      </Link>{' '}
      {t('signUp.requestAccountForm.termsAndConditions.text2')}{' '}
      <Link
        target="_blank"
        rel="noreferrer"
        href="/legal/dataprotection"
        data-test-id="termsAndConditions-link-dpp"
      >
        {t('signUp.requestAccountForm.termsAndConditions.dppLinkText')}
      </Link>
      .
    </Typography>
  )
}
