import {Box, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

const styles = {
  contentBox: {
    borderRadius: 2,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  }
}

export const TermsAndConditionsCard = () => {
  const {t} = useTranslation()

  return (
    <Box
      data-test-id="terms-and-condition-content"
      bgcolor="background.paper"
      color="text.primary"
      sx={styles.contentBox}
    >
      <Typography sx={{mx: 5}}>
        <div
          className="content"
          dangerouslySetInnerHTML={{__html: t('legal.termsAndConditions')}}
        />
      </Typography>
    </Box>
  )
}
