import {Box, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'

import {useTitle} from '../hooks/useTitle'
import {FAQLayout} from '../layouts/FAQLayout'

const styles = {
  headerBox: {
    paddingBottom: '21px'
  },
  headerTypography: {
    fontWeight: 600,
    fontSize: '28px',
    lineHeight: '34px'
  },
  contentBox: {
    borderRadius: 2,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  }
}

export const FAQ = () => {
  useHistory()
  const {t} = useTranslation()
  useTitle(t('faq.title'))

  return (
    <Box data-test-id="page-faq">
      <Box data-test-id="page-faq-header" sx={styles.headerBox}>
        <Typography variant="caption" sx={styles.headerTypography}>
          {t('faq.title')}
        </Typography>
      </Box>
      <Box
        data-test-id="page-faq-content"
        bgcolor="background.paper"
        color="text.primary"
        sx={styles.contentBox}
      >
        <FAQLayout />
      </Box>
    </Box>
  )
}
