import {Shell} from '@hconnect/uikit/src/lib2'
import {Box} from '@mui/material'
import React from 'react'
import {useHistory} from 'react-router-dom'
import {makeStyles} from 'tss-react/mui'

import {useAuthentication} from '../hooks/useAuthentication'
import {SignUpShellHeader} from '../layouts/SignUpShellHeader'
import {dataLinkRoutes} from '../routes'

export const useStyles = makeStyles()((theme) => ({
  contentContainer: {
    color: theme.palette.primary.contrastText,
    display: 'flex',
    flex: '1 0 auto',
    paddingTop: '0px',
    paddingBottom: '10px',
    justifyContent: 'stretch'
  }
}))

export const SignUpShell = ({children}: {children?: React.ReactNode}) => {
  const history = useHistory()
  const {classes} = useStyles()
  const {startLoginProcess} = useAuthentication()

  return (
    <Shell
      compact={false}
      isResponsive
      boxed={false}
      onDark={false}
      showScrollToTop={false}
      stickyFooter={true}
      zIndex={1002}
      header={
        <SignUpShellHeader
          onClickHeaderLogo={() => {
            history.push(dataLinkRoutes.LandingPage)
          }}
          onLogin={startLoginProcess}
        ></SignUpShellHeader>
      }
    >
      <Box className={classes.contentContainer}>{children}</Box>
    </Shell>
  )
}
