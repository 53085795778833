import {CompanyQuota} from '@hconnect/apiclient'
import {
  Box,
  Link as MuiLink,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography
} from '@material-ui/core'
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos'
import dayjs from 'dayjs'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'

import {getAppDescription, getAppName} from '../common/app'
import {getAllEnabledProducts} from '../common/products'
import {AppOverviewSubHeader} from '../components/apps/AppOverviewSubHeader'
import {QuotaConsumptionCard} from '../components/apps/QuotaConsumptionCard'
import {QuotaLimitReachedWarning} from '../components/apps/QuotaLimitReachedWarning'
import {ApigeeApp, ApigeeAppCredential, ApigeeApps} from '../declarations'
import {useCommonTableStyles} from '../hooks/useCommonTableStyles'

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontWeight: 'bold'
  },
  tableContainer: {
    marginTop: theme.spacing(2)
  },
  valueCell: {
    fontWeight: 'bold'
  },
  rangeExceeded: {
    color: theme.palette.error.main
  },
  container: {
    marginTop: theme.spacing(2)
  }
}))

type Props = {
  record: ApigeeApps | undefined
  disableCreateAppButton: boolean
  companyQuotas: CompanyQuota[]
}

const getAssignedProducts = (
  credentials: ApigeeAppCredential[] | undefined
): string | undefined => {
  return getAllEnabledProducts(credentials).sort().join(', ')
}

export const AppOverviewContent: React.FC<Props> = ({
  record,
  disableCreateAppButton,
  companyQuotas
}) => {
  const classes = useStyles()
  const tableClasses = useCommonTableStyles()
  const {t} = useTranslation()
  const [, setElevation] = useState(1)
  return (
    <>
      <AppOverviewSubHeader disableCreateAppButton={disableCreateAppButton} />
      {companyQuotas
        ?.filter((q) => q.quotaUsed >= q.quotaLimit)
        .map((companyQuota) => (
          <div
            key={companyQuota.companyId}
            data-test-id={`quota-limit-reached-warning-${companyQuota.companyId}`}
          >
            <QuotaLimitReachedWarning
              companyName={companyQuota.companyName}
              quotaLimit={companyQuota.quotaLimit}
            />
          </div>
        ))}
      <Box style={{display: 'flex'}}>
        <Box style={{display: 'flex', flexDirection: 'column'}} flexGrow={9}>
          <Box
            bgcolor="background.paper"
            color="text.primary"
            borderRadius={8}
            p={3}
            className={classes.container}
            data-test-id="app-overview-table-list"
          >
            <Typography
              variant="h3"
              className={classes.title}
              data-test-id="latest-samples-info-box-title"
            >
              {t('appOverview.subtitle')}
            </Typography>
            <TableContainer className={classes.tableContainer}>
              <Table size="small">
                <TableHead className={tableClasses.tableHeader}>
                  <TableRow>
                    <TableCell className={tableClasses.tableHeaderCell}>
                      {t('appOverview.table.header.app')}
                    </TableCell>
                    <TableCell className={tableClasses.tableHeaderCell}>
                      {t('appOverview.table.header.description')}
                    </TableCell>
                    <TableCell className={tableClasses.tableHeaderCell}>
                      {t('appOverview.table.header.products')}
                    </TableCell>
                    <TableCell className={tableClasses.tableHeaderCell}>
                      {t('appOverview.table.header.created')}
                    </TableCell>

                    <TableCell className={tableClasses.tableHeaderCell} />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {record?.app.map((item: ApigeeApp) => (
                    <TableRow key={item.appId} className={tableClasses.tableRow}>
                      <TableCell
                        className={tableClasses.tableRowCell}
                        data-test-id={`latest-samples-info-box-time-cell-${item.appId}`}
                      >
                        {getAppName(item)}
                      </TableCell>
                      <TableCell
                        className={tableClasses.tableRowCell}
                        data-test-id={`latest-samples-info-box-time-cell-${item.appId}`}
                      >
                        {getAppDescription(item.attributes)}
                      </TableCell>
                      <TableCell
                        className={tableClasses.tableRowCell}
                        data-test-id={`latest-samples-info-box-time-cell-${item.appId}`}
                      >
                        {getAssignedProducts(item.credentials)}
                      </TableCell>

                      <TableCell
                        className={tableClasses.tableRowCell}
                        data-test-id={`latest-samples-info-box-time-cell-${item.appId}`}
                      >
                        <>{dayjs(+item.createdAt).format('YYYY-MM-DD')}</>
                      </TableCell>

                      {!disableCreateAppButton && (
                        <TableCell
                          className={tableClasses.tableRowCell}
                          data-test-id={`latest-samples-info-box-time-cell-${item.appId}`}
                        >
                          <MuiLink
                            component={Link}
                            to={`/apps/${encodeURIComponent(item.name)}`}
                            underline="none"
                            onMouseEnter={() => setElevation(3)}
                            onMouseLeave={() => setElevation(1)}
                            data-test-id={`app-detail-${item.appId}`}
                          >
                            <ArrowForwardIos color="secondary" />
                          </MuiLink>
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
        {companyQuotas.length > 0 && (
          <Box data-test-id="quota-wrapper" flexGrow={1}>
            {companyQuotas?.map((companyQuota) => (
              <QuotaConsumptionCard
                key={companyQuota.companyId}
                companyId={companyQuota.companyId}
                companyName={companyQuota.companyName}
                subscriptionPlanName={companyQuota.subscriptionPlanName}
                quotaLimit={companyQuota.quotaLimit}
                quotaUsed={companyQuota.quotaUsed}
              />
            ))}
          </Box>
        )}
      </Box>
    </>
  )
}
