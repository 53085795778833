import {Check} from '@material-ui/icons'
import {Alert, AlertTitle} from '@material-ui/lab'
import {Box, Button, Stack} from '@mui/material'
import classNames from 'classnames'
import {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {useStyles} from './RequestAccount.styles'
import {AccountFormComponentProps} from './types'

export const SupportStatusBanner: React.FC<AccountFormComponentProps> = ({formMethods}) => {
  const {t} = useTranslation()
  const {classes} = useStyles()
  const {watch} = formMethods
  const [showAlert, setShowAlert] = useState(false)
  const watchedBusinessLine = watch('businessLine')
  const watchedIsSupported = watch('isSupported')
  const supportedStatusText = watchedIsSupported ? 'supported' : 'notSupported'

  useEffect(() => {
    setShowAlert(watchedBusinessLine !== '')
  }, [watchedIsSupported, watchedBusinessLine])

  return (
    <>
      {showAlert && (
        <Alert
          data-test-id="component-supportStatusBanner"
          severity={watchedIsSupported ? 'success' : 'error'}
          icon={false}
          className={classNames(classes.supportStatusBanner, supportedStatusText)}
        >
          <Stack direction="row" spacing={2} alignItems={'center'}>
            <Box>
              <AlertTitle data-test-id="supportStatusBanner-alertTitle">
                {t(`signUp.requestAccountForm.supportStatusBanner.${supportedStatusText}.title`)}
              </AlertTitle>
              <div
                data-test-id="supportStatusBanner-alertText"
                dangerouslySetInnerHTML={{
                  __html: t(
                    `signUp.requestAccountForm.supportStatusBanner.${supportedStatusText}.text`
                  )
                }}
              />
            </Box>
            <Box>
              <Button
                data-test-id="supportStatusBanner-button"
                color="primary"
                type="button"
                variant="contained"
                startIcon={<Check />}
                onClick={() => setShowAlert(false)}
              >
                {t('signUp.requestAccountForm.supportStatusBanner.buttonText')}
              </Button>
            </Box>
          </Stack>
        </Alert>
      )}
    </>
  )
}
