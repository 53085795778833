import {AxiosInstance} from 'axios'
import {QueryFunction, useQuery} from 'react-query'

import {datalinkPublicApi} from '../common/datalinkApi'
import {DatalinkBusinessLine} from '../declarations'

type UseBusinessLines = ['datalink', 'businessLines', {country: string}]

const datalinkDeveloperPortalPath = 'datalink'

const businessLinesFetcher =
  (publicApi: AxiosInstance): QueryFunction<DatalinkBusinessLine[] | undefined, UseBusinessLines> =>
  async ({queryKey}) => {
    const [, , {country}] = queryKey
    const response = await publicApi.get<DatalinkBusinessLine[]>(
      `${datalinkDeveloperPortalPath}/business-lines?country=${country}`
    )
    return response.data
  }

export const useDatalinkBusinessLines = (country: string) => {
  return useQuery(
    ['datalink', 'businessLines', {country}],
    businessLinesFetcher(datalinkPublicApi),
    {
      enabled: !!country
    }
  )
}
