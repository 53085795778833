import {dataTestId} from '@hconnect/uikit'
import {Button} from '@mui/material'
import {useTranslation} from 'react-i18next'

import {SubmitButtonBox, useStyles} from './Survey.styles'

export const SurveySubmitButtonCard = (props: {disabledButton: boolean; onSubmit: () => void}) => {
  const {t} = useTranslation()
  const {classes} = useStyles()

  return (
    <SubmitButtonBox {...dataTestId('container-survey-submit-box')}>
      <Button
        className={classes.surveySubmitButton}
        variant="contained"
        disabled={props.disabledButton}
        onClick={props.onSubmit}
        id="container-survey-submit-button"
      >
        {t('survey.submit')}
      </Button>
    </SubmitButtonBox>
  )
}
