/* eslint-disable @typescript-eslint/no-misused-promises */
import {Country} from '@hconnect/apiclient'
import {InputTextField} from '@hconnect/uikit/src/lib2/components/inputs'
import {yupResolver} from '@hookform/resolvers/yup'
import {Box, CircularProgress, Typography} from '@material-ui/core'
import classNames from 'classnames'
import React from 'react'
import {Controller, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import * as yup from 'yup'

import {useAvailableDatalinkCountries} from '../../hooks/useCountries'

import {BusinessLineField} from './BusinessLineField'
import {CountryField} from './CountryField'
import {CustomerNumberField} from './CustomerNumberField'
import {LinkToSignIn} from './LinkToSignIn'
import {useStyles} from './RequestAccount.styles'
import {SubmitButton} from './SubmitButton'
import {SupportStatusBanner} from './SupportStatusBanner'
import {TermsAndConditions} from './TermsAndConditions'
import {AccountFormTypes, AccountFormValues} from './types'

export const RequestAccountForm = (props: {
  isSubmitting: boolean | undefined
  onSubmit: (
    formValues: AccountFormValues,
    formMethods: AccountFormTypes,
    selectedCountry: Country | undefined
  ) => void
}) => {
  const {onSubmit, isSubmitting} = props
  const {t} = useTranslation()
  const {classes} = useStyles()
  const {
    isLoading: isCountriesLoading,
    datalinkCountries,
    countrySelectValues
  } = useAvailableDatalinkCountries()

  const getFormSchema = () =>
    yup.object().shape({
      fullName: yup.string().required('fieldRequired'),
      country: yup.string().required('fieldRequired'),
      businessLine: yup.string().required('fieldRequired'),
      company: yup.string().required('fieldRequired'),
      email: yup.string().email().required('fieldRequired'),
      accountNumber: yup.string().matches(/^[0-9]+$|^$/, 'ValueMustBeANumber')
    })

  const userRegistrationForm = useForm<AccountFormValues>({
    defaultValues: {
      fullName: '',
      country: '',
      businessLine: '',
      company: '',
      accountNumber: '',
      email: '',
      isSupported: false
    },
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    resolver: yupResolver(getFormSchema())
  })

  const {ref: fullNameRef, ...fullNameRest} = userRegistrationForm.register('fullName')
  const {ref: companyRef, ...companyRest} = userRegistrationForm.register('company')
  const {ref: emailRef, ...emailRest} = userRegistrationForm.register('email')

  const handleFormSubmit = () => {
    const formValues = userRegistrationForm.getValues()
    const selectedCountry = datalinkCountries?.find(
      (country) => country.countryCode === formValues.country
    )
    onSubmit(formValues, userRegistrationForm, selectedCountry)
  }

  const getFormattedInputErrorMessage = (errorMessage?: string): string => {
    return errorMessage ? t(`signUp.requestAccountForm.errors.${errorMessage}`) : ''
  }

  if (isCountriesLoading) {
    return (
      <div className={classes.loadingIndicatorContainer}>
        <CircularProgress />
      </div>
    )
  }

  return (
    <form
      onSubmit={userRegistrationForm.handleSubmit(handleFormSubmit)}
      data-test-id="requestAccountForm"
    >
      <Typography variant="h3" data-test-id="requestAccountForm-title" className={classes.title}>
        {t('signUp.requestAccountForm.title')}
      </Typography>

      <InputTextField
        data-test-id="requestAccountForm-textInput-fullName"
        id="fullNameField"
        inputRef={fullNameRef}
        error={!!userRegistrationForm.formState.errors?.fullName}
        helperText={getFormattedInputErrorMessage(
          userRegistrationForm.formState.errors?.fullName?.message
        )}
        required={true}
        label={t('signUp.requestAccountForm.labels.fullName')}
        classes={{
          root: classNames(classes.inputTextBox)
        }}
        {...fullNameRest}
      />

      <InputTextField
        data-test-id="requestAccountForm-textInput-company"
        id="companyField"
        inputRef={companyRef}
        error={!!userRegistrationForm.formState.errors?.company}
        helperText={getFormattedInputErrorMessage(
          userRegistrationForm.formState.errors?.company?.message
        )}
        label={t('signUp.requestAccountForm.labels.company')}
        required={true}
        classes={{
          root: classNames(classes.inputTextBox)
        }}
        {...companyRest}
      />

      <Controller
        name="country"
        control={userRegistrationForm.control}
        render={({field: {value, onChange}}) => (
          <CountryField
            name="country"
            value={value}
            countrySelectValues={countrySelectValues}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
              userRegistrationForm.setValue('businessLine', '')
              onChange(e)
            }}
            formMethods={userRegistrationForm}
            helperText={getFormattedInputErrorMessage(
              userRegistrationForm.formState.errors?.businessLine?.message
            )}
            required={true}
            classes={{
              root: classNames(classes.inputTextBox)
            }}
          />
        )}
      />

      <Controller
        name="businessLine"
        control={userRegistrationForm.control}
        render={({field: {value, onChange}}) => (
          <BusinessLineField
            name="businessLine"
            value={value}
            onChange={onChange}
            formMethods={userRegistrationForm}
            helperText={getFormattedInputErrorMessage(
              userRegistrationForm.formState.errors?.businessLine?.message
            )}
            required={true}
            classes={{
              root: classNames(classes.inputTextBox)
            }}
          />
        )}
      />
      <SupportStatusBanner name={'SupportStatusBanner'} formMethods={userRegistrationForm} />

      <CustomerNumberField name="accountNumber" formMethods={userRegistrationForm} />

      <Typography variant="h3" color="inherit" gutterBottom className={classes.subTitle}>
        {t('signUp.requestAccountForm.subTitle')}
      </Typography>

      <InputTextField
        data-test-id="requestAccountForm-textInput-email"
        id="emailField"
        inputRef={emailRef}
        error={!!userRegistrationForm.formState.errors?.email}
        helperText={getFormattedInputErrorMessage(
          userRegistrationForm.formState.errors?.email?.message
        )}
        label={t('signUp.requestAccountForm.labels.emailAddress')}
        type="email"
        required={true}
        classes={{
          root: classNames(classes.inputTextBox)
        }}
        {...emailRest}
      />

      <TermsAndConditions />

      <Box className={classes.submitButtonContainer}>
        <SubmitButton
          loading={isSubmitting === true}
          disabled={!userRegistrationForm.formState.isValid}
        />
      </Box>
      <Box className={classes.linkToSignContainer}>
        <LinkToSignIn />
      </Box>
    </form>
  )
}
