import {makeStyles} from '@material-ui/core'

export const useCommonBoxStyles = makeStyles((theme) => ({
  paper: {
    padding: '1em'
  },
  card: {
    padding: '1em'
  },
  box: {
    padding: '1em'
  },
  header: {
    textTransform: 'none',
    marginBottom: theme.spacing(3)
  },
  details: {
    whiteSpace: 'pre-line',
    marginTop: -theme.spacing(3)
  }
}))
