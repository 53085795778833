/* eslint-disable @typescript-eslint/no-misused-promises */
import {Country, CreationChannel, CreationProduct} from '@hconnect/apiclient'
import {calcDefaultLocale, getBrowserLanguage} from '@hconnect/authenticator/src/utils'
import {AppInsightsAdapter} from '@hconnect/common/logging'
import {HeidelbergMaterialsLogoComponent} from '@hconnect/uikit'
import {Paper, Stack} from '@mui/material'
import {AxiosError} from 'axios'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {makeStyles} from 'tss-react/mui'

import {setAnalyticEvent} from '../common/eventTracker'
import {RequestAccountForm} from '../components/userRegistration/RequestAccountForm'
import {
  AccountFormTypes,
  AccountFormValues,
  CreateUserAccountErrorResponse
} from '../components/userRegistration/types'
import {useDatalinkSnackbar} from '../hooks/useDatalinkSnackbar'
import {useTracking} from '../hooks/useTracking'
import {UserModel, useCreateUserMutation} from '../hooks/useUser'
import {
  useRegistrationDetails,
  useRoleRequest,
  useSendInvitation
} from '../hooks/useUserSelfRegistrations'

export const useStyles = makeStyles()(() => ({
  requestAccountDialogContainer: {
    backgroundColor: 'white',
    padding: '48px',
    width: '100%',
    maxWidth: '664px'
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '0px',
    paddingBottom: '56px'
  }
}))

export const RequestAccountLayout = (props: {afterUserRegistration: (userId: string) => void}) => {
  const {t} = useTranslation()
  const {mutateAsync: createUser, isLoading: isCreateUserLoading} = useCreateUserMutation()
  const {trackEvent} = useTracking(setAnalyticEvent)
  const {classes} = useStyles()
  const {afterUserRegistration} = props
  const {mutateAsync: registrationDetailsPublisher} = useRegistrationDetails()
  const {mutateAsync: autoRoleRequest, isError: hasAutoRoleRequestError} = useRoleRequest()
  const {mutateAsync: invitationSender, isError: hasInvitationError} = useSendInvitation()

  const preferredLanguage = getBrowserLanguage()

  const {enqueueSnackbar} = useDatalinkSnackbar()

  useEffect(() => {
    if (hasInvitationError || hasAutoRoleRequestError) {
      enqueueSnackbar(t('signUp.requestAccountForm.errors.roleRequestInvitationFailed'), 'error')
    }
  }, [enqueueSnackbar, hasInvitationError, hasAutoRoleRequestError, t])

  const handleValidationError = (
    error: AxiosError,
    formMethods: AccountFormTypes
  ): string | undefined => {
    const responseData = error?.response?.data as CreateUserAccountErrorResponse
    const fieldErrors = responseData?.extendedData?.fieldErrors
    const emailExistsError = fieldErrors?.find(
      (fieldError) =>
        fieldError.fieldName === 'email' && fieldError.validationErrorType === 'exists'
    )

    if (emailExistsError) {
      formMethods.setError('email', {
        message: 'emailAlreadyExists'
      })
      return
    }

    if (error.isAxiosError) {
      return responseData.title ?? t(`signUp.requestAccountForm.errors.${error.message}`)
    }
  }

  const transformToUserModel = (
    formValues: AccountFormValues,
    selectedCountry: Country
  ): UserModel => {
    return {
      userName: formValues.fullName,
      isTermsApprovalRequired: true,
      name: formValues.fullName,
      email: formValues.email,
      companyName: formValues.company,
      creationChannel: 'inAppRegistration' as CreationChannel,
      creationProduct: 'Datalink' as CreationProduct,
      defaultLocale: calcDefaultLocale(selectedCountry, preferredLanguage),
      requestedCustomerNumber: JSON.stringify({
        accountNumber: formValues.accountNumber,
        extraInfo: formValues.businessLine
      }),
      country: formValues.country,
      termsAcceptedOn: new Date().toISOString(),
      termsAcceptedVersion: t('legal.termsAndConditionsVersion'),
      dataPrivacyAcknowledgedOn: new Date().toISOString(),
      dataPrivacyAcknowledgedVersion: t('legal.dataPrivacyProtectionVersion')
    }
  }

  const transformToUserRegistrationDetail = (formValues: AccountFormValues) => {
    return {
      fullName: formValues.fullName,
      company: formValues.company,
      countryCode: formValues.country,
      businessLineCode: formValues.businessLine,
      email: formValues.email
    }
  }

  const handleSubmit = (
    formValues: AccountFormValues,
    formMethods: AccountFormTypes,
    selectedCountry: Country | undefined
  ) => {
    if (!selectedCountry) {
      return
    }

    const body: UserModel = transformToUserModel(formValues, selectedCountry)

    AppInsightsAdapter.allowTracking()

    createUser(['users', {user: body}])
      .then((data) => {
        trackEvent({name: 'dlSubmitRequest'})

        return data.user_id ?? ''
      })
      .then((userId) => {
        autoRoleRequest([
          'datalink/roles',
          {
            userId: userId,
            countryId: formValues.country
          }
        ]).catch(() =>
          enqueueSnackbar(
            t('signUp.requestAccountForm.errors.roleRequestInvitationFailed'),
            'error'
          )
        )

        invitationSender(['datalink/invitations', {userId: userId}]).catch(() =>
          enqueueSnackbar(
            t('signUp.requestAccountForm.errors.roleRequestInvitationFailed'),
            'error'
          )
        )

        registrationDetailsPublisher([
          'datalink/registration-details',
          transformToUserRegistrationDetail(formValues)
        ]).catch(() =>
          enqueueSnackbar(
            t('signUp.requestAccountForm.errors.roleRequestInvitationFailed'),
            'error'
          )
        )
        afterUserRegistration(userId)
      })
      .catch((error) => {
        const errorMessage = handleValidationError(error as AxiosError, formMethods)
        if (errorMessage) {
          enqueueSnackbar(errorMessage, 'error')
        }
      })
      .finally(() => AppInsightsAdapter.stopTracking())
  }

  return (
    <Stack alignItems={'center'} justifyContent={'center'}>
      <Paper
        role="dialog"
        aria-modal="false"
        aria-label="requestAccountDialog"
        square={false}
        variant="elevation"
        className={classes.requestAccountDialogContainer}
        elevation={1}
        data-test-id="requestAccountLayout-paper"
      >
        <Stack direction={'row'} className={classes.logoContainer}>
          <HeidelbergMaterialsLogoComponent
            height={'59px'}
            width={'207px'}
            data-test-id="requestAccountLayout-hmLogo"
          />
        </Stack>
        <Stack>
          <RequestAccountForm onSubmit={handleSubmit} isSubmitting={isCreateUserLoading} />
        </Stack>
      </Paper>
    </Stack>
  )
}
