import {Box, Button, makeStyles, Typography} from '@material-ui/core'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {useCommonButtonStyles} from '../../hooks/useCommonButtonStyles'
import {CreateAppConfirmationDialog} from '../../layouts/CreateAppConfirmationDialog'

const useStyle = makeStyles(() => ({
  header: {
    textTransform: 'none'
  }
}))

export const AppOverviewSubHeader = ({
  disableCreateAppButton = false,
  hideCreateAppButton = false
}: {
  disableCreateAppButton?: boolean
  hideCreateAppButton?: boolean
}) => {
  const {t} = useTranslation()
  const [openCreateAppModal, setOpenCreateAppModal] = useState<boolean>(false)
  const classes = useStyle()
  const commonButtonStyles = useCommonButtonStyles()

  const handleOpen = () => {
    setOpenCreateAppModal(true)
  }

  const handleClose = () => {
    setOpenCreateAppModal(false)
  }

  return (
    <>
      <Box display="flex" flexDirection="row" width="100%" justifyContent="space-between">
        <Typography variant="h2" className={classes.header}>
          {t('appOverview.title')}
        </Typography>

        {!hideCreateAppButton && (
          <Button
            className={commonButtonStyles.button}
            variant="outlined"
            data-test-id="button-add-app"
            aria-haspopup="true"
            aria-controls="create-app-confirmation-dialog"
            startIcon={<ArrowForwardIcon />}
            onClick={handleOpen}
            disabled={disableCreateAppButton}
          >
            {t('appOverview.addAppButtonText')}
          </Button>
        )}

        <CreateAppConfirmationDialog open={openCreateAppModal} onClose={handleClose} />
      </Box>
    </>
  )
}
