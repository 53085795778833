import {AxiosInstance} from 'axios'
import {useMutation} from 'react-query'

import {datalinkPublicApi} from '../common/datalinkApi'

const PendingDeveloperRoleType = 'DATALINK_PENDING_DEVELOPER'
const SampleApiProduct = 'Sample'
const datalinkDeveloperPortalPath = 'datalink'

type UseSurveyResultsMutationKey = [
  'datalink/survey-results',
  {userId: string; surveyAnswers: string}
]

type UseRoleRequestMutationKey = ['datalink/roles', {userId: string; countryId: string}]

type UseRegistrationDetailsMutationKey = [
  'datalink/registration-details',
  {
    fullName: string
    company: string
    countryCode: string
    businessLineCode: string
    email: string
  }
]

type UseInvitationMutationKey = ['datalink/invitations', {userId: string}]

const registrationDetailsPublisher =
  (publicApi: AxiosInstance) => async (queryKey: UseRegistrationDetailsMutationKey) => {
    const [, {fullName, company, countryCode, businessLineCode, email}] = queryKey
    const response = await publicApi.post(`${datalinkDeveloperPortalPath}/registration-details`, {
      fullName: fullName,
      company: company,
      countryCode: countryCode,
      businessLineCode: businessLineCode,
      email: email
    })
    return response.data
  }

export const useRegistrationDetails = () => {
  return useMutation(registrationDetailsPublisher(datalinkPublicApi))
}

const surveyResultSender =
  (publicApi: AxiosInstance) => async (queryKey: UseSurveyResultsMutationKey) => {
    const [, {userId, surveyAnswers}] = queryKey
    const response = await publicApi.post(`${datalinkDeveloperPortalPath}/survey-results`, {
      userId: userId,
      surveyAnswers: surveyAnswers
    })
    return response.data
  }

export const useSurveyResults = () => {
  return useMutation(surveyResultSender(datalinkPublicApi))
}

const pendingDeveloperRole = (countryId: string) => {
  return {
    roles: [
      {
        roleType: PendingDeveloperRoleType,
        dataScope: {
          countryId: countryId,
          apiProducts: SampleApiProduct
        }
      }
    ]
  }
}
const roleRequester = (publicApi: AxiosInstance) => async (queryKey: UseRoleRequestMutationKey) => {
  const [, {userId, countryId}] = queryKey
  const response = await publicApi.post(`${datalinkDeveloperPortalPath}/roles`, {
    userId: userId,
    roleRequest: pendingDeveloperRole(countryId)
  })
  return response.data
}

export const useRoleRequest = () => {
  return useMutation(roleRequester(datalinkPublicApi))
}

const invitationSender =
  (publicApi: AxiosInstance) => async (queryKey: UseInvitationMutationKey) => {
    const [, {userId}] = queryKey
    const response = await publicApi.post(`${datalinkDeveloperPortalPath}/invitations`, {
      userId: userId
    })
    return response.data
  }

export const useSendInvitation = () => {
  return useMutation(invitationSender(datalinkPublicApi))
}
