import {Button, Typography} from '@mui/material'
import CookieConsent from 'react-cookie-consent'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router'

import {dataLinkRoutes} from '../routes'

export const CookiesDialog = () => {
  const {t} = useTranslation()
  const history = useHistory()

  const openCookiePage = () => {
    history.push(dataLinkRoutes.Cookies)
  }

  return (
    <CookieConsent location="bottom" buttonText={t('cookies.acceptButton')}>
      <Typography data-test-id="cookies-text">
        {t('cookies.bannerText')}
        <Button
          sx={{textTransform: 'none', color: 'grey'}}
          onClick={openCookiePage}
          data-test-id="cookiesDialog-btn-manage"
        >
          {t('cookies.manageButton')}
        </Button>
      </Typography>
    </CookieConsent>
  )
}
