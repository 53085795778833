import {SnackbarMessage, useSnackbar, VariantType} from 'notistack'

export const useDatalinkSnackbar = () => {
  const {enqueueSnackbar: showSnackbar, closeSnackbar} = useSnackbar()

  const enqueueSnackbar = (message: SnackbarMessage, variant: VariantType | undefined) => {
    showSnackbar(message, {
      onClick: () => closeSnackbar(),
      variant: variant,
      preventDuplicate: true
    })
  }

  return {
    enqueueSnackbar
  }
}
