import {Box, styled, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

const FrequentAskedQuestionTypography = styled(Typography)({
  noWrap: true,
  variant: 'subtitle1',
  fontWeight: 600,
  fontSize: '18px',
  lineHeight: '24px'
})

const FrequentAskedQuestionDescriptionTypography = styled(Typography)({
  noWrap: true,
  variant: 'body1',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '26px',
  paddingTop: '24px',
  paddingBottom: '36px'
})

export const FAQDescriptionCard = () => {
  const {t} = useTranslation()
  return (
    <Box data-test-id="component-faq-description">
      <FrequentAskedQuestionTypography data-test-id="component-faq-description-header">
        {t('faq.header.frequentAskedQuestion')}
      </FrequentAskedQuestionTypography>

      <FrequentAskedQuestionDescriptionTypography data-test-id="component-faq-description-header-content">
        {t('faq.content.frequentAskedQuestionDescription')}
      </FrequentAskedQuestionDescriptionTypography>
    </Box>
  )
}
