import {AxiosInstance} from 'axios'
import {useMutation, useQueryClient} from 'react-query'

import {ApigeeApp, ApigeeAppCredential, KeyValue} from '../declarations'

import {useDatalinkSecureApi} from './useDatalinkSecureApi'

export type UseCreateAppKeyMutationKey = [
  'datalink/appkeys',
  {
    attributes?: KeyValue[]
    productNames: string[]
    country: string
    appName: string
  }
]

export type UseToogleProductMutationKey = [
  'datalink/appkeys',
  {
    productName: string
    enabled: boolean
    credential: ApigeeAppCredential
    appName: string
  }
]

export type UseAssignProductMutationKey = [
  'datalink/appkeys',
  {
    productName: string
    credential: ApigeeAppCredential
    appName: string
  }
]

type UseAppKeyRevokeMutationKey = ['datalink/appkeys', {appName: string; key: string}]

enum KeyAction {
  Approve = 'Approve',
  Revoke = 'Revoke'
}

const DeveloperAppsEndPoint = `/datalink/developerportal/${process.env.REACT_APP_ORGANIZATION_NAME}`

const createAppKey = (secureApi: AxiosInstance) => async (queryKey: UseCreateAppKeyMutationKey) => {
  const [, {productNames, country, appName, attributes}] = queryKey
  await secureApi.post(`${DeveloperAppsEndPoint}/apps/${appName}`, {
    attributes: attributes,
    apiProducts: productNames,
    country
  })
}

const appKeyRevoke = (secureApi: AxiosInstance) => async (queryKey: UseAppKeyRevokeMutationKey) => {
  const [, {appName, key}] = queryKey
  const response = await secureApi.post<ApigeeApp>(
    `${DeveloperAppsEndPoint}/apps/${appName}/appkeys/${key}/revoke`
  )
  return response.data
}

const toggleProduct =
  (secureApi: AxiosInstance) => async (queryKey: UseToogleProductMutationKey) => {
    const [, {productName, enabled, credential, appName}] = queryKey
    const action = enabled ? KeyAction.Approve : KeyAction.Revoke
    await secureApi.post(
      `${DeveloperAppsEndPoint}/apps/${appName}/appkeys/${credential.consumerKey}/apiproducts/${productName}?action=${action}`
    )
  }

const assignProduct =
  (secureApi: AxiosInstance) => async (queryKey: UseAssignProductMutationKey) => {
    const [, {productName, credential, appName}] = queryKey
    await secureApi.post(
      `${DeveloperAppsEndPoint}/apps/${appName}/appkeys/${credential.consumerKey}`,
      {
        apiProducts: [productName]
      }
    )
  }

export const useRevokeAppKeyMutation = () => {
  const secureApi = useDatalinkSecureApi()
  const queryClient = useQueryClient()
  return useMutation(appKeyRevoke(secureApi), {
    onSuccess: () => queryClient.invalidateQueries()
  })
}

export const useToggleProductMutation = () => {
  const secureApi = useDatalinkSecureApi()
  return useMutation(toggleProduct(secureApi))
}

export const useAssignProductToAppKeysMutation = () => {
  const secureApi = useDatalinkSecureApi()
  return useMutation(assignProduct(secureApi))
}

export const useCreateAppKeyMutation = () => {
  const secureApi = useDatalinkSecureApi()
  const queryClient = useQueryClient()
  return useMutation(createAppKey(secureApi), {
    onSuccess: () => queryClient.invalidateQueries()
  })
}
