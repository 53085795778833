import {Button, IconButton} from '@material-ui/core'
import CloseIcon from '@mui/icons-material/Close'
import {Alert} from '@mui/material'
import {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'

export const QuotaLimitReachedWarning = ({companyName, quotaLimit}) => {
  const {t} = useTranslation()
  const history = useHistory()
  const [warningVisible, setWarningVisible] = useState(true)
  const handleClose = () => {
    setWarningVisible(false)
  }
  return (
    <>
      {warningVisible && (
        <Alert
          style={{marginTop: '16px'}}
          severity="warning"
          data-test-id="quota-limit-reached-warning-alert"
          action={
            <>
              <Button
                variant="text"
                color="primary"
                style={{textTransform: 'none'}}
                data-test-id="upgrade-plan-button"
                onClick={() => {
                  history.push('/pricing')
                }}
              >
                {t('appOverview.quotaConsumption.upgradePlanLabel')}
              </Button>
              <IconButton
                color="primary"
                size="small"
                style={{border: 'none', boxShadow: 'none'}}
                onClick={handleClose}
                data-test-id="close-warning-button"
              >
                <CloseIcon />
              </IconButton>
            </>
          }
        >
          {t('appOverview.quotaConsumption.limitReachedMessage', {
            quotaLimit: quotaLimit,
            companyName: companyName
          })}
        </Alert>
      )}
    </>
  )
}
